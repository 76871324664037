/* eslint-disable */
import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";
import Collapse from "@material-ui/core/Collapse";
import SvgIcon from "@material-ui/core/SvgIcon";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
    "& .MuiCollapse-wrapperInner": {
      background: "#FFF8D3",
    },
    "& .MuiAlertTitle-root": {
      color: "#142A39",
      fontWeight: "bold",
      fontFamily: "Work Sans",
      fontSize: "14px",
      lineHeight: "24px",
      display: "flex",
      alignItems: "center",
      letterSpacing: "-0.2px",
    },
  },
}));

const defaultMaterialTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#2D9F86",
    },
    success: { main: "#6BC077" },
  },
});

export default function AvailabilityAlert(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const [title, settitle] = React.useState("");
  useEffect(() => {
    setOpen(true);
    settitle(props.title);
  }, []);
  return (
    <div className={classes.root}>
      <ThemeProvider theme={defaultMaterialTheme}>
        <Collapse in={open} timeout="auto">
          <Alert
            severity={props.component==="notes"?`${props.severity}`:"success"}
            variant="filled"
            icon={props.component==="notes"?
            null
            :
              <SvgIcon viewBox="0 0 22 21">
                <path
                  d="M22 10.46L19.56 7.68L19.9 4L16.29 3.18L14.4 -3.8147e-08L11 1.46L7.6 -3.8147e-08L5.71 3.18L2.1 3.99L2.44 7.67L0 10.46L2.44 13.24L2.1 16.93L5.71 17.75L7.6 20.93L11 19.46L14.4 20.92L16.29 17.74L19.9 16.92L19.56 13.24L22 10.46ZM9 15.46L5 11.46L6.41 10.05L9 12.63L15.59 6.04L17 7.46L9 15.46Z"
                  fill="#FFFFFF"
                />
              </SvgIcon>
            }
            action={
              <Button
                color="inherit"
                onClick={() => {
                  setOpen(false);
                  if(props.component==="AdminCalendar")
                      props.onclose();
                }}
              >
                {
                  props.component==="notes"?
                  <SvgIcon viewBox="0 0 22 21">
              <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z" fill="#000000"/>
            </SvgIcon>:
              "OKAY"
                }
              </Button>
            }
          >
            {title}
          </Alert>
        </Collapse>
      </ThemeProvider>
    </div>
  );
}
