/* eslint-disable no-template-curly-in-string */
type serviceType = {
  url: string,
  version: string
};

const microserviceConfig: {
  [key: string]: serviceType
  } = {
  AppointmentsList: {
    url: "api/v1/clinicians/${clinicianId}/appointments",
    subscriptionkey: ""
  }, 
  GetApi: {
    url: "",
    subscriptionkey: ""
  }
};

export default microserviceConfig;
