import React from "react";
import { Dialog, IconButton, Grid, Button, Box } from "@material-ui/core";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import { withStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import MuiTypography from "../Shared/Typography/MuiTypography";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
  root: {},
  buttonText: {
    marginLeft: 138,
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "17px",
    display: "flex",
    letterSpacing: "0.75px",
    textTransform: "uppercase",
    color: " #142A39",
  },
  buttonTextEdit: {
    marginLeft: 150,
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "17px",
    display: "flex",
    letterSpacing: "0.75px",
    textTransform: "uppercase",
    color: " #142A39",
  },
  smallcell: {
    maxWidth: 200,
    wordWrap: "break-word"
  },
  button: {
    border: "1px solid #142A39",
    color: "#142A39",
    justifyContent: "flex-start",
    // margin: "70vw",
    // margin: theme.spacing(1),
    // paddingLeft: 8,
    // "& .MuiButton-startIcon": {
    //   margin: 0
    // },
    // [theme.breakpoints.down("sm")]: {
    //   minWidth: 32,
    //   paddingLeft: 8,
    //   paddingRight: 8,
    //   "& .MuiButton-startIcon": {
    //     margin: 0
    //   }
    // }
  },
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    outline: "none ",
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
    flexDirection: "column",
  },
}))(MuiDialogActions);
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    outline: "none",
  },
  smallButton: {
    position: "absolute",
    backgroundColor: "rgba(20, 42, 57, 0.15)",
    right: theme.spacing(3),
    top: theme.spacing(2),
    color: "black",
    padding: "10px",
    margin: "0px 0px 0px 10px",
    border: "none",
    cursor: "pointer",
    width: 40,
    height: 40,
    opacity: 0.9,
    borderRadius: "8px",
  },
});
const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, title, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <MuiTypography
        fontSize="15px"
        fontFamily="Work Sans"
        letterSpacing="0.15px"
        fontWeight={600}
        textTransform="uppercase"
        color="#415B6A"
      >
        {title}
      </MuiTypography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.smallButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});
export default function Popup(props) {
  const { t } = useTranslation(); 
  const classes = useStyles();
  const  convertMtoHM = (ms) => {
    ms=ms*60;
    let h, m;
    h = Math.floor(ms / 60);
    m = Math.floor(ms%60);

    return h + "." + m + "Hours"; //" mins ";
  };
  return (
    <Dialog
      fullWidth={true}
      maxWidth={"xs"}
      onClose={props.handleClose}
      aria-labelledby="simple-dialog-title"
      open={props.open}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
    >
      <DialogTitle
        id="simple-dialog-title"
        onClose={props.handleClose}
        title="Availability"
      />
      <DialogContent>
        <Grid container direction="row" justify="flex-start" spacing={1}>
          <Grid item>
            <Grid container direction="column" justify="space-between">
              <Grid item>
                <MuiTypography
                  fontSize="14px"
                  fontFamily="Work Sans"
                  lineHeight="24px"
                  letterSpacing="-0.2px"
                  fontWeight={700}
                  color="#142A39"
                >
                   {t("Doctor Name")}
                </MuiTypography>
              </Grid>
              <Grid item>
                <MuiTypography
                  fontSize="14px"
                  fontFamily="Work Sans"
                  lineHeight="24px"
                  letterSpacing="-0.2px"
                  fontWeight={700}
                  color="#142A39"
                >
                  {props.data.isSplit?"Start Date":"Start Time"}
                </MuiTypography>
              </Grid>
              <Grid item>
                <MuiTypography
                  fontSize="14px"
                  fontFamily="Work Sans"
                  lineHeight="24px"
                  letterSpacing="-0.2px"
                  fontWeight={700}
                  color="#142A39"
                >
                  {props.data.isSplit?"End Date":"End Time"}
                </MuiTypography>
              </Grid>
              <Grid item>
                <MuiTypography
                  fontSize="14px"
                  fontFamily="Work Sans"
                  lineHeight="24px"
                  letterSpacing="-0.2px"
                  fontWeight={700}
                  color="#142A39"
                >
                  {t("Duration")}
                </MuiTypography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container direction="column" justify="space-between">
              <Grid item>
                <MuiTypography
                  fontSize="14px"
                  fontFamily="Work Sans"
                  lineHeight="24px"
                  letterSpacing="-0.2px"
                  fontWeight={700}
                  color="#142A39"
                >
                  :
                </MuiTypography>
              </Grid>
              <Grid item>
                <MuiTypography
                  fontSize="14px"
                  fontFamily="Work Sans"
                  lineHeight="24px"
                  letterSpacing="-0.2px"
                  fontWeight={700}
                  color="#142A39"
                >
                  :
                </MuiTypography>
              </Grid>
              <Grid item>
                <MuiTypography
                  fontSize="14px"
                  fontFamily="Work Sans"
                  lineHeight="24px"
                  letterSpacing="-0.2px"
                  fontWeight={700}
                  color="#142A39"
                >
                  :
                </MuiTypography>
              </Grid>
              <Grid item>
                <MuiTypography
                  fontSize="14px"
                  fontFamily="Work Sans"
                  lineHeight="24px"
                  letterSpacing="-0.2px"
                  fontWeight={700}
                  color="#142A39"
                >
                  :
                </MuiTypography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container direction="column" justify="space-between">
              <Grid item className={classes.smallcell}>
              <MuiTypography
                 noWrap={true}
                 title={props.selectedDoctorName}
                  fontSize="14px"
                  fontFamily="Work Sans"
                  lineHeight="24px"
                  letterSpacing="-0.2px"
                  color="#142A39"
                >
                  {props.selectedDoctorName}
                </MuiTypography>
              </Grid>
              <Grid item>
              <MuiTypography
                  fontSize="14px"
                  fontFamily="Work Sans"
                  lineHeight="24px"
                  letterSpacing="-0.2px"
                  color="#142A39"
                >
                  {props.data.isSplit?moment(props.data.startDateTime).format("MM/DD/YYYY")+", "+moment(props.data.startDateTime).format("hh:mm A"):moment(props.data.startDateTime).format("LT")}
                </MuiTypography>
              </Grid>
              <Grid item>
              <MuiTypography
                  fontSize="14px"
                  fontFamily="Work Sans"
                  lineHeight="24px"
                  letterSpacing="-0.2px"
                  color="#142A39"
                >
                  {props.data.isSplit?moment(props.data.endDateTime).format("MM/DD/YYYY")+", "+moment(props.data.endDateTime).format("hh:mm A"):moment(props.data.endDateTime).format("LT")}
                </MuiTypography>
              </Grid>
              <Grid item>
              <MuiTypography
                  fontSize="14px"
                  fontFamily="Work Sans"
                  lineHeight="24px"
                  letterSpacing="-0.2px"
                  color="#142A39"
                >
                  {/* {Math.floor(
                    moment
                      .duration(
                        moment(props.data.endDateTime).diff(
                          moment(props.data.startDateTime)
                        )
                      )
                      .asMinutes() / 60
                  )}
                  :
                  {Math.floor(
                    moment
                      .duration(
                        moment(props.data.endDateTime).diff(
                          moment(props.data.startDateTime)
                        )
                      )
                      .asMinutes() % 60
                  )}{" "}
                  Hours */}
                  {convertMtoHM(
                        moment.duration(
                          moment(props.data.endDateTime).diff(
                            moment(props.data.startDateTime)
                          )
                        ).asHours())
                      }
                </MuiTypography>
              </Grid>
            </Grid>
          </Grid>        
        </Grid>
        </DialogContent>
      <DialogActions disableSpacing={true}>
        <>
          <Button
            color="default"
            variant="outlined"
            size="large"
            fullWidth
            className={classes.button}
            startIcon={<EditIcon />}
            onClick={props.onEdit}
            disabled={moment().isAfter(moment(props.data.endDateTime))?true:false}
          >
            <span className={classes.buttonTextEdit}>{t("Edit")}</span>
          </Button>

          <Box mt={2} />
          <Button
            variant="outlined"
            size="large"
            fullWidth
            className={classes.button}
            startIcon={<DeleteIcon />}
            onClick={props.onDelete}
            disabled={moment().isAfter(moment(props.data.endDateTime))?true:false}
          >
            <span className={classes.buttonText}>{t("Delete")}</span>
          </Button>
        </>
      </DialogActions>
    </Dialog>
  );
}
