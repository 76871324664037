import React from 'react'
import  Calendar  from './Calendar/calendar'
import './Appointments.scss'
import '../../App.scss'

 const Appointments=(props)=>{
        return (
                    <div>
                        <div className="right_content">
                            <div className="main_content right_container">
                                    <Calendar {...props}/>
                            </div>
                        </div>
                    </div >
        )
}

export default Appointments
