/* eslint-disable */
import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import * as Routes from "./../../Auth/Routes";
import { useHistory } from "react-router-dom";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    // maxWidth: 500,
  },
  customTabRoot: {
    color: "#142A39",
  },
  customTabIndicator: {
    backgroundColor: "#2d9f86",
    height: "4px",
  },
  paperroot: {
    flexGrow: 1,
  },
  tabRoot: {
    textTransform: "uppercase !important",
    fontSize: "14px !important",
    // textTransform: 'initial',
    minWidth: 147,
    fontWeight: "700",
    fontFamily: ["Work Sans"].join(","),
    // '&:hover': {
    //   color: '#40a9ff',
    //   opacity: 1,
    // },
    "&$tabSelected": {
      color: "#2d9f86 !important",
    },
    // '&:focus': {
    //   color: '#40a9ff',
    // },
  },
});

function useWidth() {
  const theme = useTheme();
  const keys = [...theme.breakpoints.keys].reverse();
  return (
    keys.reduce((output, key) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const matches = useMediaQuery(theme.breakpoints.up(key));
      return !output && matches ? key : output;
    }, null) || "xs"
  );
};


export default function Header(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const mediawidth = useWidth(); 
  let history = useHistory();
  let tvalue = 0;
  switch (window.location.pathname) {
    case Routes.dashboard:
      tvalue = 0;
      break;
    case Routes.appointments:
      tvalue = 1;
      break;
    case Routes.all_appointments:
      tvalue = 2;
      break;
  }

  const [value_, setValue_] = React.useState(tvalue);
  let value = tvalue;
  const handleChange = (event, newValue) => {
    ;
    if (newValue === 0) {
      history.push(Routes.dashboard);
    } else if (newValue === 1) {
      history.push(Routes.appointments);
    } else {
      history.push(Routes.all_appointments);
    }

    setValue_(newValue);
    value = newValue;
  };

  return (
    <nav className= {(mediawidth === "xl" || mediawidth === "lg") ? "nav nav-pills nav-fill home_nav mt-2 mb-2" :"home_nav_tablet"}>
      <Tabs
        value={value}
        onChange={handleChange}
        classes={{
          root: classes.customTabRoot,
          indicator: classes.customTabIndicator,
        }}
        centered
        variant={(mediawidth === "xl" || mediawidth === "lg") ? "standard" : "fullWidth"}
      >
        <Tab label={t("Home")} classes={{ root: classes.tabRoot }}></Tab>
        <Tab label={t("Calendar")} classes={{ root: classes.tabRoot }}></Tab>
        <Tab label={t("Consultations")} classes={{ root: classes.tabRoot }}></Tab>
      </Tabs>
   </nav>
  );
}
