/* eslint-disable */
import React from "react";
import { Dialog, Grid, Button, SvgIcon, Box } from "@material-ui/core";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import MuiTypography from "../../Shared/Typography/MuiTypography";
import { withStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

const buttonstyle = {
  color: "#FFFFFF",
  backgroundColor: "#142A39",
};

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    outline: "none ",
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
    flexDirection: "column",
  },
}))(MuiDialogActions);

export default function PatientTechicalIssueChat(props) {
  const { t } = useTranslation();
  return (
    <Dialog
      onClose={props.handleClose}
      aria-labelledby="simple-dialog-title"
      open={props.open}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      maxWidth="false"
    >
      <div style={{ width: 343 }}>
        <DialogContent>
          <Grid container justify="center" alignItems="center">
          <SvgIcon viewBox="0 0 64 64" style={{ fontSize: 64 }}>
                <path
                  d="M28.8 22.4H35.2V16H28.8V22.4ZM32 57.6C17.888 57.6 6.4 46.112 6.4 32C6.4 17.888 17.888 6.4 32 6.4C46.112 6.4 57.6 17.888 57.6 32C57.6 46.112 46.112 57.6 32 57.6ZM32 0C27.7977 0 23.6365 0.827705 19.7541 2.43586C15.8717 4.04401 12.3441 6.40111 9.37258 9.37258C3.37142 15.3737 0 23.5131 0 32C0 40.4869 3.37142 48.6263 9.37258 54.6274C12.3441 57.5989 15.8717 59.956 19.7541 61.5641C23.6365 63.1723 27.7977 64 32 64C40.4869 64 48.6263 60.6286 54.6274 54.6274C60.6286 48.6263 64 40.4869 64 32C64 27.7977 63.1723 23.6365 61.5641 19.7541C59.956 15.8717 57.5989 12.3441 54.6274 9.37258C51.6559 6.40111 48.1283 4.04401 44.2459 2.43586C40.3635 0.827705 36.2023 0 32 0ZM28.8 48H35.2V28.8H28.8V48Z"
                  fill="#142A39"
                />
              </SvgIcon>
          </Grid>
          <Grid container justify="center" alignItems="center">
    
          <>
                <MuiTypography
                  fontSize="20px"
                  fontFamily="Work Sans"
                  lineHeight="24px"
                  fontWeight={700}
                  color="#142A39"
                >
                  {t("Patient Technical Issues")}
                </MuiTypography>
                <Box mt={3} />
                <MuiTypography
                  fontSize="15px"
                  fontFamily="Work Sans"
                  lineHeight="24px"
                  fontWeight={400}
                  color="#142A39"
                >
                  {t("It looks like your patient may be having")}.
                </MuiTypography>
                <MuiTypography
                  fontSize="15px"
                  fontFamily="Work Sans"
                  lineHeight="24px"
                  fontWeight={400}
                  color="#142A39"
                >
                  {t("technical issues.")}.
                </MuiTypography>
              </>
          </Grid>
        </DialogContent>
        <DialogActions disableSpacing={true}>
          <>
          <Button
                size="large"
                fullWidth
                variant="contained"
                onClick={props.closePatientLeftCallPopup}
                style={buttonstyle}
              >
                {t("Okay")}
              </Button>
          </>
        </DialogActions>
      </div>
    </Dialog>
  );
}
