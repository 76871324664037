/* eslint-disable */
import * as actionTypes from '../actions/actionTypes'
import { updateObject } from '../utility';

const initialState = {
    clinicianAppointments: [],
    patientDetails:[],
    clinicianAppointmentsData:[],
    clinicianAvailabilities:[],
    error:null,
    loading:false
};

const getAppointmentsStart = (state,action) =>{
    return updateObject(state,{
        loading:true,
        error:null
    });
}

const getAppointmentsSuccess = (state, action) => {
    return updateObject(state, {
        clinicianAppointments: action.payload,
        loading:false,
        error:null
    })
}

const getAppointmentsFail = (state, action) => {
    return updateObject(state, {
        error:action.error,
        loading:false
    });
}

const deleteAppointmentStart = (state,action) =>{
    return updateObject(state,null);
}
const deleteAppointmentSuccess = (state,action) =>{
    return updateObject(state,{
        clinicianAppointments:[...state.clinicianAppointments.filter(id=>id!==action.id)],
        patientDetails:[],
        error:null
    });
}
const deleteAppointmentFail = (state,action) =>{
    return updateObject(state, {
        error:action.error
    });
}
const getPatientDetailsStart = (state,action) =>{
    return updateObject(state,{
        patientDetails:[],
        detailsloader:true,
        error:null
    });
}

const getPatientDetailsSuccess = (state, action) => {
    return updateObject(state, {
        patientDetails: action.payload,
        detailsloader:false,
        error:null
        
    })
} 

const getPatientDetailsFail = (state, action) => {
    return updateObject(state,  {
        error:action.error,
        detailsloader:false,
        patientDetails:[],

    });
}
const getCliniciansAppointmentsStart = (state,action) =>{
    return updateObject(state,{
        statsloader:true,
        error:null
    });
}

const resetPatientDetailsSuccess = (state, action) => {
    
    return updateObject(state, {
        patientDetails: action.payload,
        error:null
            
        
    })
}


const getCliniciansAppointmentsSuccess = (state, action) => {
    return updateObject(state, {
        clinicianAppointmentsData: action.payload,
            statsloader:false,
            error:null
        
    })
}

const getCliniciansAppointmentsFail = (state, action) => {
    return updateObject(state,  {
        error:action.error,
        statsloader:false
    });
}

const getClinicianAvailabilityStart = (state,action) =>{
    return updateObject(state,{
        error:null
    });
}

const getClinicianAvailabilitySuccess = (state, action) => {
    
    return updateObject(state, {
        clinicianAvailabilities:action.payload,
        error:null
    })
}

const getClinicianAvailabilityFail = (state, action) => {
    return updateObject(state,  {
        error:action.error
    });
}
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_APPOINTMENTS_START : return getAppointmentsStart(state,action)
        case actionTypes.GET_APPOINTMENTS_SUCCESS: return getAppointmentsSuccess(state, action);
        case actionTypes.GET_APPOINTMENTS_FAIL: return getAppointmentsFail(state, action);
        case actionTypes.DELETE_APPOINTMENT_START : return deleteAppointmentStart(state,action)
        case actionTypes.DELETE_APPOINTMENT_SUCCESS: return deleteAppointmentSuccess(state, action);
        case actionTypes.DELETE_APPOINTMENT_FAIL: return deleteAppointmentFail(state, action);
        case actionTypes.GET_PATIENTDETAILS_START : return getPatientDetailsStart(state,action)
        case actionTypes.GET_PATIENTDETAILS_SUCCESS: return getPatientDetailsSuccess(state, action);
        case actionTypes.GET_PATIENTDETAILS_FAIL: return getPatientDetailsFail(state, action);
        case actionTypes.GET_CLINICIANS_APPOINTMENTS_START : return getCliniciansAppointmentsStart(state,action);
        case actionTypes.GET_CLINICIANS_APPOINTMENTS_SUCCESS: return getCliniciansAppointmentsSuccess(state, action);
        case actionTypes.GET_CLINICIANS_APPOINTMENTS_FAIL: return getCliniciansAppointmentsFail(state, action);
        case actionTypes.GET_CLINICIAN_AVAILABILITY_START : return getClinicianAvailabilityStart(state,action);
        case actionTypes.GET_CLINICIAN_AVAILABILITY_SUCCESS: return getClinicianAvailabilitySuccess(state, action);
        case actionTypes.GET_CLINICIAN_AVAILABILITY_FAIL: return getClinicianAvailabilityFail(state, action);
        case actionTypes.RESET_PATIENT_DETAILS_SUCCESS: return resetPatientDetailsSuccess(state, action);

        default: return state;
    }
}

export default reducer