import 'date-fns';
import React from 'react';
import DateFnsUtils from "@date-io/date-fns";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker
} from '@material-ui/pickers';
import Grid from "@material-ui/core/Grid";

import {createMuiTheme} from "@material-ui/core";
import {ThemeProvider} from "@material-ui/core/styles";
import englishLocale from "date-fns/locale/en-US";
import spanishLocale from "date-fns/locale/es";
import frLocale from "date-fns/locale/fr";
import germanLocale from "date-fns/locale/de";
import italianLocale from "date-fns/locale/it";
import { useTranslation } from "react-i18next";

const materialTheme = createMuiTheme({
    palette: {
        primary: {
            main: "#2D9F86"
        }
    },
    typography: {
        fontFamily: 'Work Sans',
      },
    overrides: {
        // MuiPickersToolbar: {
        //     toolbar: {
        //         backgroundColor: '#282828'
        //     },
        // },
        MuiPickersCalendarHeader: {
            daysHeader: {
                display: "-webkit-box" && "-moz-box" && "-ms-flexbox" && "-webkit-flex" && "flex"                
            },
            switchHeader: {
                display: "-webkit-box" && "-moz-box" && "-ms-flexbox" && "-webkit-flex" && "flex",
                "-ms-flex-pack":" space-between",
                "justify-content": 'space-between'
                // backgroundColor: "#282828",
                // color: "white",
            },
        },
        // MuiPickersDay: {           
        //     day: {
        //         color: 'black',
        //     },
        //     daySelected: {
        //         backgroundColor: '#2D9F86',
        //     },
        // },
        // MuiPickersModal: {
        //     dialogAction: {
        //         color: 'black',
        //     },
        // },
        MuiPickersCalendar: {
            calendar: {
                color: '#2D9F86'
            },
            week: {
                display: "-webkit-box" && "-moz-box" && "-ms-flexbox" && "-webkit-flex" && "flex",
                // backgroundColor: '#282828'
            },
        }
    },
});

export default function MaterialUIPickers(props) {
    const [selectedDate, setSelectedDate] = React.useState(props.date);
    const { i18n } = useTranslation();
    const handleDateChange = (date) => {
        setSelectedDate(date);
        props.changeHandle(date);
    };
    const localeMap = {
        en: englishLocale,
        es: spanishLocale,
        de: germanLocale,
        it : italianLocale,
        fr: frLocale
      };

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[i18n.language]}>
            <Grid container justify="flex-end">
            <ThemeProvider theme={materialTheme}>
                <KeyboardDatePicker
                    autoOk
                    disableToolbar
                    variant="inline"
                    inputVariant="outlined"
                    format="MM/dd/yyyy"
                    // margin="normal"
                    id="date-picker-inline"
                    // label={props.label}
                    value={selectedDate}
                    onChange={handleDateChange}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}

                    inputProps={{
                        style: {                          
                          padding: props.padding,
                        },
                      }}
                      InputProps={{ readOnly: true }}

                />
            </ThemeProvider>
            </Grid>
           
        </MuiPickersUtilsProvider>
    );
}
