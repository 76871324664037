/* eslint-disable */
import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import {useSelector} from "react-redux";
import { withStyles } from '@material-ui/core/styles';

const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#2D9F86',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#2D9F86',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#142A39',
      },
      '&:hover fieldset': {
        borderColor: '#2D9F86',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#2D9F86',
      },
    },
  },
})(TextField);
import * as API from '../../Services/API/actions';
import { makeStyles } from '@material-ui/core/styles';

const useStyles= makeStyles((theme)=>({
  root:{
    "&.MuiAutocomplete-listbox":{
      overflow:"visible"
    }
  },
  paper: {
    boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.02), 0px 1px 12px rgba(0, 0, 0, 0.12)",  
    margin: 0,
    borderRadius:"4px",
    height:"200px",
    padding:"5px 20px",
     overflowY:"auto",
    background:"#FFFFFF"
  },
  option: {
    fontFamily:"Work Sans",
    fontWeight:400,
    fontSize:"16px",
    borderBottom: '1px solid #BABFBD',
    alignItems: 'flex-start',
    color: "#000000",
    padding: 8,
    '&[aria-selected="true"]': {
      backgroundColor: 'transparent',
    },
  },
  popupIndicatorOpen:{
      color: '#2D9F86',
  }

}));
export default function Asynchronous(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const loading = open && options.length === 0;
  const memberId = useSelector((state) => state.user.profile.id);
  const teamId = useSelector((state) => state.user.team.id);
  React.useEffect(() => {
    let active = true;
    if (!loading) {
      return undefined;
    }

    (async () => {
       API.AlldoctorsList(teamId,memberId).subscribe(res=>{
         if(active){
           setOptions(_.sortBy(res.data.result,"firstName"));
         }
            
      },
      (error)=>{
        console.log(error);
      });
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);
  let inputValue;
    if(props.disabled){
      inputValue = props.selectedData.substring(3);
    }

  return (
    <Autocomplete
      id="asynchronous"
      disabled={props.disabled?true:false}
      onChange={(event, value) => props.selectedDoctor(value)}
      noOptionsText="No Doctor Available with this name."
      style={{ width: 570}}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      classes={{
        paper: classes.paper,
        option: classes.option,
        popupIndicatorOpen:classes.popupIndicatorOpen,
        listbox:classes.root
      }}
      inputValue={inputValue}
      getOptionSelected={(option, value) => option.name === value.name}
      getOptionLabel={(option) => option.firstName +" "+ option.lastName}
      options={options}
      loading={loading}
      renderInput={(params) => (
        <CssTextField
          error={props.error === true ?true:false}
          {...params}
          label={props.label}
          placeholder="Select name"
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}
