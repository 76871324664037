/* eslint-disable */
import * as action_types from './actionTypes'

export const user_logout = () => {
    
    return { type: action_types.USER_LOGOUT,payload : {} }
}

export const getUserSuccess = (data) => {
    return { type: action_types.GET_USER_SUCCESS, payload: data }
}

export const getUserFail = (error) => {
    return { type: action_types.GET_USER_FAIL, error: error }
}

export const addUser = (data) => {
    return dispatch => {
        dispatch(getUserSuccess(data));
    }
};

// export const getUser = (id, eventData) => {
//     return dispatch => {
//         GoAPI.get('/i/v1/me', { headers: { "Authorization": `Bearer ${token}` } })
//             .then(res => {

//                 console.log(res.data)
//                 dispatch(getUserSuccess(res.data));
//             })
//             .catch((error) => {
//                 console.log(error)
//                 dispatch(getUserFail(error));
//             });
//     };
// };
