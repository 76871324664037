import React from "react";
import { Dialog, IconButton, Grid, Button} from "@material-ui/core";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import { withStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import WarningIcon from '@material-ui/icons/Warning';
import { makeStyles } from "@material-ui/core/styles";
import MuiTypography from "../Shared/Typography/MuiTypography";
import * as API from './../../Services/API/actions'
import Alert from "../Shared/Alert/Alerts";
import {useSelector} from "react-redux";
const useStyles = makeStyles({
  root: {
    "& .MuiButton-outlinedSizeLarge":{
      width:"164px",
      height: "40px",
      borderRadius:"4px",
    fontFamily: "Work Sans",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "16px",
    letterSpacing: "0.75px",
    textAlign: "center"

  }
  },
  outlinebutton: {
    paddingLeft: 50,
    paddingRight: 50,
    border: "1px solid #142A39",
    color: "#142A39",
  },
  filtering:{
    opacity: 0.4
  }
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    outline: "none ",
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
    flexDirection: "column",
  },
}))(MuiDialogActions);
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    outline: "none",
  },
  smallButton: {
    position: "absolute",
    backgroundColor: "rgba(20, 42, 57, 0.15)",
    right: theme.spacing(3),
    top: theme.spacing(2),
    color: "black",
    padding: "10px",
    margin: "0px 0px 0px 10px",
    border: "none",
    cursor: "pointer",
    width: 40,
    height: 40,
    opacity: 0.9,
    borderRadius: "8px",
  },
  warningIconcolor:  {
    color: "#CC3D3F",
  },
});
const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, title, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Grid container justify="flex-start" direction="row" alignItems="center" spacing={2}>
        <Grid item>
        <WarningIcon style={{fontSize: 24}} className={classes.warningIconcolor} />
        </Grid>
        <Grid item>
          <MuiTypography
            fontSize="24px"
            fontFamily="Work Sans"
            // letterSpacing="0.15px"
            // lineHeight="28.15px"
            fontWeight={500}
            color="#000000"
          >
            Cancel consultation
          </MuiTypography>
        </Grid>
      </Grid>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.smallButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});
export default function CancelConsultaionConfirmationPopup(props) {
  const classes = useStyles();
  const [loading,setloading] = React.useState(false);
  const [alert,setAlert] = React.useState(null);
  const teamId = useSelector((state) => state.user.team.id);
  const deleteHandler=()=>{
    setloading(true);
    let params={
      version:props.data.version
    }
    API.deleteAppointment(teamId,props.memberId,props.data.appointmentId,params).subscribe(res=>{
      setloading(false);
      if ("detailed_error" in res.data) {
        setAlert(<Alert
          date={new Date()}
          title={"detailed_error" in res.data?"Unable to process the request":"Technical Issue"
          }
          message={"detailed_error" in res.data?res.data["detailed_error"]:"Unable to process the request"}
        />)
      }
      else{
        props.onCancel();
      }
    },
    (error)=>{
      setloading(false);
      setAlert(<Alert
        date={new Date()}
        title={"Technical Issue"
        }
        message={"Unable to process this request due to internal server error."}
      />)
    })
  }
  return (
    <Dialog
      fullWidth={true}
      maxWidth={"xs"}
      onClose={props.handleClose}
      aria-labelledby="simple-dialog-title"
      open={props.open}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
    >
      <div className={loading ? classes.filtering : null}>
        <div className="telekardia">
          <div className={loading ? "spinner-border" : null}></div>
        </div>
      <DialogTitle id="simple-dialog-title" onClose={props.handleClose} />
      {alert && alert !== null && (
      <div className="telekardia">
              <div className="m-3">{alert}</div>
              </div>
      )}
      <DialogContent>
      <MuiTypography
                fontSize="14px"
                fontFamily="Work Sans"
                lineHeight="24px"
                letterSpacing="-0.2px"
                fontWeight={400}
                color="#142A39"
              >
                Are you sure you want to cancel the consultation?
              </MuiTypography>
      </DialogContent>
      <DialogActions disableSpacing={true}>
        <Grid container direction="row" justify="flex-end" spacing={2}>
          <Grid item>
            <div className={classes.root}>
            <Button
              variant="outlined"
              size="large"
              onClick={props.handleClose}
              className={classes.outlinebutton}
            >
              No
            </Button>
            </div>
          </Grid>
          <Grid item>
            <div className="telekardia">
              <button
                type="button"
                className="btn px-4 bg_red_darker text-uppercase h40 ml-3 swal_green"
                onClick={deleteHandler}
              >
                {"YES, CANCEL"}
              </button>
            </div>
          </Grid>
        </Grid>
      </DialogActions>
    </div>
    </Dialog>
  );
}
