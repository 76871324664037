
import { api } from '../restCallApi';

export default {
  getapiservice(...args) {
    return api.GetApi.get$(...args);
  },
  postapiservice(...args) {
    return api.GetApi.post$(...args);
  }
};
