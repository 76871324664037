/* eslint-disable */
import React from "react";
import styled, { keyframes } from "styled-components";

type Props = {
  name: string,
  size: number,
  color: string,
  strokeColor: String,
  float: string,
  margin: string,
  viewBox: String,
  onclick: () => {}
};

const ShimmerKeyframe = keyframes`
  0% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 1000;
  }
`;

const StyledSvg = styled.svg`
  display: inline-block;
  vertical-align: middle;
  path {
    fill: ${(props: Props) => props.color};
  }
  float: ${(props: Props) => props.float};
  margin: ${(props: Props) => props.margin};
  stroke-dashoffset: 1000;
`;

// @keyframes dash {
//   from {
//     stroke-dashoffset: 1000;
//   }
//   to {
//     stroke-dashoffset: 0;
//   }
// }

function Icon(props: Props) {
  return (
    // <Svg
    //   color={props.color}
    //   width={`${props.size}px`}
    //   height={`${props.size}px`}
    //   viewBox={props.viewBox || "0 0 24 24"}
    //   float={props.float}
    //   margin={props.margin}
    //   onClick={props.onclick}
    // >
    //   <path d={props.name} />
    // </Svg>
    <StyledSvg
      color={props.color}
      width={`${props.size}px`}
      height={`${props.size}px`}
      viewBox={props.viewBox || "0 0 24 24"}
      float={props.float}
      margin={props.margin}
      onClick={props.onclick}
    >
      {/* <g stroke="#0072CE" strokeWidth="2" transform="translate(1 1)"> */}
      <g
        // stroke={props.strokeColor}
        stroke="#000000"
        strokeWidth="2"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
      >
        <path
          // strokeDasharray="1000"
          // strokeDashoffset="1000"
          d={props.name}
          // animation: "dash 5s linear alternate infinite"
          // style={{ animation: "dash 5000ms linear 0ms infinite" }}
        />
      </g>
    </StyledSvg>
  );
}

Icon.defaultProps = {
  size: 16
};

export default Icon;
