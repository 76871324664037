/* eslint-disable */
import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Dialog, IconButton } from "@material-ui/core";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import CloseIcon from "@material-ui/icons/Close";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import KeyboardArrowLeftOutlinedIcon from "@material-ui/icons/KeyboardArrowLeftOutlined";
import Typography from "@material-ui/core/Typography";
import { useSelector } from "react-redux";
import Authorize from "../Authorize";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});
const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">&nbsp;</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const AppointmentDetails = (props) => {
  let actions = null;
  if (props.component === "popover") {
    actions = (
      <div className="telekardia">
        <button
          type="button"
          className="btn px-4 btn-outline-primary text-uppercase h40 f28_icon"
          onClick={props.handleClose}
        >
          <KeyboardArrowLeftOutlinedIcon /> Back
        </button>

        {(props["isfuturescheduledappintment"] === undefined ||
          props.isfuturescheduledappintment === true) && (
          <Authorize>
            <button
              type="button"
              className="btn px-4 btn-outline-secondary text-uppercase h40 ml-3"
              onClick={props.onSubmit}
            >
              REASSIGN CONSULTATION
            </button>
          </Authorize>
        )}
      </div>
    );
  }

  const loading = useSelector((state) => state.appointments.loding);

  return (
    <>
      <Dialog 
        fullWidth={true}
        maxWidth={"xl"}
        onClose={props.handleClose}
        aria-labelledby="customized-dialog-title"
        open={props.show}
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
      >
        {props.component === "popover" && (
          <DialogTitle
            id="customized-dialog-title"
            onClose={props.handleClose}
          />
        )}

        <DialogContent dividers>
          <div className={loading ? "filtering" : null}>
            <div className={loading ? "spinner-border" : null}></div>
            {props.children}
          </div>
        </DialogContent>
        <DialogActions>
          <div className="py-4 px-5">{actions}</div>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AppointmentDetails;
