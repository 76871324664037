/* eslint-disable */
import React, { useEffect, useCallback } from "react";
import moment from "moment";
import _get from "lodash.get";
import { makeStyles } from "@material-ui/core/styles";
import EkgRecording from "../chart/EkgRecording";
import { calcRowSeconds } from "../chart/utils";
import * as Lib from "../chart/Constants/RecordingConstants";
//import Modal from './AppointmentDetailsModal'
import PatientDetails from "./PatientDetails";
import axios from "axios";
import { streamToPromise, getTimezoneName } from "../../Utilities/Utilities";
import { getEKGAlgorithmDeterminationDisplayText } from '../../Utilities/recordingMaps';

import apiservice from "../../api/services/apiservice.service";
import { GoAPI } from "../../Axios/axios";
import { getToken } from "../../Auth/AuthUtilities";
import "../../Assets/styles/bootstrap.scss";
import Alert from "@material-ui/lab/Alert";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {},
  verticalgrid: {
    minHeight: 600,
  },
  cardHeader: {
    fontWeight: "600",
  },
  blackGrid: {
    fontWeight: "600",
    alignItems: "center",
    height: "55px",
    padding: theme.spacing(1),
    background: "#E2E7E3",
    color: "#000000",
    lineHeight: "40px",
  },
  gridPadding: {
    padding: theme.spacing(1),
  },
  boldFont: {
    fontWeight: "600",
  },
  bolderFont: {
    fontWeight: "700",
  },
  cardlayout: {
    minHeight: 600,
    background: "#F1F1F1",
  },
  cardlayoutmini: {
    minHeight: 280,
    background: "#F1F1F1",
  },
  recordingInfo: {
    zIndex: 1,
    padding: "8px 10px",
    backgroundColor: "#fff",
    opacity: 0.8,
    width: "fit-content",
    display:
      "-webkit-box" && "-moz-box" && "-ms-flexbox" && "-webkit-flex" && "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    position: "absolute",
    right: "10px",
    margin:"20px"
  },
  recordingInfospan: {
    // width: "30px",
    paddingRight: "20px",
    color: "#000",
  },
  recordingInfospanfirstChild: {
    width: "30px",
    paddingLeft: "0px",
  },
}));

const Details = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const appointmentId = props.appointmentId;
  const clinicianId = useSelector((state) => state.user.profile.id);;
  const teamId = useSelector((state) => state.user.team.id);
  //  const contentHeight = 500 - 124 - 14 + 34;
  const MIN_SECONDS = 1;
  const mainContentWidth = 902;
  const rowSeconds =
    calcRowSeconds(mainContentWidth) > 0
      ? calcRowSeconds(mainContentWidth)
      : MIN_SECONDS;
  const ekgWidth = rowSeconds * Lib.PX_PER_SECOND + 18;

  const [patientDetails, setPatientDetails] = React.useState({});
  const [notesdata, setnotesdata] = React.useState("");

  const [recording, setRecording] = React.useState({});
  const [loader, setloader] = React.useState(false);
  const [alertOn, setAlertOn] = React.useState(false);

  const [toggleRecordingInvert, setoggleRecordingInvert] = React.useState(
    false
  );
  const timezoneValue = getTimezoneName();
  const loadAction = useCallback(async () => {
    setloader(true);
    let offsetInMinutes = new Date().getTimezoneOffset();
    const appointmentRequest = streamToPromise(
      apiservice.getapiservice({
        baseUrl: `api/v1.1/teams/${teamId}/clinicians/${clinicianId}/appointments/${appointmentId}/patient-detail?timeZoneOffset=${offsetInMinutes}&timeZone=${timezoneValue}`,
      })
    );
    const appointmentdetailsdata = await appointmentRequest;
    setPatientDetails(appointmentdetailsdata.data);
    const notesRequest = streamToPromise(
      apiservice.getapiservice({
        baseUrl: `api/v1.1/teams/${teamId}/clinicians/${clinicianId}/appointments/${appointmentId}/notes`,
      })
    );
    const notesRequestdata = await notesRequest;
    setnotesdata(
      notesRequestdata.data.consultationNote &&
        notesRequestdata.data.consultationNote !== null
        ? notesRequestdata.data.consultationNote
        : "-"
    );
  }, []);

  useEffect(() => {
    loadAction();
  }, [appointmentId]);

  const loadEkgAction = useCallback((recordingid) => {
    let samps = GoAPI.get(`/i/v1/recordings/${recordingid}/samples`, {
      headers: { Authorization: `Bearer ${getToken()}` },
    });
    let recs = GoAPI.get(`/i/v1/recordings/${recordingid}`, {
      headers: { Authorization: `Bearer ${getToken()}` },
    });

    axios
      .all([recs, samps])
      .then(
        axios.spread((...responses) => {
          setloader(false);
          setRecording({ ...responses[0].data, ...responses[1].data });
        })
      )
      .catch((error) => {
        setAlertOn(true);
        setloader(false);
        setRecording({});
      });
  }, []);

  useEffect(() => {
    if (
      Object.keys(patientDetails).length > 0 &&
      patientDetails.ekgResults &&
      patientDetails.ekgResults.length > 0
    ) {
      loadEkgAction(patientDetails.ekgResults[0].ekgRecordId);
    }
  }, [patientDetails]);
  
  return (
    <div className="telekardia">
      <div className={loader ? "filtering" : null}>
        <div className={loader ? "spinner-border" : null}></div>
        <div className="row">
          <div className="col-12">{props.alert}</div>
        </div>
        <div className="container-fluid">
          <div className="row m-0" style={{ display: "-ms-flexbox" }}>
            <div className="col-12 col-xl-7">
              <div className="row d-none d-md-block d-lg-block d-xl-none">
                <PatientDetails patientDetails={patientDetails} />
              </div>
              <div className="row">
                <div
                  className={
                    props.step === 1 ||
                    props.step === 2 ||
                    props.component === "popover"
                      ? "col-12 height_960 bg_white p-0"
                      : "col-12 height_530 bg_white p-0"
                  }
                >
                  {alertOn === true ? (
                    <Alert severity="warning">EKG result not found!!!</Alert>
                  ) : (
                    <>
                      <div className={classes.blackGrid + " pl-2 d-flex justify-content-between"}>
                                  <div>{t("EKG Finding")}{" : "}
                                      {getEKGAlgorithmDeterminationDisplayText(recording.algorithmDetermination, recording.algorithmPackage)}</div>
                                      <div>
                                      {t("Device Used")}{" : "}
                                      {recording && recording.is6l?"6-Lead Device":"1-Lead Device"}
                                      </div>
                      </div>
                      <div className={classes.recordingInfo}>
                        <div className={classes.recordingInfospan}>25 mm/s</div>
                        <div className={classes.recordingInfospan}>
                          10 mm/mV
                        </div>
                        {!recording.is6l &&
                         <div className={classes.recordingInfospan}>
                         {" "}
                         <a
                           onClick={() =>
                             setoggleRecordingInvert(!toggleRecordingInvert)
                           }
                         >
                           {" "}
                           {t("Invert ECG")}
                         </a>
                       </div>
                        }
                       
                      </div>
                      <div className={recording.is6l?"EKG_canvas":"EKG_canvas_non6L"}>
                        <EkgRecording
                          height={880}
                          width={ekgWidth}
                          recording={recording}
                          calpulse={false}
                          rowSeconds={rowSeconds}
                          inverted={toggleRecordingInvert}
                          is6l={recording.is6l}
                        ></EkgRecording>
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div
                className={
                  props.step === 1 ||
                  props.step === 2 ||
                  props.component === "popover"
                    ? "row mt-3"
                    : "row"
                }
              >
                <div className="col-12 height_300 bg_white p-4">
                  <div
                    className="row no-gutters"
                    style={{ display: "-ms-flexbox" }}
                  >
                    <div className="col-4">
                      <h5 className="heading">{t("EKG Tags")}</h5>
                      <div>
                        {!recording && recording.tags.length > 0
                          ? recording.tags.map((item, index) => (
                              <ul className="list-unstyled mb-0" key={index}>
                                <li className="my-2">{item}</li>
                              </ul>
                            ))
                          : "-"}
                      </div>
                    </div>
                    <div className="col-4">
                      <h5 className="heading">{t("EKG Details")}</h5>
                      <div>
                        <ul className="list-unstyled mb-0">
                          <li className="my-2">
                          {t("Heart Rate:")}{" "}
                            <span>
                              {(_get(recording, "bpm")  ||_get(recording, "bpm") === 0) ? (_get(recording, "bpm") + " " + t("BPM")) : "-"}
                            </span>
                          </li>
                          <li className="my-2">
                          {t("Recorded Date")}:{" "}
                            <span>
                              {_get(recording, "recordedAt") ? moment(
                                new Date(_get(recording, "recordedAt"))
                              ).format("MMMM D, YYYY") : "-"}
                            </span>
                          </li>
                          <li className="my-2">
                          {t("Recorded Time")}:{" "}
                            <span>
                              {" "}
                              {_get(recording, "recordedAt") ? moment(
                                new Date(_get(recording, "recordedAt"))
                              ).format("hh:mm A") : "-"}
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-xl-5 p-md-0 p-lg-0 px-xl-3">
              <div className="d-none d-xl-block">
              <PatientDetails patientDetails={patientDetails} />
              </div>
              <div className="row no-gutters mt-3">
                {/*height_815 - step1,  height_400 - step2*/}
                <div
                  className={
                    props.step === 1 || props.component === "popover"
                      ? "col-12 height_800 bg_white p-4 my-3"
                      : "col-12 height_350 bg_white p-4 overflow-auto"
                  }
                >
                  <div className="row">
                    <div className="col-12">
                      <h5 className="head"> {t("Medical History")}</h5>
                      <div>
                        <ul className="list-unstyled mb-0">
                          <li className="my-2">
                            <span>
                              {_get(patientDetails, "medicalHistory") || "-"}
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-12 mt-2">
                      <h5 className="heading">{t("Family History")}</h5>
                      <div>
                        <ul className="list-unstyled mb-0">
                          <li className="my-2">
                            <span>
                              {_get(patientDetails, "familyMedicalHistory") ||
                                "-"}
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-12 mt-2">
                      <h5 className="heading">{t("Medications")}</h5>
                      <div>
                        <ul className="list-unstyled mb-0">
                          <li className="my-2">
                            <span>
                              {_get(patientDetails, "medications") ||
                                "-"}
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-12 mt-2">
                      <h5 className="heading">{t("Allergies")}</h5>
                      <div>
                        <ul className="list-unstyled mb-0">
                          <li className="my-2">
                            <span>
                              {_get(patientDetails, "allergyDetails") || "-"}
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-12 mt-2">
                      <h5 className="heading">{t("Social History")}</h5>
                      <div>
                        <ul className="list-unstyled mb-0">
                          {Object.keys(patientDetails).length > 0 &&
                          patientDetails.lifeStyleHistoryStr ? (
                            patientDetails.lifeStyleHistoryStr
                          // patientDetails.lifeStyleHistoryStr.length > 0 ? (
                          //   patientDetails.lifeStyleHistoryStr.map(
                          //     (item, index) => (
                          //       <li className="my-2" key={index}>
                          //         {item.name}
                          //       </li>
                          //     )
                          //   )
                          ) : (
                            <li className="my-2"> - </li>
                          )}
                        </ul>
                      </div>
                    </div>

                    <div className="col-12 mt-2">
                      <h5 className="heading">{t("Surgeries")}</h5>
                      <div>
                        <ul className="list-unstyled mb-0">
                          <li className="my-2">
                            <span>
                              {_get(patientDetails, "surgeryDetails") || "-"}
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-12 mt-2">
                      <h5 className="heading">{t("Patient Notes")}</h5>
                      <div>
                        <ul className="list-unstyled mb-0">
                          <li className="my-2">
                            <span>
                              {decodeURI(_get(patientDetails, "patientNotes") || "-")}
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-12 mt-2">
                      <h5 className="heading">{t("My Notes")}</h5>
                      <div>
                        <ul className="list-unstyled mb-0">
                          <li className="my-2">
                            <span>{decodeURI(notesdata)}</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Details;
