
import { useState, useRef } from 'react';

const useDoctorchatTimer = (initialState = 0) => {
  const [doctorchattimer, setdoctorchattimer] = useState(initialState)
  const [isActive, setIsActive] = useState(false)
  const [isPaused, setIsPaused] = useState(false)
  const countRef = useRef(null)

  const handledoctorchattimerStart = () => {
    setIsActive(true)
    setIsPaused(true)
    countRef.current = setInterval(() => {
      setdoctorchattimer((timer) => timer + 1)
    }, 1000)
  }


  const handledoctorchattimerReset = () => {
    clearInterval(countRef.current)
    setIsActive(false);
    setIsPaused(false);
    setdoctorchattimer(0);
  }

  return { doctorchattimer, isActive, isPaused, handledoctorchattimerStart, handledoctorchattimerReset }
}

export default useDoctorchatTimer;