/* eslint-disable */
import { GoAPI, TeleKardiaAPI } from "../../Axios/axios";
import axios from "axios";
import { getToken } from "./../../Auth/AuthUtilities";
import * as actionTypes from "./actionTypes";
import {
  getPatientDetailsFail,
  getPatientDetailsSuccess,
  getPatientDetailsStart,
} from "./dashboard";

export const getRecordingStart = () => {
  return {
    type: actionTypes.GET_RECORDING_START,
  };
};
export const getRecordingSuccess = (data) => {
  return { type: actionTypes.GET_RECORDING_SUCCESS, payload: data };
};

export const getRecordingFail = (error) => {
  return { type: actionTypes.GET_RECORDING_FAIL, error: error };
};

export const getRecording = (recordingId) => {
  return (dispatch) => {
    dispatch(getRecordingStart);
    GoAPI.get(`/i/v1/recordings/${recordingId}`, {
      headers: { Authorization: `Bearer ${getToken()}` },
    })
      .then((res) => {
        dispatch(getRecordingSuccess(res.data));
      })
      .catch((error) => {
        dispatch(getRecordingFail(error));
      });
  };
};

export const getRecordingSamplesStart = () => {
  return {
    type: actionTypes.GET_RECORDING_SAMPLE_START,
  };
};
export const getRecordingSamplesSuccess = (data) => {
  return { type: actionTypes.GET_RECORDING_SAMPLE_SUCCESS, payload: data };
};

export const getRecordingSamplesFail = (error) => {
  return { type: actionTypes.GET_RECORDING_SAMPLE_FAIL, error: error };
};

export const getRecordingSamples = (
  recordingId,
  teamId,
  clinicianid,
  appointmentId,
  params
) => {
  return (dispatch) => {
    dispatch(getRecordingSamplesStart);
    let patientDetails = TeleKardiaAPI.get(
      `/api/v1.1/teams/${teamId}/clinicians/${clinicianid}/appointments/${appointmentId}/patient-detail`,
      {
        headers: { Authorization: `Bearer ${getToken()}` },
        params: params,
      }
    );
    let samps = GoAPI.get(`/i/v1/recordings/${recordingId}/samples`, {
      headers: { Authorization: `Bearer ${getToken()}` },
    });
    let recs = GoAPI.get(`/i/v1/recordings/${recordingId}`, {
      headers: { Authorization: `Bearer ${getToken()}` },
    });
    dispatch(getPatientDetailsStart());
    (patientDetails)
    .then((res)=>{
      if("detailed_error" in res.data){
         dispatch(getPatientDetailsFail(res.data["detailed_error"]));
         //dispatch(getRecordingSamplesFail(res.data.detailed_error));
      }
      else{
        dispatch(getPatientDetailsSuccess(res.data));
        axios
        .all([recs,samps])     
          .then(axios.spread((...responses) => {
                  dispatch(getRecordingSamplesSuccess(responses));
              }))
              .catch((err)=>{
                dispatch(getRecordingSamplesFail(err.response));
              })    
      }
    })
    .catch((err)=>{
                  dispatch(getPatientDetailsFail(err));
    })
      
  };
};
