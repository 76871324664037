import React from 'react';
import Button from '../Button';
import Icon from '../Icon';
import { icons } from '../../../constants/icons';

type Props = {
  onClick: () => void,
  t: Function,
  id: string
};

const AddButtonBase = function(props: Props) {
  return (
    <Button {...props} wide secondary type={'button'} onClick={props.onClick}>
      <Icon strokeColor="#0072CE" name={icons.ADD} size={22} />
      {props.t('common.addNew')}
    </Button>
  );
};

const AddButton = (AddButtonBase);
AddButton.displayName = 'AddButton';
export default AddButton;
