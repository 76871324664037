/* eslint-disable */
import React from "react";
import { Dialog, Grid, Button, SvgIcon, Box } from "@material-ui/core";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import MuiTypography from "../../Shared/Typography/MuiTypography";
import { withStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

const buttonstyle = {
  color: "#FFFFFF",
  backgroundColor: "#142A39",
};

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    outline: "none ",
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
    flexDirection: "column",
  },
}))(MuiDialogActions);

export default function PatientDeclinePopUp(props) {
  const { t } = useTranslation(); 
  return (
    <Dialog
      // fullWidth={true}
      // maxWidth={"xs"}
      onClose={props.handleClose}
      aria-labelledby="simple-dialog-title"
      open={props.open}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      maxWidth="false"
    >
       <div style={{width: 343, height: 264}}>
      <DialogContent>
      <Box mt={3} />
        <Grid container justify="center" alignItems="center">
          <SvgIcon viewBox="0 0 72 61" style={{ fontSize: 61 }}>
            <path
              d="M39.1998 38.6H32.7998V25.8H39.1998V38.6ZM39.1998 51.4H32.7998V45H39.1998V51.4ZM0.799805 61H71.1998L35.9998 0.199951L0.799805 61Z"
              fill="#CC3D3F"
            />
          </SvgIcon>
        </Grid>
        <Box mt={3} />
        <Grid container justify="center" alignItems="center">
          <MuiTypography
            fontSize="20px"
            fontFamily="Work Sans"
            lineHeight="24px"
            fontWeight={600}
            color="#142A39"
          >
            {t("Patient Declined Consultation")}
          </MuiTypography>
        </Grid>
      </DialogContent>
      <DialogActions disableSpacing={true}>
        <>
        <Box mt={3} />
          <Button
            size="large"
            fullWidth
            variant="contained"
            onClick={props.closeofflinepopup}
            style={buttonstyle}
          >
            {t("Okay")}
          </Button>
        </>
      </DialogActions>
      </div>
    </Dialog>
  );
}
