import { useState, useRef } from 'react';

const usePatientchatTimer = (initialState = 0) => {
  const [patientchattimer, setpatientchattimer] = useState(initialState);
  const [isActive, setIsActive] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const countRef = useRef(null);

  const handlepatientchattimerStart = () => {
    setIsActive(true)
    setIsPaused(true)
    countRef.current = setInterval(() => {
      setpatientchattimer((timer) => timer + 1)
    }, 1000)
  }


  const handlepatientchattimerReset = () => {
    clearInterval(countRef.current)
    setIsActive(false);
    setIsPaused(false);
    setpatientchattimer(0);
  }

  return { patientchattimer, isActive, isPaused, handlepatientchattimerStart, handlepatientchattimerReset }
}

export default usePatientchatTimer;