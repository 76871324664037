import React, { forwardRef } from "react";
import { useSelector } from "react-redux";

const Authorize = forwardRef((props) => {
  const canManageAvailability = useSelector((state) => state.user.profile.canManageAvailability);

  return (
    <>
      {/* {localStorage.getItem("kardiapro_admin_permission") === "false" ? ( */}
      {canManageAvailability === false ? ( 
        <></>
      ) : (
        props.children
      )}
    </>
  );
});

export default Authorize;
