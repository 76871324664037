import React, { useState, useEffect, useCallback } from "react";
import {
  Grid,
  IconButton,
  Paper,
  SvgIcon,
  Box,
  InputAdornment,
  Popover,
  Button,
  FormControl,
  InputLabel,
  Select,
  Chip,
  MenuItem,
  OutlinedInput,
  Checkbox,
  TextField,
} from "@material-ui/core";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import VisibilityIcon from "@material-ui/icons/Visibility";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import Weekpicker from "../Appointments/Calendar/material_datepicker_week";
import Datepicker from "../Appointments/Calendar/material_datepicker_metrix";
import MonthPicker from "../Appointments/Calendar/material_monthpicker";
import YearPicker from "../Appointments/Calendar/material_yearpicker";
import DonutChart from "../Shared/Chart/DonutChart";
import MuiTypography from "../Shared/Typography/MuiTypography";
import apiservice from "../../api/services/apiservice.service";
import { streamToPromise, getTimezoneName } from "../../Utilities/Utilities";
import _get from "lodash.get";
import moment from "moment";
import startOfMonth from "date-fns/startOfMonth";
import endOfMonth from "date-fns/endOfMonth";
import startOfYear from "date-fns/startOfYear";
import endOfYear from "date-fns/endOfYear";
import endOfWeek from "date-fns/endOfWeek";
import startOfWeek from "date-fns/startOfWeek";
import startOfDay from "date-fns/startOfDay";
import endOfDay from "date-fns/endOfDay";
import { useTranslation } from "react-i18next";
import EcgLoaderSmall from "../Shared/EcgLoaderSmall";
import { useSelector } from "react-redux";
import Timezone from "moment-timezone";
import SearchIcon from "@material-ui/icons/Search";
import FilterListIcon from "@material-ui/icons/FilterList";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CloseIcon from "@material-ui/icons/Close";

const GreenCheckbox = withStyles({
  root: {
    color: "#000000",
    "&$checked": {
      color: "#2D9F86",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const StyledMenuItem = withStyles((theme) => ({
  root: {
    // '&$hover': {
    //   backgroundColor: '#FFFFFF',
    //     '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
    //       color: '#2D9F86',
    //     },
    // },
    "&:focus": {
      backgroundColor: "#FFFFFF",
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: "#2D9F86",
      },
    },
    "&$selected": {
      backgroundColor: "#FFFFFF",
      color: "#2D9F86",
    },
    "&$selected:hover": {
      backgroundColor: "#FFFFFF",
      color: "#2D9F86",
    },
    "& .MuiButtonBase-root": {
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.5),
    },
    "& .MuiListItemIcon-root": {
      minWidth: "40px",
    },
    "& .MuiButtonBase-root:hover": {
      backgroundColor: "#FFFFFF",
    },
    "& .MuiListItem-root.Mui-selected": {
      backgroundColor: "#FFFFFF",
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: "#2D9F86",
      },
    },
    "&.Mui-focusVisible": {
      color: "#FFFFFF",
      backgroundColor: "#FFFFFF",
    },
  },
  selected: {},
}))(MenuItem);

const CssTextField = withStyles({
  root: {
    "& input::placeholder": {
      fontFamily: "Work Sans !important",
     opacity:1,
     color: "#142A39 !important",
   },
    "& label.Mui-focused": {
      color: "#2D9F86",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#2D9F86",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#142A39",
      },
      "&:hover fieldset": {
        borderColor: "#2D9F86",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#2D9F86",
      },
    },
  },
})(TextField);

const useOutlinedInputStyles = makeStyles((theme) => ({
  root: {
    minWidth: 264,
    color: "#000000",
    backgroundColor: "#ffffff",
    "&:hover $notchedOutline": {
      color: "#2D9F86",
      borderColor: "#2D9F86",
    },
    "&$focused $notchedOutline": {
      color: "#000000",
      borderColor: "#2D9F86",
    },
  },
  input: {
    background: "#ffffff",
    "&:hover": {
      color: "#2D9F86",
    },
    "&$focused": {
      color: "#2D9F86",
    },
    fontSize: 16,
    padding: "10px 26px 10px 12px",
  },
  focused: {},
  notchedOutline: {},
}));
const buttonstyle = {
  marginBottom: "10px",
  color: "#2D9F86",
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    borderBottom: "1px solid #e8e8e8",
    backgroundColor: "transparent",
  },
  paperroot: {
    flexGrow: 1,
    flexDirection: "row",
    // justifyContent: "flex-end",
  },
  customTabRoot: {
    color: "#142A39",
    // backgroundColor: "#fff !important",
    // borderBottom: "1px solid #e8e8e8",
    // padding: "5px",
    marginBottom: "1px",
    boxShadow:
      "0px 0px 1px rgba(0, 0, 0, 0.16), 0px 1px 2px rgba(0, 0, 0, 0.12), 0px 1px 2px rgba(0, 0, 0, 0.24)",
    // minWidth: 75,
    fontWeight: 700,
  },
  customTabIndicator: {
    backgroundColor: "#2d9f86",
    height: "4px",
  },
  tabRoot: {
    background: "#fff !important",
    fontSize: "14px",
    fontWeight: "bold",
    opacity: "0.54",
    minWidth: 75,
    // fontFamily: ["Work Sans"].join(","),
    fontFamily: "Work Sans !important",
  },
  label: {
    fontFamily: "Work Sans !important",
    fontWeight: "700 !important",
  },
  tabvaluestyle: {
    // minWidth: "130px",
  },
  tabvaluesmallstyle: {
    minWidth: "auto",
    // [theme.breakpoints.up("md")]: {
    //   minWidth: "130px"
    // },
    // [theme.breakpoints.up("xs")]: {
    //   minWidth: "100px"
    // }
  },
  popoverclass: {
    "& .popover_class::before": {
      content: "",
      position: "absolute",
      top: -20,
      right: 5,
      borderBottom: 10,
      zIndex: 10,
    },
    "&::after": {
      content: "",
      position: "absolute",
      top: "-5px",
      right: "4px",
      borderStyle: "solid",
      borderWidth: "0 8px 8px 8px",
      borderColor: "transparent transparent green transparent",
      zIndex: 9998,
    },
  },
  popovergrid: {
    padding: "5px",
    minHeight: 280,
    background: "white",
    width: 590,
  },
  formControllabelstyle: {
    marginLeft: "5px",
    marginRight: "5px",
    fontFamily: "Work Sans !important",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "16px",
    letterSpacing: "0.4px",
    color: "rgba(20, 42, 57, 0.72)",
  },
  formControlchip: {
    background: "#FFFFFF",
    marginLeft: "5px",
    marginRight: "5px",
    marginBottom: "5px",
    // minHeight: 280,
    minWidth: 280,
    maxWidth: 300,
  },
  formControlchipsmall: {
    background: "#FFFFFF",
    // margin: theme.spacing(1),
    marginLeft: "5px",
    marginRight: "5px",
    marginBottom: "5px",
    minWidth: 120,
    minHeight: 280,
    maxWidth: 300,
  },
  autocompleteinput: {
    color: "#000000",
    fontFamily: "Roboto Mono",
    backgroundColor: "#FFFFFF",
    "& .MuiOutlinedInput-notchedOutline": {
      borderWidth: "2px",
      borderColor: "blue",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderWidth: "2px",
      borderColor: "blue",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderWidth: "2px",
      borderColor: "blue",
    },
    "&.Mui-focused": {
      backgroundColor: "#FFFFFF",
      color: "#000000",
    },
  },
  paddingTopmainmenu: {
    paddingLeft:"15px",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingRight: theme.spacing(2),
    background: "white",
  },
  tabpanel: {
    padding: "0px",
    "& .MuiTabPanel-root": {
      padding: "0px",
    },
    "& .MuiBox-root": {
      padding: "0px",
    },
  },
  chipfont:{  
    fontFamily: "Work Sans !important",
    color: "#000000 !important",
    fontSize: "16px",
    lineHeight: "20px",
    letterSpacing: "-0.2px",
    fontWeight: 400
  },
}));

function useWidth() {
  const theme = useTheme();
  const keys = [...theme.breakpoints.keys].reverse();
  return (
    keys.reduce((output, key) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const matches = useMediaQuery(theme.breakpoints.up(key));
      return !output && matches ? key : output;
    }, null) || "xs"
  );
}

export default function ScrollableTabsButtonAuto(props) {
  const { refreshdatetimeaction } = props;

  const { t } = useTranslation();
  const pattern = "YYYY-MM-DDT00:00:00.000";
  const eodpattern = "YYYY-MM-DDT23:59:00.000";
  const outlinedInputClasses = useOutlinedInputStyles();
  const classes = useStyles();

  const [newvals] = useState([
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "10",
    "11",
  ]);
  const [regionselectionmenuitemtext] = useState("All");
  const inputLabel1 = React.useRef(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [applyadvancefilter, setapplyadvancefilter] = useState(false);
  const [combinedarray, setcombinedarray] = useState([]);

  const neededthreeKeys = [
    "videoAppointments",
    "chatAppointments",
    "audioAppointments",
  ];
  const neededfourKeys = [
    "videoAppointments",
    "chatAppointments",
    "audioAppointments",
    "phoneAppointments",
  ];

  const [regionarrayoptions, setregionarrayoptions] = useState([]);
  const [countryarrayoptions, setcountryarrayoptions] = useState([]);

  const [regionarray, setregionarray] = useState([]);
  const [countryarray, setcountryarray] = useState([]);

  const loadLookupAction = useCallback(async () => {
    const countrylookuprequest = streamToPromise(
      apiservice.getapiservice({
        baseUrl: `api/v1/teams/${teamId}/country`,
      })
    );
    const countrylookup = await countrylookuprequest;
    let allcountrydata = {
      name: "All",
      countryId: "all-country-id",
      regions: [
        {
          name: "All",
          abbreviation: "All",
          regionId: "all-region-id",
          countryId: "all-country-id",
        },
      ],
    };

    let addedcountrylookup = [allcountrydata, ...countrylookup.data.result];

    let countryarrayoptionsdata = addedcountrylookup.map((item) => {
      return {
        ...item,
        title: item.name,
        code: item.countryId,
      };
    });
    setcountryarrayoptions(countryarrayoptionsdata);
    // countryarrayoptionsdata[0]
    setcountryarray([countryarrayoptionsdata[0]]);

    let regionarrayoptionsdata = addedcountrylookup[0].regions.map((item) => {
      return {
        ...item,
        title: item.name,
        code: item.regionId,
      };
    });

    setregionarrayoptions(regionarrayoptionsdata);
    // regionarrayoptionsdata[0]
    setregionarray([regionarrayoptionsdata[0]]);
    // countryarrayoptionsdata[0], regionarrayoptionsdata[0]
    setcombinedarray([countryarrayoptionsdata[0], regionarrayoptionsdata[0]]);  
  }, []);

  useEffect(() => {
    loadLookupAction();
  }, []);

  const popoveropen = Boolean(anchorEl);
  const popoverid = popoveropen ? "simple-popover" : undefined;

  const handlepopoveropenClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handlepopoverapplyclick = () => {
    setAnchorEl(null);
    setapplyadvancefilter(!applyadvancefilter);
  };
  const handlepopoverresetclick = () => {
    setAnchorEl(null);
    setcountryarray([]);
    setregionarray([]);
    setcombinedarray([]);
    setTimeout(() => {
      setapplyadvancefilter(!applyadvancefilter);
    }, 100);
  };

  
  const handlepopovercloseclick = () => {
    setAnchorEl(null);
  };

  const changeregionarray = (newregionarray, details) => {
    var newregionarraydata = [];
    if (
      details.option.name === "All" &&
      regionarray.some((el) => el.name === "All")
    ) {
      newregionarraydata = [];
    }
    else if (
      details.option.name === "All" &&
      !regionarray.some((el) => el.name === "All")
    ) {
      newregionarraydata = [...regionarrayoptions];
    }
    else if (
      regionarray.some((el) => el.name === details.option.name)
    ) {
      let filteralnonallregionarray = newregionarray.filter(
        (x) => x.name !== "All"
      );
      newregionarraydata =  [...filteralnonallregionarray];
    }

    else {
      newregionarraydata =  [...newregionarray]; 
    }

    setregionarray([...newregionarraydata]);
    setcombinedarray([...new Set([...countryarray, ...newregionarraydata])]);
  };

  const changecountryarray = (newcountryarray, details) => {
    var newcountryarraydata = [];
    if (
      details.option.name === "All" &&
      countryarray.some((el) => el.name === "All")
    ) {
     newcountryarraydata = [];
    }
    else if (
      details.option.name === "All" &&
      !countryarray.some((el) => el.name === "All")
    ) {
      newcountryarraydata = [...countryarrayoptions];
    }
    else if (
      countryarray.some((el) => el.name === details.option.name)
    ) {
      let filteralnonallcountryarray = newcountryarray.filter(
        (x) => x.name !== "All"
      );
      newcountryarraydata =  [...filteralnonallcountryarray];
    }

    else {
      newcountryarraydata =  [...newcountryarray]; 
    }

    setcountryarray([...newcountryarraydata]);
    let regionarrayoptionsdatatemp = newcountryarraydata.map((item) => {
      let combinedregionArray = [];
      combinedregionArray.push(...item.regions);
      return combinedregionArray;
    });
    let regionarrayoptionsdataflatten = Array.prototype.concat.apply(
      [],
      regionarrayoptionsdatatemp
    );
    let regionarrayoptionsdatatemp2 = regionarrayoptionsdataflatten.map(
      (item) => {
        return {
          ...item,
          title: item.name,
          code: item.regionId,
        };
      }
    );

    setregionarrayoptions(regionarrayoptionsdatatemp2);

    let regionfilteredarraysellected = regionarrayoptionsdatatemp2.filter(
      (x) => {
        if (regionarray.filter((y) => y.name === x.name).length > 0) return x;
      }
    );
    setregionarray(regionfilteredarraysellected);
    setcombinedarray([
      ...new Set([...newcountryarray, ...regionfilteredarraysellected]),
    ]);
  };

  const removetag = (chipToDelete) => {
    let newregionarray = [...regionarray];
    newregionarray = newregionarray.filter(
      (item) => item.name !== chipToDelete.name
    );
    // newregionarray = newregionarray.filter((item) => item.code !== chipToDelete.code);

    let newcountryarray = [...countryarray];
    newcountryarray = newcountryarray.filter(
      (item) => item.name !== chipToDelete.name
    );
    setregionarray([...newregionarray]);
    setcountryarray([...newcountryarray]);
    setcombinedarray([...new Set([...newcountryarray, ...newregionarray])]);
    setTimeout(() => {
      setapplyadvancefilter(!applyadvancefilter);
    }, 100);
  };

  const [value, setValue] = React.useState(0);
  const [startDate, setstartDate] = useState(new Date());
  const [metricsInfo, setmetricsInfo] = useState({});
  const [isloading, setisloading] = useState(false);
  const clinicianId = useSelector((state) => state.user.profile.id);
  const teamId = useSelector((state) => state.user.team.id);
  const timezoneValue = getTimezoneName();
  // const theme = useTheme();
  // const matches = useMediaQuery(theme.breakpoints.up('sm'));
  // console.log("matches",matches);
  const mediawidth = useWidth();
  const timezoneString = useSelector((state) => state.user.team.timezone);
  const offset = -Timezone.tz(timezoneString).utcOffset();
  const countryfilteredarray =
    combinedarray && combinedarray.length > 0
      ? combinedarray.filter((x) => "regions" in x)
      : [];
  const combinecountryvaluetemp =
    countryfilteredarray && countryfilteredarray.length > 0
      ? countryfilteredarray.map((x) => x.name).join()
      : "";
  const combinecountryvalue = !combinecountryvaluetemp.includes("All")
    ? combinecountryvaluetemp
    : "";

  const regionfilteredarray =
    combinedarray && combinedarray.length > 0
      ? combinedarray.filter((x) => !("regions" in x))
      : [];
  const combineregionvaluetemp =
    regionfilteredarray && regionfilteredarray.length > 0
      ? regionfilteredarray.map((x) => x.name).join()
      : "";
  const combineregionvalue = !combineregionvaluetemp.includes("All")
    ? combineregionvaluetemp
    : "";

  const dataLoader = async (tabvalue) => {
    setisloading(true);
    // const dt = new Date();
    const dt = startDate;
    var startdateformatted;
    var enddateformatted;
    if (tabvalue === 0) {
      startdateformatted = moment(startOfDay(dt)).format(pattern);
      enddateformatted = moment(endOfDay(dt)).format(eodpattern);
      // setstartDate(new Date());
      // setendDate(dt);
    } else if (tabvalue === 1) {
      //   startdateformatted = moment(dt).subtract(7, "days").format(pattern);
      //   enddateformatted = moment(dt).format(pattern);
      startdateformatted = moment(startOfWeek(dt)).format(pattern);
      enddateformatted = moment(endOfWeek(dt)).format(eodpattern);
      // setstartDate(dt);
      // setstartDate(startdate);
      // setendDate(dt);
    } else if (tabvalue === 2) {
      //   startdateformatted = moment(dt).subtract(30, "days").format(pattern);
      //   enddateformatted = moment(dt).format(pattern);
      startdateformatted = moment(startOfMonth(dt)).format(pattern);
      enddateformatted = moment(endOfMonth(dt)).format(eodpattern);
      // setstartDate(dt);
      // setstartDate(startdate);
      // setendDate(dt);
    } else if (tabvalue === 3) {
      //   startdateformatted = moment(dt).subtract(365, "days").format(pattern);
      //   enddateformatted = moment(dt).format(pattern);
      startdateformatted = moment(startOfYear(dt)).format(pattern);
      enddateformatted = moment(endOfYear(dt)).format(eodpattern);
      // setstartDate(dt);
    }

    let offsetInMinutes = offset;
    const metricsdatarequest = streamToPromise(
      apiservice.getapiservice({
        baseUrl: `api/v2/teams/${teamId}/admin/${clinicianId}/dashboard/metrics?endDate=${enddateformatted}&offsetInMinutes=${offsetInMinutes}&startDate=${startdateformatted}&timeZone=${timezoneValue}&countryIds=${combinecountryvalue}&regionIds=${combineregionvalue}`,
      })
    );
    const metricsdata = await metricsdatarequest;
    setmetricsInfo(metricsdata.data.result);
    setisloading(false);
  };

  useEffect(() => {
    dataLoader(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    refreshdatetimeaction();
    dataLoader(newValue);
    setValue(newValue);
  };

  useEffect(() => {
    dataLoader(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.refreshaction]);

  useEffect(() => {
    dataLoader(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applyadvancefilter]);

  const handleDateChange = async (date) => {
    setisloading(true);
    let startdateformatted = moment(startOfDay(date)).format(pattern);
    let enddateformatted = moment(endOfDay(date)).format(eodpattern);
    setstartDate(date);
    refreshdatetimeaction();
    let offsetInMinutes = offset;
    const metricsdatarequest = streamToPromise(
      apiservice.getapiservice({
        baseUrl: `api/v2/teams/${teamId}/admin/${clinicianId}/dashboard/metrics?endDate=${enddateformatted}&offsetInMinutes=${offsetInMinutes}&startDate=${startdateformatted}&timeZone=${timezoneValue}&countryIds=${combinecountryvalue}&regionIds=${combineregionvalue}`,
      })
    );
    const metricsdata = await metricsdatarequest;
    setmetricsInfo(metricsdata.data.result);
    setisloading(false);
  };

  const handleWeekChange = async (props) => {
    setisloading(true);
    let startdateformatted = moment(props.daterange.selectedStartDate).format(
      pattern
    );
    let enddateformatted = moment(props.daterange.selectedEndDate).format(
      eodpattern
    );
    setstartDate(props.daterange.selectedStartDate);
    refreshdatetimeaction();
    let offsetInMinutes = offset;
    const metricsdatarequest = streamToPromise(
      apiservice.getapiservice({
        baseUrl: `api/v2/teams/${teamId}/admin/${clinicianId}/dashboard/metrics?endDate=${enddateformatted}&offsetInMinutes=${offsetInMinutes}&startDate=${startdateformatted}&timeZone=${timezoneValue}&countryIds=${combinecountryvalue}&regionIds=${combineregionvalue}`,
      })
    );
    const metricsdata = await metricsdatarequest;
    setmetricsInfo(metricsdata.data.result);
    setisloading(false);
  };

  const handleMonthChange = async (props) => {
    setisloading(true);
    let startdateformatted = moment(props.daterange.selectedStartDate).format(
      pattern
    );
    let enddateformatted = moment(props.daterange.selectedEndDate).format(
      eodpattern
    );
    setstartDate(props.daterange.selectedStartDate);
    refreshdatetimeaction();
    let offsetInMinutes = offset;
    const metricsdatarequest = streamToPromise(
      apiservice.getapiservice({
        baseUrl: `api/v2/teams/${teamId}/admin/${clinicianId}/dashboard/metrics?endDate=${enddateformatted}&offsetInMinutes=${offsetInMinutes}&startDate=${startdateformatted}&timeZone=${timezoneValue}&countryIds=${combinecountryvalue}&regionIds=${combineregionvalue}`,
      })
    );
    const metricsdata = await metricsdatarequest;
    setmetricsInfo(metricsdata.data.result);
    setisloading(false);
  };

  const handleYearChange = async (props) => {
    setisloading(true);
    let startdateformatted = moment(props.daterange.selectedStartDate).format(
      pattern
    );
    let enddateformatted = moment(props.daterange.selectedEndDate).format(
      eodpattern
    );
    setstartDate(props.daterange.selectedStartDate);
    refreshdatetimeaction();
    let offsetInMinutes = offset;
    const metricsdatarequest = streamToPromise(
      apiservice.getapiservice({
        baseUrl: `api/v2/teams/${teamId}/admin/${clinicianId}/dashboard/metrics?endDate=${enddateformatted}&offsetInMinutes=${offsetInMinutes}&startDate=${startdateformatted}&timeZone=${timezoneValue}&countryIds=${combinecountryvalue}&regionIds=${combineregionvalue}`,
      })
    );
    const metricsdata = await metricsdatarequest;
    setmetricsInfo(metricsdata.data.result);
    setisloading(false);
  };

  const DashboardComponent = (props) => {
    return (
      <div className="telekardia">
        <div className="admin_home_scroll">
          <Grid
            alignItems="center"
            container
            justify={mediawidth === "xl" ? "flex-start" : "flex-start"}
          >
            <Grid item xs={mediawidth === "xl" ? 12 : 12} spacing={0}>
              {/* <div className="row m-10"> */}
              {/* <div className="col-12 pt-0"> */}
              <div>
                {/* <div className="row mt-4"> */}
                <div className="row">
                  <div className="col-12">
                    <div
                      className={
                        mediawidth === "sm"
                          ? "card height500 boxshadow1"
                          : "card height360 boxshadow1"
                      }
                    >
                      <div className="card-body">
                        {isloading === true ? (
                          <EcgLoaderSmall />
                        ) : (
                          <div
                            className="row no-gutters"
                            style={{ display: "-ms-flexbox" }}
                          >
                            <div className="col-6">
                              <h5 className="card-title text-uppercase mb-0">
                                <SvgIcon viewBox="0 0 22 22">
                                  <path
                                    d="M21.9655 9.8002H12.3655V0.200195C14.9116 0.200195 17.3534 1.21162 19.1537 3.01197C20.9541 4.81232 21.9655 7.25412 21.9655 9.8002ZM19.5655 12.2002C19.5655 15.5362 17.8615 18.4762 15.2815 20.2042L10.6615 12.2002H19.5655ZM9.96548 21.8002C6.62948 21.8002 3.68948 20.0962 1.96148 17.5162L9.74948 13.0162L14.2375 20.8042C12.9655 21.4402 11.5015 21.8002 9.96548 21.8002ZM0.365479 12.2002C0.365479 9.65412 1.3769 7.21232 3.17725 5.41197C4.9776 3.61162 7.4194 2.6002 9.96548 2.6002V11.5042L1.36148 16.4722C0.725479 15.2002 0.365479 13.7362 0.365479 12.2002Z"
                                    fill="#536063"
                                  />
                                </SvgIcon>
                                <span className="pl-2">
                                  {t("CONSULTATION SUMMARY")}
                                </span>
                              </h5>
                              {/* here */}

                              <DonutChart
                                values={[
                                  _get(metricsInfo, "closedAppointments"),
                                  _get(metricsInfo, "completedAppointments"),
                                  _get(
                                    metricsInfo,
                                    "physicianCancelledAppointments"
                                  ) + _get(metricsInfo, "missedAppointments"),
                                  _get(
                                    metricsInfo,
                                    "patientCancelledAppointments"
                                  ),
                                  _get(metricsInfo, "scheduledAppointments"),
                                  _get(
                                    metricsInfo,
                                    "adminCancelledAppointments"
                                  ),
                                  _get(metricsInfo, "inProgressAppointments"),
                                ]}
                                totalAppointments={_get(
                                  metricsInfo,
                                  "totalAppointments"
                                )}
                              />
                            </div>
                            <div className="col-6">
                              <ul className="list-group">
                                <li className="list-group-item border-left-0 border-top-0 border-right-0 border_bot f16 py-3 pl-0 d-flex minheight_57">
                                  <span className="d-inline-block circle_b circle_light_purple_premium ml-1 mr-3 align-self-center"></span>
                                  <span className="d-inline-block align-self-center">
                                    {t("Scheduled")}
                                  </span>
                                  <span
                                    className="caption20 ml-auto"
                                    style={{ display: "inline-block" }}
                                  >
                                    {_get(metricsInfo, "scheduledAppointments")}
                                  </span>
                                </li>
                                <li className="list-group-item border-left-0 border-top-0 border-right-0 border_bot f16 py-3 pl-0 d-flex minheight_57">
                                  <span className="d-inline-block circle_b circle_light_blue ml-1 mr-3 align-self-center"></span>
                                  <span className="d-inline-block align-self-center">
                                    {t("In progress")}
                                  </span>
                                  <span
                                    className="caption20 ml-auto"
                                    style={{ display: "inline-block" }}
                                  >
                                    {_get(
                                      metricsInfo,
                                      "inProgressAppointments"
                                    )}
                                  </span>
                                </li>
                                <li className="list-group-item border-left-0 border-top-0 border-right-0 border_bot f16 py-3 pl-0 d-flex minheight_57">
                                  <span className="d-inline-block circle_b circle_light_teal ml-1 mr-3 align-self-center"></span>
                                  <span className="d-inline-block align-self-center">
                                    {t("Completed")}
                                  </span>
                                  <span
                                    className="caption20 ml-auto"
                                    style={{ display: "inline-block" }}
                                  >
                                    {_get(metricsInfo, "completedAppointments")}
                                  </span>
                                </li>
                                <li className="list-group-item border-left-0 border-top-0 border-right-0 border_bot f16 py-3 pl-0 d-flex minheight_57">
                                  <span className="d-inline-block circle_b circle_grey ml-1 mr-3 align-self-center"></span>
                                  <span className="d-inline-block align-self-center">
                                    {t("Closed")}
                                  </span>
                                  <span
                                    className="caption20  ml-auto"
                                    style={{ display: "inline-block" }}
                                  >
                                    {_get(metricsInfo, "closedAppointments")}
                                  </span>
                                </li>
                                <li className="list-group-item border-left-0 border-top-0 border-right-0 border_bot f16 py-3 pl-0 d-flex minheight_57">
                                  <span className="d-inline-block circle_b circle_red ml-1 mr-3 align-self-center"></span>
                                  <span className="d-inline-block align-self-center">
                                    {t("Patient canceled")}
                                  </span>
                                  <span
                                    className="caption20  ml-auto"
                                    style={{ display: "inline-block" }}
                                  >
                                    {_get(
                                      metricsInfo,
                                      "patientCancelledAppointments"
                                    )}
                                  </span>
                                </li>
                                <li className="list-group-item border-left-0 border-top-0 border-right-0 border_bot f16 py-3 pl-0 d-flex minheight_57">
                                  <span className="d-inline-block circle_b circle_yellow ml-1 mr-3 align-self-center"></span>
                                  <span className="d-inline-block align-self-center">
                                    {t("Doctor canceled")}
                                  </span>
                                  <span
                                    className="caption20 ml-auto"
                                    style={{ display: "inline-block" }}
                                  >
                                    {_get(
                                      metricsInfo,
                                      "physicianCancelledAppointments"
                                    )}
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="row mt-4 mb-4"
                  style={{ display: "-ms-flexbox" }}
                >
                  <div className="col-6">
                    <div
                      className={
                        mediawidth === "sm"
                          ? "card maxheight523 boxshadow1"
                          : "card height360 boxshadow1"
                      }
                    >
                      <div className="card-body">
                        <h5 className="card-title text-uppercase">
                          <VisibilityIcon />{" "}
                          <span>{t("MONITORING parameters")}</span>
                        </h5>
                        {isloading === true ? (
                          <EcgLoaderSmall />
                        ) : (
                          <>
                            <div
                              className="row no-gutters"
                              style={{ display: "-ms-flexbox" }}
                            >
                              <h6 className="small_head mt-3 font_green">
                                {t("CARDIOLOGIST RELATED")}
                              </h6>
                              <div className="col-12">
                                <ul className="list-group list-group-flush">
                                  <li
                                    className={
                                      mediawidth === "sm"
                                        ? "list-group-item border-left-0 border-top-0 border-right-0 border_bot f16 pl-0 d-flex maxheight_45"
                                        : "list-group-item border-left-0 border-top-0 border-right-0 border_bot f16 py-3 pl-0 d-flex minheight_57"
                                    }
                                  >
                                    <span className="d-inline-block align-self-center">
                                      {t("Doctor missed")}
                                    </span>
                                    <span
                                      className={
                                        mediawidth === "sm"
                                          ? "caption20small ml-auto"
                                          : "caption20 ml-auto"
                                      }
                                      style={{ display: "inline-block" }}
                                    >
                                      {_get(metricsInfo, "missedAppointments")}
                                    </span>
                                  </li>
                                  <li
                                    className={
                                      mediawidth === "sm"
                                        ? "list-group-item border-left-0 border-top-0 border-right-0 border_bot f16 pl-0 d-flex maxheight_45"
                                        : "list-group-item border-left-0 border-top-0 border-right-0 border_bot f16 py-3 pl-0 d-flex minheight_57"
                                    }
                                  >
                                    <span className="d-inline-block align-self-center">
                                      {t("Doctor canceled")}
                                    </span>
                                    <span
                                      className={
                                        mediawidth === "sm"
                                          ? "caption20small ml-auto"
                                          : "caption20 ml-auto"
                                      }
                                      style={{ display: "inline-block" }}
                                    >
                                      {_get(
                                        metricsInfo,
                                        "physicianCancelledAppointments"
                                      )}
                                    </span>
                                  </li>
                                  <li
                                    className={
                                      mediawidth === "sm"
                                        ? "list-group-item border-left-0 border-top-0 border-right-0 border_bot f16 pl-0 d-flex maxheight_45"
                                        : "list-group-item border-left-0 border-top-0 border-right-0 border_bot f16 py-3 pl-0 d-flex minheight_57"
                                    }
                                  >
                                    <span className="d-inline-block align-self-center">
                                      {t("Late cancel")}
                                    </span>
                                    <span
                                      className={
                                        mediawidth === "sm"
                                          ? "caption20small ml-auto"
                                          : "caption20 ml-auto"
                                      }
                                      style={{ display: "inline-block" }}
                                    >
                                      {_get(
                                        metricsInfo,
                                        "impromptuCancelledAppointments"
                                      )}
                                    </span>
                                  </li>
                                  <li
                                    className={
                                      mediawidth === "sm"
                                        ? "list-group-item border-left-0 border-top-0 border-right-0 border_bot f16 pl-0 d-flex maxheight_45"
                                        : "list-group-item border-left-0 border-top-0 border-right-0 border_bot f16 py-3 pl-0 d-flex minheight_57"
                                    }
                                  >
                                    <span className="d-inline-block align-self-center">
                                      {t("Delayed start")}
                                    </span>
                                    <span
                                      className={
                                        mediawidth === "sm"
                                          ? "caption20small ml-auto"
                                          : "caption20 ml-auto"
                                      }
                                      style={{ display: "inline-block" }}
                                    >
                                      {_get(
                                        metricsInfo,
                                        "delayedStartAppointments"
                                      )}
                                    </span>
                                  </li>
                                  <li
                                    className={
                                      mediawidth === "sm"
                                        ? "list-group-item border-left-0 border-top-0 border-right-0 border_bot f16 pl-0 d-flex maxheight_45"
                                        : "list-group-item border-left-0 border-top-0 border-right-0 border_bot f16 py-3 pl-0 d-flex minheight_57"
                                    }
                                  >
                                    <span className="d-inline-block align-self-center">
                                      {t("Exceeded end time")}
                                    </span>
                                    <span
                                      className={
                                        mediawidth === "sm"
                                          ? "caption20small ml-auto"
                                          : "caption20 ml-auto"
                                      }
                                      style={{ display: "inline-block" }}
                                    >
                                      {_get(
                                        metricsInfo,
                                        "exceededEndTimeAppointments"
                                      )}
                                    </span>
                                  </li>
                                  <li
                                    className={
                                      mediawidth === "sm"
                                        ? "list-group-item border-left-0 border-top-0 border-right-0 border_bot f16 pl-0 d-flex maxheight_45"
                                        : "list-group-item border-left-0 border-top-0 border-right-0 border_bot f16 py-3 pl-0 d-flex minheight_57"
                                    }
                                  >
                                    <span className="d-inline-block align-self-center">
                                      {t("Reassigned")}
                                    </span>
                                    <span
                                      className={
                                        mediawidth === "sm"
                                          ? "caption20small ml-auto"
                                          : "caption20 ml-auto"
                                      }
                                      style={{ display: "inline-block" }}
                                    >
                                      {_get(
                                        metricsInfo,
                                        "physicianReAssignedAppointments"
                                      )}
                                    </span>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div className="row no-gutters">
                              <h6 className="small_head mt-4 font_green">
                                {t("PATIENT RELATED")}
                              </h6>
                              <div className="col-12">
                                <ul className="list-group list-group-flush">
                                  <li
                                    className={
                                      mediawidth === "sm"
                                        ? "list-group-item border-left-0 border-top-0 border-right-0 border_bot f16 py-3 pl-0 d-flex maxheight_45"
                                        : "list-group-item border-left-0 border-top-0 border-right-0 border_bot f16 py-3 pl-0 d-flex minheight_57"
                                    }
                                  >
                                    <span className="d-inline-block align-self-center">
                                      {t("Rescheduled")}
                                    </span>
                                    <span
                                      className={
                                        mediawidth === "sm"
                                          ? "caption20small ml-auto"
                                          : "caption20 ml-auto"
                                      }
                                      style={{ display: "inline-block" }}
                                    >
                                      {_get(
                                        metricsInfo,
                                        "patientRescheduledAppointments"
                                      )}
                                    </span>
                                  </li>
                                  <li
                                    className={
                                      mediawidth === "sm"
                                        ? "list-group-item border-left-0 border-top-0 border-right-0 border_bot f16 py-3 pl-0 d-flex maxheight_45"
                                        : "list-group-item border-left-0 border-top-0 border-right-0 border_bot f16 py-3 pl-0 d-flex minheight_57"
                                    }
                                  >
                                    <span className="d-inline-block align-self-center">
                                      {t("Patient missed")}
                                    </span>
                                    <span
                                      className={
                                        mediawidth === "sm"
                                          ? "caption20small ml-auto"
                                          : "caption20 ml-auto"
                                      }
                                      style={{ display: "inline-block" }}
                                    >
                                      {_get(
                                        metricsInfo,
                                        "patientNoShowAppointments"
                                      )}
                                    </span>
                                  </li>
                                  <li
                                    className={
                                      mediawidth === "sm"
                                        ? "list-group-item border-0 f16 py-3 pl-0 d-flex maxheight_45"
                                        : "list-group-item border-0 f16 py-3 pl-0 d-flex minheight_57"
                                    }
                                  >
                                    <span className="d-inline-block align-self-center">
                                      {t("Declined")}
                                    </span>
                                    <span
                                      className={
                                        mediawidth === "sm"
                                          ? "caption20small ml-auto"
                                          : "caption20 ml-auto"
                                      }
                                      style={{ display: "inline-block" }}
                                    >
                                      {_get(
                                        metricsInfo,
                                        "patientDeclinedAppointments"
                                      )}
                                    </span>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div
                      className={
                        mediawidth === "sm" || mediawidth === "md"
                          ? "column"
                          : "row"
                      }
                      style={{ display: "-ms-flexbox" }}
                    >
                      <div
                        className={
                          mediawidth === "sm" || mediawidth === "md"
                            ? ""
                            : "col-6"
                        }
                      >
                        <div
                          className={
                            mediawidth === "sm"
                              ? "card height171 boxshadow1"
                              : "card boxshadow1"
                          }
                        >
                          <div className="card-body">
                            <h5 className="card-title text-uppercase">
                              <LocalOfferIcon />{" "}
                              <span className="pl-2">
                                {t("CONSULTATION TYPES")}
                              </span>
                            </h5>
                            {isloading === true ? (
                              <EcgLoaderSmall />
                            ) : (
                              <div
                                className="row no-gutters"
                                style={{ display: "-ms-flexbox" }}
                              >
                                <div className="col-6 text-center p-2">
                                  <div className="bg_green radius8 p-2">
                                    <h5 className="big_font">
                                      {_get(metricsInfo, "generalAppointments")}
                                    </h5>
                                    <div className="f18">{t("General")}</div>
                                  </div>
                                </div>
                                <div className="col-6 text-center p-2">
                                  <div className="bg_green radius8 p-2">
                                    <h5 className="big_font">
                                      {_get(
                                        metricsInfo,
                                        "followUpAppointments"
                                      )}
                                    </h5>
                                    <div className="f18">{t("Follow-up")}</div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      {(mediawidth === "sm" || mediawidth === "md") && (
                        <Box m={2} />
                      )}
                      <div
                        className={
                          mediawidth === "sm" || mediawidth === "md"
                            ? ""
                            : "col-6"
                        }
                      >
                        <div
                          className={
                            mediawidth === "sm"
                              ? "card height171 boxshadow1"
                              : "card boxshadow1"
                          }
                        >
                          <div className="card-body">
                            <h5 className="card-title text-uppercase">
                              <SvgIcon viewBox="12.1655 11.5532 24 23.106399999999997">
                                <path
                                  d="M33.7655 11.5532H14.5655C13.2455 11.5532 12.1655 12.593 12.1655 13.8639V34.6596L16.9655 30.0383H33.7655C35.0855 30.0383 36.1655 28.9985 36.1655 27.7277V13.8639C36.1655 12.593 35.0855 11.5532 33.7655 11.5532ZM24.0942 27.6843L23.0888 26.8031C21.2052 25.1586 19.6592 23.8077 18.6187 22.5287H19.9655C20.1633 22.5287 20.3483 22.4349 20.4602 22.2779L20.9635 21.572L21.8695 22.8537C21.9881 23.0215 22.1895 23.1171 22.4 23.1054C22.6105 23.0938 22.7992 22.9766 22.897 22.7968L24.3825 20.0664L25.5858 24.4105C25.6534 24.6547 25.8781 24.8282 26.1403 24.8389C26.4026 24.8495 26.6416 24.6947 26.7302 24.4569L27.7876 21.621H30.2268C29.249 23.1786 27.4335 24.7656 25.0996 26.8031L24.0942 27.6843ZM30.7933 20.4657H27.3655C27.1123 20.4657 26.8864 20.6187 26.8008 20.8481L26.2501 22.3251L25.1453 18.3362C25.0815 18.1062 24.8777 17.9373 24.632 17.911C24.3862 17.8846 24.1488 18.0061 24.034 18.217L22.2984 21.4071L21.4616 20.2232C21.3501 20.0654 21.1649 19.9709 20.9667 19.9705C20.7685 19.9701 20.583 20.064 20.4708 20.2213L19.6496 21.3734H17.8132C17.3895 20.6313 17.1603 19.8907 17.1603 19.106C17.1603 17.0432 18.8383 15.4344 20.974 15.4344C22.1805 15.4344 23.3384 15.9751 24.0942 16.8229C24.85 15.9751 26.008 15.4344 27.2145 15.4344C29.3501 15.4344 31.0281 17.0432 31.0281 19.106C31.0281 19.5716 30.9474 20.0216 30.7933 20.4657Z"
                                  fill="#536063"
                                />
                              </SvgIcon>
                              {/* <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M33.7655 11.5532H14.5655C13.2455 11.5532 12.1655 12.593 12.1655 13.8639V34.6596L16.9655 30.0383H33.7655C35.0855 30.0383 36.1655 28.9985 36.1655 27.7277V13.8639C36.1655 12.593 35.0855 11.5532 33.7655 11.5532ZM24.0942 27.6843L23.0888 26.8031C21.2052 25.1586 19.6592 23.8077 18.6187 22.5287H19.9655C20.1633 22.5287 20.3483 22.4349 20.4602 22.2779L20.9635 21.572L21.8695 22.8537C21.9881 23.0215 22.1895 23.1171 22.4 23.1054C22.6105 23.0938 22.7992 22.9766 22.897 22.7968L24.3825 20.0664L25.5858 24.4105C25.6534 24.6547 25.8781 24.8282 26.1403 24.8389C26.4026 24.8495 26.6416 24.6947 26.7302 24.4569L27.7876 21.621H30.2268C29.249 23.1786 27.4335 24.7656 25.0996 26.8031L24.0942 27.6843ZM30.7933 20.4657H27.3655C27.1123 20.4657 26.8864 20.6187 26.8008 20.8481L26.2501 22.3251L25.1453 18.3362C25.0815 18.1062 24.8777 17.9373 24.632 17.911C24.3862 17.8846 24.1488 18.0061 24.034 18.217L22.2984 21.4071L21.4616 20.2232C21.3501 20.0654 21.1649 19.9709 20.9667 19.9705C20.7685 19.9701 20.583 20.064 20.4708 20.2213L19.6496 21.3734H17.8132C17.3895 20.6313 17.1603 19.8907 17.1603 19.106C17.1603 17.0432 18.8383 15.4344 20.974 15.4344C22.1805 15.4344 23.3384 15.9751 24.0942 16.8229C24.85 15.9751 26.008 15.4344 27.2145 15.4344C29.3501 15.4344 31.0281 17.0432 31.0281 19.106C31.0281 19.5716 30.9474 20.0216 30.7933 20.4657Z" fill="#536063"/>
                          </svg> */}
                              <span className="pl-2">
                                {t("CONSULTATION METHODS")}
                              </span>
                            </h5>
                            {isloading === true ? (
                              <EcgLoaderSmall />
                            ) : (
                              <div
                                className="row no-gutters"
                                style={{ display: "-ms-flexbox" }}
                              >
                                {"audioAppointments" in metricsInfo && (
                                  <div
                                    className={
                                      neededfourKeys.every((key) =>
                                        Object.keys(metricsInfo).includes(key)
                                      )
                                        ? "col-6 text-center p-2"
                                        : neededthreeKeys.every((key) =>
                                            Object.keys(metricsInfo).includes(
                                              key
                                            )
                                          )
                                        ? "col-4 text-center p-2"
                                        : "col-6 text-center p-2"
                                    }
                                  >
                                    <div className="bg_green radius8 p-2">
                                      <h5 className={(_get(metricsInfo, "audioAppointments") ||  0) <1000 ? "big_font" : "medium_font"}>
                                        {_get(metricsInfo, "audioAppointments")}
                                      </h5>
                                      <div className="f18">{t("Audio")}</div>
                                    </div>
                                  </div>
                                )}
                                {"videoAppointments" in metricsInfo && (
                                  <div
                                    className={
                                      neededfourKeys.every((key) =>
                                        Object.keys(metricsInfo).includes(key)
                                      )
                                        ? "col-6 text-center p-2"
                                        : neededthreeKeys.every((key) =>
                                            Object.keys(metricsInfo).includes(
                                              key
                                            )
                                          )
                                        ? "col-4 text-center p-2"
                                        : "col-6 text-center p-2"
                                    }
                                  >
                                    <div className="bg_green radius8 p-2">
                                      <h5 className= {(_get(metricsInfo, "videoAppointments") ||  0) <1000 ? "big_font" : "medium_font"}>
                                        {_get(metricsInfo, "videoAppointments")}
                                      </h5>
                                      <div className="f18">{t("Video")}</div>
                                    </div>
                                  </div>
                                )}
                                {"chatAppointments" in metricsInfo && (
                                  <div
                                    className={
                                      neededfourKeys.every((key) =>
                                        Object.keys(metricsInfo).includes(key)
                                      )
                                        ? "col-6 text-center p-2"
                                        : neededthreeKeys.every((key) =>
                                            Object.keys(metricsInfo).includes(
                                              key
                                            )
                                          )
                                        ? "col-4 text-center p-2"
                                        : "col-6 text-center p-2"
                                    }
                                  >
                                    <div className="bg_green radius8 p-2">
                                      <h5 className={(_get(metricsInfo, "chatAppointments") ||  0) <1000 ? "big_font" : "medium_font"}>
                                        {_get(metricsInfo, "chatAppointments")}
                                      </h5>
                                      <div className="f18">{t("Chat")}</div>
                                    </div>
                                  </div>
                                )}
                                {"phoneAppointments" in metricsInfo && (
                                  <div
                                    className={
                                      neededfourKeys.every((key) =>
                                        Object.keys(metricsInfo).includes(key)
                                      )
                                        ? "col-6 text-center p-2"
                                        : neededthreeKeys.every((key) =>
                                            Object.keys(metricsInfo).includes(
                                              key
                                            )
                                          )
                                        ? "col-4 text-center p-2"
                                        : "col-6 text-center p-2"
                                    }
                                  >
                                    <div className="bg_green radius8 p-2">
                                      <h5 className={(_get(metricsInfo, "phoneAppointments") ||  0) <1000 ? "big_font" : "medium_font"}>
                                        {_get(metricsInfo, "phoneAppointments")}
                                      </h5>
                                      <div className="f18">{t("Phone")}</div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <Box m={2} />
                    <div
                      className={
                        mediawidth === "sm"
                          ? "card boxshadow1 my-2"
                          : "card height282 boxshadow1 my-4"
                      }
                    >
                      <div className="card-body">
                        <h5 className="card-title text-uppercase">
                          <SvgIcon viewBox="0 0 25 24">
                            <path
                              d="M15.2735 3.6L12.1655 6.708L9.05753 3.6L7.36553 5.292L10.4735 8.4L7.36553 11.508L9.05753 13.2L12.1655 10.092L15.2735 13.2L16.9655 11.508L13.8575 8.4L16.9655 5.292L15.2735 3.6ZM18.1655 0C18.802 0 19.4125 0.252856 19.8626 0.702944C20.3127 1.15303 20.5655 1.76348 20.5655 2.4V14.4C20.5655 15.0365 20.3127 15.647 19.8626 16.0971C19.4125 16.5471 18.802 16.8 18.1655 16.8H13.3655V19.2H14.5655C14.8838 19.2 15.189 19.3264 15.4141 19.5515C15.6391 19.7765 15.7655 20.0817 15.7655 20.4H24.1655V22.8H15.7655C15.7655 23.1183 15.6391 23.4235 15.4141 23.6485C15.189 23.8736 14.8838 24 14.5655 24H9.76553C9.44727 24 9.14204 23.8736 8.917 23.6485C8.69196 23.4235 8.56553 23.1183 8.56553 22.8H0.165527V20.4H8.56553C8.56553 20.0817 8.69196 19.7765 8.917 19.5515C9.14204 19.3264 9.44727 19.2 9.76553 19.2H10.9655V16.8H6.16553C4.83353 16.8 3.76553 15.72 3.76553 14.4V2.4C3.76553 1.76348 4.01838 1.15303 4.46847 0.702944C4.91856 0.252856 5.52901 0 6.16553 0H18.1655Z"
                              fill="#536063"
                            />
                          </SvgIcon>{" "}
                          <span className="pl-2">
                            {t("CLOSED consultations")}
                          </span>
                        </h5>
                        {isloading === true ? (
                          <EcgLoaderSmall />
                        ) : (
                          <div className="row no-gutters">
                            <div className="col-12">
                              <ul className="list-group list-group-flush">
                                <li className="list-group-item border-left-0 border-top-0 border-right-0 border_bot f16 py-3 pl-0 d-flex">
                                  <span className="d-inline-block align-self-center">
                                    {t("Patient missed")}
                                  </span>
                                  <span
                                    className="caption20 ml-auto"
                                    style={{ display: "inline-block" }}
                                  >
                                    {_get(
                                      metricsInfo,
                                      "patientNoShowAppointments"
                                    )}
                                  </span>
                                </li>
                                <li className="list-group-item border-left-0 border-top-0 border-right-0 border_bot f16 py-3 pl-0 d-flex">
                                  <span className="d-inline-block align-self-center">
                                    {t("Declined")}
                                  </span>
                                  <span
                                    className="caption20 ml-auto"
                                    style={{ display: "inline-block" }}
                                  >
                                    {_get(
                                      metricsInfo,
                                      "patientDeclinedAppointments"
                                    )}
                                  </span>
                                </li>
                                <li className="list-group-item border-left-0 border-top-0 border-right-0 border_bot f16 py-3 pl-0 d-flex">
                                  <span className="d-inline-block align-self-center">
                                    {t("Technical issue - doctor’s side")}
                                  </span>
                                  <span
                                    className="caption20  ml-auto"
                                    style={{ display: "inline-block" }}
                                  >
                                    {_get(
                                      metricsInfo,
                                      "physicianTechnicalIssueAppointments"
                                    )}
                                  </span>
                                </li>
                                <li className="list-group-item border-0 f16 py-3 pl-0 d-flex">
                                  <span className="d-inline-block align-self-center">
                                    {t("Technical issue - patient’s side")}
                                  </span>
                                  <span
                                    className="caption20  ml-auto"
                                    style={{ display: "inline-block" }}
                                  >
                                    {_get(
                                      metricsInfo,
                                      "patientTechnicalIssueAppointments"
                                    )}
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* </div> */}
            </Grid>
          </Grid>
        </div>
      </div>
    );
  };

  return (
    <div className={classes.root}>
      {/* <AppBar position="static" color="default"> */}
      <Paper className={classes.paperroot}>
        <Tabs
          className="custom_tab"
          classes={{
            root: classes.customTabRoot,
            indicator: classes.customTabIndicator,
          }}
          value={value}
          onChange={handleChange}
          scrollButtons="auto"
          // centered
          // variant="scrollable"
          variant="fullWidth"
        >
          <Tab
            className={
              mediawidth === "xl"
                ? classes.tabvaluestyle
                : classes.tabvaluesmallstyle
            }
            label={<span className={classes.label}>{t("Day")}</span>}
            {...a11yProps(0)}
          />
          <Tab
            className={
              mediawidth === "xl"
                ? classes.tabvaluestyle
                : classes.tabvaluesmallstyle
            }
            label={<span className={classes.label}>{t("Week")}</span>}
            {...a11yProps(1)}
          />
          <Tab
            className={
              mediawidth === "xl"
                ? classes.tabvaluestyle
                : classes.tabvaluesmallstyle
            }
            label={<span className={classes.label}>{t("Month")}</span>}
            {...a11yProps(2)}
          />
          <Tab
            className={
              mediawidth === "xl"
                ? classes.tabvaluestyle
                : classes.tabvaluesmallstyle
            }
            label={<span className={classes.label}>{t("Year")}</span>}
            {...a11yProps(3)}
          />

          {/* <Tab component={() =>  <Datepicker
              label="Start Date"
              date={startDate}
              changeHandle={handleDateChange}
            />} value={'/'}  label={'/'}/> */}
        </Tabs>

        {/* </AppBar> */}
      </Paper>
      <Grid
        container
        justify="space-between"
        className={classes.paddingTopmainmenu}
        direction="row"
      >
        <Grid item xs={9} container alignItems="center" justify="flex-start">
          <Grid item>
            <MuiTypography
              fontFamily="Work Sans"
              fontSize="16px"
              fonStyle="normal"
              fontWeight={600}
              lineHeight="20px"
              color="#142A39"
            >
              Filter
            </MuiTypography>
          </Grid>
          <Grid item>
            <IconButton onClick={handlepopoveropenClick} aria-label="Search">
              <FilterListIcon />
            </IconButton>
          </Grid>
          <Grid item>
            {combinedarray &&
              combinedarray.length > 0 &&
              combinedarray.map((value) => (
                <>
                  <Chip
                    variant="outlined"
                    className={classes.chipfont}
                    key={value.code}
                    label={
                      "regions" in value
                        ? `Country - ${value.title}`
                        : `Region - ${value.title}`
                    }
                    onDelete={() => removetag(value)}
                  />
                  &nbsp;
                </>
              ))}
          </Grid>
        </Grid>
        <Grid item xs={3}>
          {value === 0 ? (
            <Datepicker
              label="Start Date"
              padding="14px"
              date={startDate}
              changeHandle={handleDateChange}
            />
          ) : value === 1 ? (
            <Weekpicker
              padding="14px"
              date={startDate}
              changeHandle={handleWeekChange}
            />
          ) : value === 2 ? (
            <MonthPicker
              padding="14px"
              date={startDate}
              changeHandle={handleMonthChange}
            />
          ) : (
            <YearPicker
              padding="14px"
              date={startDate}
              changeHandle={handleYearChange}
            />
          )}
        </Grid>
      </Grid>
      <Popover
        id={popoverid}
        open={popoveropen}
        anchorEl={anchorEl}
        className={classes.popoverclass}
        modifiers={{
          flip: {
            enabled: true,
          },
          preventOverflow: {
            enabled: false,
            boundariesElement: "scrollParent",
          },
          arrow: {
            enabled: true,
            // element: arrowRef,
          },
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Grid
          item
          container
          justify="flex-end"
          alignItems="flex-end"
          direction="row"
          spacing={2}
        >
          <Grid item>
            <IconButton aria-label="close" onClick={handlepopovercloseclick}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>

        <Grid
          container
          justify="space-between"
          direction="row"
          className={classes.popovergrid}
        >
          <Grid item>
            <InputLabel
              ref={inputLabel1}
              className={classes.formControllabelstyle}
            >
              {t("Country")}
            </InputLabel>
            <FormControl
              variant="outlined"
              className={
                mediawidth === "xl" || mediawidth === "lg"
                  ? classes.formControlchip
                  : classes.formControlchipsmall
              }
            >
              <Select
                multiple
                className="not-rounded"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={newvals}
                MenuProps={{
                  getContentAnchorEl: null,
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "center",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "center",
                  },
                }}
                renderValue={(selected) =>
                  countryarray.length > 0 ? (
                    <MuiTypography
                      fontSize="14px"
                      lineHeight="16.42px"
                      fontFamily="Work Sans"
                      letterSpacing="-0.02px"
                      fontWeight={400}
                      color="#142A39"
                    >
                      {`${countryarray
                        .map((x) => x.title)
                        .join()
                        .slice(0, 30)}...`}
                    </MuiTypography>
                  ) : (
                    <MuiTypography
                      fontSize="14px"
                      lineHeight="16.42px"
                      fontFamily="Work Sans"
                      letterSpacing="-0.02px"
                      fontWeight={400}
                      color="#142A39"
                    >
                      All
                    </MuiTypography>
                  )
                }
                input={
                  <OutlinedInput
                    labelWidth={0}
                    name="status"
                    id="outlined-status-simple"
                    classes={outlinedInputClasses}
                  />
                }
              >
                <StyledMenuItem onKeyDown={(e) => e.stopPropagation()}>
                  <Autocomplete
                    multiple
                    id="checkboxes-tags-demo"
                    options={countryarrayoptions}
                    disableCloseOnSelect
                    disableClearable
                    classes={{
                      inputRoot: classes.autocompleteinput,
                    }}
                    getOptionLabel={(option) => option.title}
                    getOptionSelected={(option, value) =>
                      value.code === option.code
                    }
                    onKeyDown={(e) => e.stopPropagation()}
                    renderTags={(tagValue, getTagProps) => {
                      return <></>;
                    }}
                    style={{ width: "240px" }}
                    value={countryarray}
                    onChange={(event, newValue, reason, details) => {
                      changecountryarray(newValue, details);
                    }}
                    renderOption={(option, { selected }) => (
                      <React.Fragment>
                        <GreenCheckbox
                          color="primary"
                          checked={selected}
                          style={{ marginRight: 8 }}
                        />
                        <MuiTypography
                          fontFamily="Work Sans"
                          fontSize="16px"
                          fonStyle="normal"
                          fonWeight="400"
                          lineHeight="20px"
                          color="#142A39"
                        >
                          {option.title}
                        </MuiTypography>
                      </React.Fragment>
                    )}
                    // style={{ padding: 10 }}
                    renderInput={(params) => (
                      <CssTextField
                        {...params}
                        label={props.label}
                        placeholder="Select country"
                        variant="outlined"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <InputAdornment position="end">
                              <SearchIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </StyledMenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item>
            <InputLabel
              ref={inputLabel1}
              className={classes.formControllabelstyle}
            >
              {t("Region")}
            </InputLabel>

            <FormControl
              variant="outlined"
              className={
                mediawidth === "xl" || mediawidth === "lg"
                  ? classes.formControlchip
                  : classes.formControlchipsmall
              }
            >
              <Select
                multiple
                className="not-rounded"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={newvals}
                MenuProps={{
                  getContentAnchorEl: null,
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "center",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "center",
                  },
                }}
                renderValue={(selected) =>
                  regionarray.length > 0 ? (
                    <MuiTypography
                      fontSize="14px"
                      lineHeight="16.42px"
                      fontFamily="Work Sans"
                      letterSpacing="-0.02px"
                      fontWeight={400}
                      color="#142A39"
                    >
                      {`${regionarray
                        .map((x) => x.title)
                        .join()
                        .slice(0, 30)}...`}
                    </MuiTypography>
                  ) : (
                    <MuiTypography
                      fontSize="14px"
                      lineHeight="16.42px"
                      fontFamily="Work Sans"
                      letterSpacing="-0.02px"
                      fontWeight={400}
                      color="#142A39"
                    >
                      All
                    </MuiTypography>
                  )
                }
                input={
                  <OutlinedInput
                    labelWidth={0}
                    name="status"
                    id="outlined-status-simple-region"
                    classes={outlinedInputClasses}
                  />
                }
              >
                <StyledMenuItem onKeyDown={(e) => e.stopPropagation()}>
                  <Autocomplete
                    multiple
                    id="checkboxes-tags-demo"
                    options={regionarrayoptions}
                    disableCloseOnSelect
                    disableClearable
                    classes={{
                      inputRoot: classes.autocompleteinput,
                    }}
                    getOptionLabel={(option) => option.title}
                    getOptionSelected={(option, value) =>
                      value.code === option.code
                    }
                    onKeyDown={(e) => e.stopPropagation()}
                    renderTags={(tagValue, getTagProps) => {
                      return <></>;
                    }}
                    style={{ width: "240px" }}
                    value={regionarray}
                    onChange={(event, newValue, reason, details) => {
                      changeregionarray(newValue, details);
                    }}
                    renderOption={(option, { selected }) => (
                      <React.Fragment>
                        <GreenCheckbox
                          color="primary"
                          checked={selected}
                          style={{ marginRight: 8 }}
                        />
                        <MuiTypography
                          fontFamily="Work Sans"
                          fontSize="16px"
                          fonStyle="normal"
                          fonWeight="400"
                          lineHeight="20px"
                          color="#142A39"
                        >
                          {option.title}
                        </MuiTypography>
                      </React.Fragment>
                    )}
                    // style={{ padding: 10 }}
                    renderInput={(params) => (
                      <CssTextField
                        {...params}
                        label={props.label}
                        placeholder="Select region"
                        variant="outlined"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <InputAdornment position="end">
                              <SearchIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </StyledMenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid
            item
            container
            justify="flex-end"
            alignItems="flex-end"
            direction="row"
            spacing={2}
          >
            <Grid item>
              <Button style={buttonstyle} onClick={handlepopoverresetclick}>
                <MuiTypography
                  fontSize="14px"
                  lineHeight="16.42px"
                  fontFamily="Work Sans"
                  letterSpacing="0.75px"
                  fontWeight={700}
                  color="#2D9F86"
                >
                  {t("CLEAR ALL")}
                </MuiTypography>
              </Button>
            </Grid>
            <Grid item>
              <div className="telekardia">
                <button
                  type="button"
                  className="btn m-1 btn_teal py-2 px-4"
                  onClick={handlepopoverapplyclick}
                >
                  {t("APPLY")}
                </button>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Popover>

      <TabPanel value={value} index={0}>
        <DashboardComponent />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <DashboardComponent />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <DashboardComponent />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <DashboardComponent />
      </TabPanel>
    </div>
  );
}
