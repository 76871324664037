import React from "react";
import { Dialog, IconButton, Grid, Button, Box, Chip } from "@material-ui/core";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import { withStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import EventIcon from '@material-ui/icons/Event';
import DeleteIcon from "@material-ui/icons/Delete";
import { makeStyles } from "@material-ui/core/styles";
import RepeatIcon from '@material-ui/icons/Repeat';
import MuiTypography from "../Shared/Typography/MuiTypography";
import moment from 'moment';
import { useTranslation } from "react-i18next";
import _get from "lodash.get";

const useStyles = makeStyles({
  root: {},
  buttonText: {
    marginLeft: 94,   
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "17px",
    display: "flex",
    letterSpacing: "0.75px",
    textTransform: "uppercase",
    color: " #142A39",
  },
  buttonTextEdit: {
    marginLeft: 80,   
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "17px",
    display: "flex",
    letterSpacing: "0.75px",
    textTransform: "uppercase",
    color: " #142A39",
  },
  button: {
    border: "1px solid #142A39",
    color: "#142A39",
    justifyContent: "flex-start"
  },
  dottedChip: {
    minWidth: "120px",
    border: "2px dashed rgba(20, 42, 57, 0.5) !important",
    color: "#142A39 !important",
    background: "rgba(20, 42, 57, 0.1) !important"
  },
  chipPadding:{
    paddingLeft: "10px"
  },
  reasonofvisit:  {
    maxWidth: 150
  }
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    outline: "none ",
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
    flexDirection: "column",
  },
}))(MuiDialogActions);
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    outline: "none",
  },
  smallButton: {
    position: "absolute",
    backgroundColor: "rgba(20, 42, 57, 0.15)",
    right: theme.spacing(3),
    top: theme.spacing(2),
    color: "black",
    padding: "10px",
    margin: "0px 0px 0px 10px",
    border: "none",
    cursor: "pointer",
    width: 40,
    height: 40,
    opacity: 0.9,
    borderRadius: "8px",
  },
});
const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, title,doctorName, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <>
      <MuiTypography
        fontSize="15px"
        fontFamily="Work Sans"
        letterSpacing="-0.2px"
        fontWeight={600}
        textTransform="uppercase"
        color="#885FA5"
      >
        {title}
      </MuiTypography>
      <MuiTypography
                fontSize="20px"
                fontFamily="Work Sans"
                lineHeight="28px"
                letterSpacing="0.15px"
                fontWeight={600}
                color="#885FA5"
                textTransform="camelcase"
              >
                {doctorName}
              </MuiTypography>
      </>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.smallButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});
export default function AppointmentPopup(props) {
  const { t } = useTranslation(); 
  const classes = useStyles();
  const Title = "CONSULTATION DETAILS ("+ (moment(props.data.scheduledStartTime).format("LT") +"-"+ moment(props.data.scheduledEndTime).format("LT"))+")";
  
  let cellClass = "";
  if (
    (_get(props.data, "appointmentStatusDisplayName") || "-") === "Completed"
  ) {
    cellClass += " status_green_completed";
  } else if (
    (_get(props.data, "appointmentStatusDisplayName") || "-")=== "Pending"
  ) {
    cellClass += " status_purple";
  } else if (
    (_get(props.data, "appointmentStatusDisplayName") || "-") === "Scheduled"
  ) {
    cellClass += " status_green_scheduled";
  } else if (
    (_get(props.data, "appointmentStatusDisplayName") || "-") === "Tech issues - patient" ||
    (_get(props.data, "appointmentStatusDisplayName") || "-") === "Tech issues - doctor" ||
    (_get(props.data, "appointmentStatusDisplayName") || "-") === "Doctor disconnected" ||
    (_get(props.data, "appointmentStatusDisplayName") || "-") === "Patient Missed" ||
    (_get(props.data, "appointmentStatusDisplayName") || "-") === "Doctor Missed"
  ) {
    cellClass += " status_orange_closed";
  } else if (
      (_get(props.data, "appointmentStatusDisplayName") || "-") === "Canceled" ||
      (_get(props.data, "appointmentStatusDisplayName") || "-") === "Missed" ||
      (_get(props.data, "appointmentStatusDisplayName") || "-") === "Declined" ||
      (_get(props.data, "appointmentStatusDisplayName") || "-") === "Patient Canceled" ||
      (_get(props.data, "appointmentStatusDisplayName") || "-") === "Doctor Canceled" ||
      (_get(props.data, "appointmentStatusDisplayName") || "-") === "System Canceled" ||
      (_get(props.data, "appointmentStatusDisplayName") || "-") === "Admin Canceled"
  ) {
    cellClass += " status_red_canceled";
  } else if (
    (_get(props.data, "appointmentStatusDisplayName") || "-") === "Report being generated"
   ) {
    cellClass += " status_gray_reportgenerated";
  } else if (
    (_get(props.data, "appointmentStatusDisplayName") || "-") === "In progress"
  ) {
    cellClass += " status_gray_inprogress";
  } else if (
    (_get(props.data, "appointmentStatusDisplayName") || "-") === "Doctor review started") {
    cellClass += " status_gray_doctorreviewstarted";
  } else {
    cellClass += " status_purple";
  }
  
  return (
    <Dialog
      fullWidth={true}
      maxWidth={"xs"}
      onClose={props.handleClose}
      aria-labelledby="simple-dialog-title"
      open={props.open}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
    >
      <DialogTitle
        id="simple-dialog-title"
        onClose={props.handleClose}
        title={Title} 
        doctorName={props.selectedDoctorName}
      />
      <DialogContent>
        
      {props.open && (
        <>
        {/* <Grid item container direction="row">
              <MuiTypography
                fontSize="20px"
                fontFamily="Work Sans"
                lineHeight="28px"
                letterSpacing="0.15px"
                fontWeight={600}
                color="#885FA5"
                textTransform="uppercase"
              >
                {props.selectedDoctorName}
              </MuiTypography>
            </Grid>
            <Box mt={2} /> */}
      <Grid item container direction="row">
            <Grid item>
              <MuiTypography
                fontSize="20px"
                fontFamily="Work Sans"
                lineHeight="28px"
                letterSpacing="0.15px"
                fontWeight={600}
                color="#142A39"
              >
                {(props.data.patient["firstName"]|| "-") +" " +(props.data.patient["lastName"]|| "-") }
              </MuiTypography>
            </Grid>
            
          </Grid>
          
         
      <Grid container direction="row" justify="flex-start" spacing={1}>
          <Grid item>
            <Grid container direction="column" justify="space-between">
              {/* <Grid item>
                <MuiTypography
                  fontSize="14px"
                  fontFamily="Work Sans"
                  lineHeight="24px"
                  letterSpacing="-0.2px"
                  fontWeight={700}
                  color="#142A39"
                >
                  {t("Age")}
                </MuiTypography>
              </Grid>
              <Grid item>
                <MuiTypography
                  fontSize="14px"
                  fontFamily="Work Sans"
                  lineHeight="24px"
                  letterSpacing="-0.2px"
                  fontWeight={700}
                  color="#142A39"
                >
                  {t("Gender")}
                </MuiTypography>
              </Grid> */}
              <Grid item>
                <MuiTypography
                  fontSize="14px"
                  fontFamily="Work Sans"
                  lineHeight="24px"
                  letterSpacing="-0.2px"
                  fontWeight={700}
                  color="#142A39"
                >
                  {t("Method of consultation")}
                </MuiTypography>
              </Grid>
              <Grid item>
                <MuiTypography
                  fontSize="14px"
                  fontFamily="Work Sans"
                  lineHeight="24px"
                  letterSpacing="-0.2px"
                  fontWeight={700}
                  color="#142A39"
                >
                  {t("Type of consultation")}
                </MuiTypography>
              </Grid>
             
              <Grid item>
                <MuiTypography
                  fontSize="14px"
                  fontFamily="Work Sans"
                  lineHeight="24px"
                  letterSpacing="-0.2px"
                  fontWeight={700}
                  color="#142A39"
                >
                    {t("Status")}
                </MuiTypography>
              </Grid>
              {/* <Grid item>
                <MuiTypography
                  fontSize="14px"
                  fontFamily="Work Sans"
                  lineHeight="24px"
                  letterSpacing="-0.2px"
                  fontWeight={700}
                  color="#142A39"
                >
                  {t("Reason for consultation")}
                </MuiTypography>
              </Grid> */}
            </Grid>
          </Grid>
          <Grid item>
            <Grid container direction="column" justify="space-between">
             
              {/* <Grid item>
                <MuiTypography
                  fontSize="14px"
                  fontFamily="Work Sans"
                  lineHeight="24px"
                  letterSpacing="-0.2px"
                  fontWeight={700}
                  color="#142A39"
                >
                  :
                </MuiTypography>
              </Grid>
              <Grid item>
                <MuiTypography
                  fontSize="14px"
                  fontFamily="Work Sans"
                  lineHeight="24px"
                  letterSpacing="-0.2px"
                  fontWeight={700}
                  color="#142A39"
                >
                  :
                </MuiTypography>
              </Grid> */}
              <Grid item>
                <MuiTypography
                  fontSize="14px"
                  fontFamily="Work Sans"
                  lineHeight="24px"
                  letterSpacing="-0.2px"
                  fontWeight={700}
                  color="#142A39"
                >
                  :
                </MuiTypography>
              </Grid>
              <Grid item>
                <MuiTypography
                  fontSize="14px"
                  fontFamily="Work Sans"
                  lineHeight="24px"
                  letterSpacing="-0.2px"
                  fontWeight={700}
                  color="#142A39"
                >
                  :
                </MuiTypography>
              </Grid>
              <Grid item>
                <MuiTypography
                  fontSize="14px"
                  fontFamily="Work Sans"
                  lineHeight="24px"
                  letterSpacing="-0.2px"
                  fontWeight={700}
                  color="#142A39"
                >
                  :
                </MuiTypography>
              </Grid>
              {/* <Grid item>
                <MuiTypography
                  fontSize="14px"
                  fontFamily="Work Sans"
                  lineHeight="24px"
                  letterSpacing="-0.2px"
                  fontWeight={700}
                  color="#142A39"
                >
                  :
                </MuiTypography>
              </Grid> */}
            </Grid>
          </Grid>
          <Grid item>
            <Grid container direction="column" justify="space-between">
              {/* <Grid item>
              <MuiTypography
                  fontSize="14px"
                  fontFamily="Work Sans"
                  lineHeight="24px"
                  letterSpacing="-0.2px"
                  color="#142A39"
                >
                  {props.data.patient.dob
                    ? new Date().getFullYear() -
                      new Date(props.data.patient.dob).getFullYear() +
                      " years"
                    : "-"}
                </MuiTypography>
              </Grid>
              <Grid item>
              <MuiTypography
                  fontSize="14px"
                  fontFamily="Work Sans"
                  lineHeight="24px"
                  letterSpacing="-0.2px"
                  color="#142A39"
                >
                  {props.data.patient.gender || "-"}
                </MuiTypography>
              </Grid> */}
              <Grid item>
              <MuiTypography
                  fontSize="14px"
                  fontFamily="Work Sans"
                  lineHeight="24px"
                  letterSpacing="-0.2px"
                  color="#142A39"
                >
                 {_get(
                      props.data,
                      "appointmentChannelType"
                    ) || "-"}
                </MuiTypography>
              </Grid>
              <Grid item>
              <MuiTypography
                  fontSize="14px"
                  fontFamily="Work Sans"
                  lineHeight="24px"
                  letterSpacing="-0.2px"
                  color="#142A39"
                >
                 {props.data.appointmentType || "-"}
                </MuiTypography>
              </Grid>
              
              <Grid item>
              <div className="telekardia">
                 <Chip size="small" className={cellClass} label={props.data.appointmentStatusDisplayName} />
                 </div>
                
              </Grid>
              {/* <Grid item    className={classes.reasonofvisit}>
              <MuiTypography
                  fontSize="14px"
                  fontFamily="Work Sans"
                  lineHeight="24px"
                  letterSpacing="-0.2px"
                  color="#142A39"
                >
                  {props.data.visitReason || "-"}
                </MuiTypography>
              </Grid> */}
            </Grid>
          </Grid>        
        </Grid>
      </>
      )}
      </DialogContent>
      <DialogActions disableSpacing={true}>
        <>
          <Button
            color="default"
            variant="outlined"
            size="large"
            fullWidth
            className={classes.button}
            startIcon={<RepeatIcon />}
            onClick={props.onReassign}
          >
            <span className={classes.buttonTextEdit}>{t("REASSIGN CONSULTATION")}</span>
          </Button>

          <Box mt={2} />
          <Button
            color="default"
            variant="outlined"
            size="large"
            fullWidth
            className={classes.button}
            startIcon={<DeleteIcon />}
            onClick={props.onCancel}
          >
            <span className={classes.buttonText}>{t("CANCEL CONSULTATION")}</span>
          </Button>

          <Box mt={2} />
          <Button
            variant="outlined"
            size="large"
            fullWidth
            className={classes.button}
            startIcon={<EventIcon />}
            onClick={props.onReschedule}
          >
            <span className={classes.buttonTextEdit}>{t("RESCHEDULE CONSULTATION")}</span>
          </Button>
        </>
      </DialogActions>
    </Dialog>
  );
}
