/* eslint-disable*/
import React,{useEffect} from "react";
import Flex from '../../Shared/Flex/Flex'
import FlexColumn from '../../Shared/Flex/FlexColumn'
import {
  Dialog,
  IconButton,
  Grid,
} from "@material-ui/core";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import { withStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import MuiTypography from "../../Shared/Typography/MuiTypography";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { useTranslation } from "react-i18next";


const GreenRadio = withStyles({
  root: {
    color: "#DADADA",
    "&$checked": {
      color: "#2D9F86",
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);


const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    outline: "none ",
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
    fontFamily:"Work Sans"
  },
}))(MuiDialogActions);
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    outline: "none",
  },
  smallButton: {
    position: "absolute",
    backgroundColor: "rgba(20, 42, 57, 0.15)",
    right: theme.spacing(3),
    top: theme.spacing(2),
    color: "black",
    padding: "10px",
    margin: "0px 0px 0px 10px",
    border: "none",
    cursor: "pointer",
    width: 40,
    height: 40,
    opacity: 0.9,
    borderRadius: "8px",
  },
});
const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, title, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <MuiTypography
        fontSize="24px"
        fontFamily="Work Sans"
        fontWeight={600}
        lineHeight="28px"
        color="#142A39"
      >
        {title}
      </MuiTypography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.smallButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});
export default function AppointmentStatus(props) {
  const { t } = useTranslation();
  return (
    <Dialog
      fullWidth={true}
      maxWidth={"xs"}
      onClose={props.close}
      aria-labelledby="simple-dialog-title"
      open={props.open}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
    >
      
      <DialogTitle
        id="simple-dialog-title"
        onClose={props.close}
        title=""
      />
      <DialogContent>
          <Flex direction="row">
            <FlexColumn>
            <MuiTypography 
            fontFamily= 'Work Sans'
            fontSize= '24px'
            fonStyle= 'normal'
            fonWeight= '500'
            lineHeight= '28.15px'
            textAlign= 'left'
            color="#000000"
            >
               {t("Consultation Status")}
              </MuiTypography>   
              <div style={{ margin: "10px" }}>
                <RadioGroup
                  aria-label="quiz"
                  name="quiz"
                  value={props.seriespattern}
                  onChange={props.handleRadioChange}
                >
                  <Flex direction="row">
                    <FormControlLabel
                      value="Completed"
                      control={
                        <GreenRadio

                        />
                      }
                      label={<MuiTypography 
                        fontFamily= 'Work Sans'
                        fontSize= '16px'
                        fonStyle= 'normal'
                        fonWeight= '400'
                        lineHeight= '20px'
                        color="#142A39"
                      >
                          {t("Completed")}
                      </MuiTypography>}
                    />
                    <FormControlLabel
                      value="Not Completed"
                      control={<GreenRadio />}
                      label={<MuiTypography 
                        fontFamily= 'Work Sans'
                        fontSize= '16px'
                        fonStyle= 'normal'
                        fonWeight= '400'
                        lineHeight= '20px'
                        color="#142A39"
                      >
                         {t("Not Completed")}
                      </MuiTypography>}
                    />
                  </Flex>
                </RadioGroup>
              </div>
            </FlexColumn>
          </Flex>
          {props.seriespattern === "Not Completed" &&
            <Flex direction="row">
              <FlexColumn>
              <MuiTypography 
                  fontFamily= 'Work Sans'
                  fontSize= '20px'
                  fonStyle= 'normal'
                  fonWeight= '500'
                  lineHeight= '28.15px'
                  textAlign= 'left'
                  color="#000000"
                  >
                      {t("Reason")}
              </MuiTypography>  
                <div style={{ margin: "10px" }}>
                  <RadioGroup
                    aria-label="quiz"
                    name="quiz"
                    value={props.Reason}
                    onChange={props.handleReasonChange}
                  >
                    <FlexColumn>
                      <Flex direction="row">
                        <FormControlLabel
                          value="Patient did not turn up"
                          control={<GreenRadio />}
                          label={<MuiTypography 
                            fontFamily= 'Work Sans'
                            fontSize= '16px'
                            fonStyle= 'normal'
                            fonWeight= '400'
                            lineHeight= '20px'
                            color="#142A39"
                          >
                             {t("Patient did not turn up")}
                          </MuiTypography>}
                        />

                      </Flex>
                      <Flex direction="row">
                        <FormControlLabel
                          value="Patient declined consultation"
                          control={<GreenRadio />}
                          label={<MuiTypography 
                            fontFamily= 'Work Sans'
                            fontSize= '16px'
                            fonStyle= 'normal'
                            fonWeight= '400'
                            lineHeight= '20px'
                            color="#142A39"
                          >
                             {t("Patient declined consultation")}
                          </MuiTypography>}
                        />

                      </Flex>
                      <Flex direction="row">
                        <FormControlLabel
                          value="Technical issue at my end"
                          control={<GreenRadio />}
                          label={<MuiTypography 
                            fontFamily= 'Work Sans'
                            fontSize= '16px'
                            fonStyle= 'normal'
                            fonWeight= '400'
                            lineHeight= '20px'
                            color="#142A39"
                          >
                             {t("Technical issue at my end")}
                          </MuiTypography>}
                        />

                      </Flex>
                      <Flex direction="row">
                        <FormControlLabel
                          value="Technical issue at patient's end"
                          control={<GreenRadio />}
                          label={<MuiTypography 
                            fontFamily= 'Work Sans'
                            fontSize= '16px'
                            fonStyle= 'normal'
                            fonWeight= '400'
                            lineHeight= '20px'
                            color="#142A39"
                          >
                             {t("Technical issue at patient's end")}
                          </MuiTypography>}
                        />

                      </Flex>
                    </FlexColumn>

                  </RadioGroup>
                </div>
              </FlexColumn>
            </Flex>
          }
       </DialogContent>
      <DialogActions>
      <Grid container direction="row" justify="flex-end" spacing={2}>
                    <Grid item>
                    <div className="telekardia">
              <button type="button" className="btn px-4 text-uppercase h40 ml-3 swal_green" onClick={props.onConfirm}>
              {t("CONFIRM")}
                    </button>
                    </div>
            </Grid>
            </Grid>
      </DialogActions>
    </Dialog>
  );
}
