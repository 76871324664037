import moment from 'moment'
import { SIDEBAR_WIDTH, CONTENT_WIDTH as CONTENT_WIDTH_OUTER } from './../common/constants'
// import { contentWidth, sidebarWidth } from '../styles/variables/global.css'

export const LOCAL_STORAGE_KARDIA_PRO_NAMESPACE = 'kardiapro_'
export const LOCAL_STORAGE_AUTH_TOKEN_KEY = `${LOCAL_STORAGE_KARDIA_PRO_NAMESPACE}id_token`
export const LOCAL_STORAGE_IDLE_TIME_KEY = `${LOCAL_STORAGE_KARDIA_PRO_NAMESPACE}idle_time_reset_at`
export const KARDIA_PRO_REGION = `${LOCAL_STORAGE_KARDIA_PRO_NAMESPACE}region`
export const LOCAL_STORAGE_AUTH_TIMEOUT_MESSAGE_KEY = 'auth_timeout_error_message'

export const setAuthTimeoutError = message => {
  localStorage.setItem(LOCAL_STORAGE_AUTH_TIMEOUT_MESSAGE_KEY, message)
}
export const getAuthTimeoutError = () => localStorage.getItem(LOCAL_STORAGE_AUTH_TIMEOUT_MESSAGE_KEY)
export const clearAuthTimeoutError = () => localStorage.removeItem(LOCAL_STORAGE_AUTH_TIMEOUT_MESSAGE_KEY)

export const getRegion = () => {
  if (process.env.REACT_APP_ENABLE_API_REGION === '0') return ''
  let region = localStorage.getItem(KARDIA_PRO_REGION) || ''
  if (region.toLowerCase() !== 'us' && region.toLowerCase() !== 'eu') {
    region = 'us'
    setRegion(region)
  }
  return region
}
export const setRegion = region => localStorage.setItem(KARDIA_PRO_REGION, region)
export const clearRegion = () => localStorage.removeItem(KARDIA_PRO_REGION)

// due to circular dependency this action type needs to be defined
// outside of the circular dependency in order to not be undefined
// for reducers
export const CLEAR_STATE = 'CLEAR_STATE'

export const ACTIVE_USER_UPDATE_TOKEN_THRESHOLD_MS = 1000 * 60 * 10 // 10 mins
export const IDLE_WARNING_THRESHOLD_MS = 1000 * 60 * 1 // 1 min
export const FORCE_BUST_CACHE_AFTER_MS = 1000 * 60 * 10 // 10 mins

export const appRoot = document.getElementById('app-root')
export const modalRoot = document.getElementById('modal-root')

export const KARDIA_PRO_START_DATE = new Date('January 1, 2015 00:00:00') // arbitrary date before kardia pro was born
export const KARDIA_PRO_END_DATE = moment().endOf('day').toDate()
export const KARDIA_PRO_DOB_START_DATE = moment('0001-01-01').toDate()
export const KARDIA_PRO_DOB_END_DATE = moment().endOf('day').toDate()

// https://momentjs.com/docs/#/parsing/string-format/
export const ISO_8601_FORMATS = {
  DATE: 'YYYY-MM-DD',
  TIME: 'HH:mm',
}
//
export const US_FORMATS = {
  DATE: 'MM-DD-YYYY',
  TIME: 'hh:mm A',
}

// https://tools.ietf.org/html/rfc3339#section-5.8
export const DATETIME_API_FMT = 'YYYY-MM-DDTHH:mm:ssZ'
export const DATE_OMIT_DAY_FMT = 'MMMM YYYY' // Not iso 8601
export const getDateOfBirthFormat = () => getDateFormat()
export const getDateFormat = () => (getRegion() === 'us' ? US_FORMATS.DATE : ISO_8601_FORMATS.DATE)
export const getTimeFormat = () => (getRegion() === 'us' ? US_FORMATS.TIME : ISO_8601_FORMATS.TIME)

export const parseDateOfBirth = (date = '--') => {
  const parts = date.split('-')

  return (getRegion() === 'us'
    ? {
      month: parts[0],
      day: parts[1],
      year: parts[2],
    }
    : {
      year: parts[0],
      month: parts[1],
      day: parts[2],
    }
  )
}

export const parseDobFormDisplay = dob => {
  if (dob === null || typeof dob === 'undefined') {
    return null
  }

  if (dob === '') {
    return ''
  }

  const splitDob = dob ? dob.split('-') : [ '', '', '' ]
  return (getRegion() === 'us'
    ? [ splitDob[1], splitDob[2], splitDob[0] ].join('-')
    : [ splitDob[0], splitDob[1], splitDob[2] ].join('-')
  )
}

export const MAX_BPM_VALUE = 300
export const MIN_BPM_VALUE = 0
export const MAX_SYSTOLIC_VALUE = 300
export const MIN_SYSTOLIC_VALUE = 0
export const MAX_DIASTOLIC_VALUE = 300
export const MIN_DIASTOLIC_VALUE = 0

export const MAX_BMI_VALUE = 200
export const MIN_BMI_VALUE = 0
export const MAX_WEIGHT_VALUE = 500
export const MIN_WEIGHT_VALUE = 0


export const TABLE_CHECKBOX_WIDTH = 54

export const INITIAL_CUSTOM_REPORT_RANGE_DAYS = 30

// Used in containers/Auth
export const AUTHORIZATION_TYPES = {
  KARDIA_ADMIN: 'KARDIA_ADMIN',
  ADMIN: 'ADMIN',
  PHYSICIAN: 'PHYSICIAN',
  TECHNICIAN: 'TECHNICIAN',
  PATIENT: 'PATIENT',
  EPIC: 'EPIC',
  EVERYONE: 'EVERYONE',
}

// Used in containers/Auth & RouteManager
export const ROUTES = {
  ROOT: '/',
  LOGIN_SSO: '/sso',
  LOGIN: '/login',
  REQUEST_NEW_PASSWORD: '/request-new-password',
  RESET_PASSWORD: '/reset-password',
  RESET_PASSWORD_OLD: '/reset_password',
  TEAM_MEMBER_INVITE: '/invite',
  BAA: '/baa',

  PATIENTS: '/patients',
  PATIENTS_SEARCH_HOME: '/patients/search',
  PATIENTS_DIRECTORY: '/patients/directory',
  PATIENTS_ADD_NEW_PATIENT: '/patients/add-new-patient',
  PATIENTS_PATIENT_FILE: '/patients/patient-file',
  // patient file recording ekg
  // patient file report
  DASHBOARD: '/dashboard',
  EKG_REVIEW: '/ekg-review',
  QT_EKG_REVIEW: '/qt-ekg-review',
  EKG_REVIEW_EKG_RECORDING: '/ekg-review/recordings/ekg',
  REPORTS: '/reports',
  TELEKARDIA_DASHBOARD: '/telekardia/dashboard',
  TELEKARDIA_APPOINTMENTS: '/telekardia/appointments',
  TELEKARDIA_APPOINTMENTDETAILS: '/telekardia/appointmentdetails',
  // reports single report
  REPORTS_REPORT: '/reports',

  EXPORTS: '/exports',
  SETTINGS: '/settings',
}

// Used in containers/Auth
export const PUBLIC_ROUTES = [
  ROUTES.ROOT,
  ROUTES.LOGIN_SSO,
  ROUTES.LOGIN,
  ROUTES.REQUEST_NEW_PASSWORD,
  ROUTES.RESET_PASSWORD,
  ROUTES.TEAM_MEMBER_INVITE,
  ROUTES.BAA
]

export const SSO_ROUTES = {
  PATIENT: 'patient',
}

export const AUTH_TYPES = {
  BASIC: 'BASIC',
  NONE: 'NONE',
  BEARER: 'BEARER',
}

export const DATASOURCES = {
  ME: 'me',
  PRESCRIPTIONS: 'prescriptions',
  INTERPRETATIONS: 'interpretations',
  PHYSICIANS: 'physicians',
  PHYSICIAN: 'physician',
  PHYSICIAN_REMINDERS: 'physicianReminders',
  EKG_STACK_COUNTS: 'ekgStackCounts',
  PATIENTS: 'patients',
  PATIENT: 'patient',
  OVERVIEW: 'overview',
  ACTIVITY: 'activity',
  TEAM_TRIGGERS: 'teamTriggers',
  TEAM_INVITES: 'teamInvites',
  PATIENT_TRIGGERS: 'patientTriggers',
  EKGS: 'ekgs',
  EKG: 'ekg',
  EKG_SAMPLES: 'ekgSamples',
  EKG_RELATED: 'ekgRelated',
  BLOOD_PRESSURES: 'bloodPressures',
  WEIGHTS: 'weights',
  EKG_STACKS: 'ekgStacks',
  ENROLLMENTS: 'enrollments',
  BILLING_CODES: 'billingCodes',
  ICD10_CODES: 'icd10Codes',
  REPORTS: 'reports',
  SINGLE_EKG_PDF: 'singleEkgPdf',
  SINGLE_REPORT_PDF: 'singleReportPdf',
  SINGLE_REPORT: 'singleReport',
  EXPORTS: 'exports',
  CUSTOM_REPORT_PDF: 'customReportPdf',
}

export const SETTINGS_PAGE_TABS = {
  UPDATE_PASSWORD: 'UPDATE_PASSWORD',
  INVITE_TEAM_MEMBER: 'INVITE_TEAM_MEMBER',
  TEAM_MEMBERS: 'TEAM_MEMBERS',
  INBOX_SETTINGS: 'INBOX_SETTINGS',
}
export const PATIENT_FILE_TABS = {
  OVERVIEW: 'OVERVIEW',
  PROFILE: 'PROFILE',
  REPORTS: 'REPORTS',
  EKGS: 'EKGS',
  BLOOD_PRESSURES: 'BLOOD_PRESSURES',
  WEIGHTS: 'WEIGHTS',
}

export const FORMS = {
  ADD_NEW_PATIENT_FORM: 'newPatientForm',
  EDIT_PATIENT_FORM: 'editPatientForm',
  PRESCRIBE_FORM: 'prescribeForm',
  ENROLLMENT_FORM: 'enrollmentForm',
  ADD_NEW_MEMBER_FORM: 'addNewMemberForm',
  EDIT_MEMBER_FORM: 'editMemberForm',
  INBOX_SETTINGS_FORM: 'inboxSettingsForm',
  UPDATE_PASSWORD_FORM: 'updatePasswordForm',
}
export const FORM_SEX_OPTIONS = [ { label: 'Male', value: 'male' }, { label: 'Female', value: 'female' } ]

export const SORT_TYPES = {
  ASC: 'ASC',
  DESC: 'DESC',
}

export const ENROLLMENT_CONNECTION_STATUSES = {
  CONNECTED: 'connected',
  PENDING: 'pending',
  REVOKED: 'revoked',
  DISCONNECTED: 'disconnected',
  EXPIRED: 'expired',
  NONE: 'none',
}

export const getEnrollmentConnectionStatusesOrder = () => [
  ENROLLMENT_CONNECTION_STATUSES.CONNECTED,
  ENROLLMENT_CONNECTION_STATUSES.PENDING,
  ENROLLMENT_CONNECTION_STATUSES.REVOKED,
  ENROLLMENT_CONNECTION_STATUSES.DISCONNECTED,
  ENROLLMENT_CONNECTION_STATUSES.EXPIRED,
  ENROLLMENT_CONNECTION_STATUSES.NONE,
]

export const enrollmentConnectionStatusesOrderLookupTable = ({
  [ENROLLMENT_CONNECTION_STATUSES.CONNECTED]: 0,
  [ENROLLMENT_CONNECTION_STATUSES.PENDING]: 1,
  [ENROLLMENT_CONNECTION_STATUSES.REVOKED]: 2,
  [ENROLLMENT_CONNECTION_STATUSES.DISCONNECTED]: 3,
  [ENROLLMENT_CONNECTION_STATUSES.EXPIRED]: 4,
  [ENROLLMENT_CONNECTION_STATUSES.NONE]: 5,
})

export const getEnrollmentConnectionStatusesDisplayText = status => {
  let displayText = ''

  switch (status) {
  case ENROLLMENT_CONNECTION_STATUSES.CONNECTED:
    displayText = 'Connected'
    break
  case ENROLLMENT_CONNECTION_STATUSES.PENDING:
    displayText = 'Pending'
    break
  case ENROLLMENT_CONNECTION_STATUSES.REVOKED:
    displayText = 'Revoked'
    break
  case ENROLLMENT_CONNECTION_STATUSES.DISCONNECTED:
    displayText = 'Disconnected'
    break
  case ENROLLMENT_CONNECTION_STATUSES.EXPIRED:
    displayText = 'Expired'
    break
  case ENROLLMENT_CONNECTION_STATUSES.NONE:
    displayText = 'Not Ordered'
    break
  default:
    break
  }

  return displayText
}

export const getEnrollmentConnectionStatusesOptions = () =>
  getEnrollmentConnectionStatusesOrder().map(status => ({
    id: status,
    label: getEnrollmentConnectionStatusesDisplayText(status),
  }))

export const getSimplifiedEnrollmentConnectionStatusesOrder = () => [
  ENROLLMENT_CONNECTION_STATUSES.CONNECTED,
  ENROLLMENT_CONNECTION_STATUSES.PENDING,
  ENROLLMENT_CONNECTION_STATUSES.NONE,
]

export const getSimplifiedEnrollmentConnectionStatusesDisplayText = status => {
  let displayText = ''

  switch (status) {
  case ENROLLMENT_CONNECTION_STATUSES.CONNECTED:
    displayText = 'Connected'
    break
  case ENROLLMENT_CONNECTION_STATUSES.PENDING:
    displayText = 'Pending'
    break
  case ENROLLMENT_CONNECTION_STATUSES.REVOKED:
  case ENROLLMENT_CONNECTION_STATUSES.DISCONNECTED:
  case ENROLLMENT_CONNECTION_STATUSES.EXPIRED:
  case ENROLLMENT_CONNECTION_STATUSES.NONE:
    displayText = 'Not Ordered'
    break
  default:
    break
  }

  return displayText
}

export const getSimplifiedEnrollmentConnectionStatusesOptions = () =>
  getSimplifiedEnrollmentConnectionStatusesOrder().map(status => ({
    id: status,
    label: getSimplifiedEnrollmentConnectionStatusesDisplayText(status),
  }))

// export const NETWORK_RENEW_AUTH_THRESHOLD_MS = 1000 * 60 * 15 // 15 mins

export const LOGIN_ERROR_MESSAGES = {
  BAD_CREDENTIALS: 'Bad email or password, try again',
  SESSION_TIMEOUT: 'Your session has timed out, please sign in to continue',
  ROUTE_PERMISSION_DENIED: 'Your account does not have permission to view the page you are trying to access',
  LOCKED_OUT: 'Account locked due to too many failed login attempts. Please try again later.',
  LOCKED_WITH_TIME: 'Account has been locked until %datetime due to too many failed login attempts.',
}

export const BLOOD_PRESSURE_SOURCES = {
  OMRON: 'omron',
  MANUAL: 'manual',

  // Old source field options => manual
  KARDIA_ANDROID_MANUAL_ENTRY: 'kardia android manual entry',
  KARDIA_MOBILE: 'kardia mobile',
}

export const getBloodPressureSourcesOrder = () => [
  BLOOD_PRESSURE_SOURCES.OMRON,
  BLOOD_PRESSURE_SOURCES.MANUAL,
]

export const getBloodPressureSourceDisplayText = bloodPressureSource => {
  let displayText = ''
  switch (bloodPressureSource.toLowerCase()) {
  case BLOOD_PRESSURE_SOURCES.OMRON:
    displayText = 'Connected Device'
    break
    /* Old source field options => manual */
  case BLOOD_PRESSURE_SOURCES.KARDIA_ANDROID_MANUAL_ENTRY:
  case BLOOD_PRESSURE_SOURCES.KARDIA_MOBILE:
  case BLOOD_PRESSURE_SOURCES.MANUAL:
    displayText = 'Manual Entry'
    break
  default:
    break
  }
  return displayText
}

export const getBloodPressureSourceOptions = () =>
  getBloodPressureSourcesOrder().map(id => ({
    id,
    label: getBloodPressureSourceDisplayText(id),
  }))

export const BLOOD_PRESSURE_SEVERITY_TYPES = {
  ALL_BLOOD_PRESSURES: null,
  NORMAL: 'NORMAL',
  PREHYPERTENSION: 'PREHYPERTENSION',
  HYPERTENSION_1: 'HYPERTENSION_1',
  HYPERTENSION_2: 'HYPERTENSION_2',
  HYPERTENSIVE_CRISIS: 'HYPERTENSIVE_CRISIS',
}


export const BLOOD_PRESSURE_SEVERITY_CLASSNAMES = {
  NORMAL: 'bpNormal',
  PREHYPERTENSION: 'bpPrehypertension',
  HYPERTENSION_1: 'bpHypertension1',
  HYPERTENSION_2: 'bpHypertension2',
  HYPERTENSIVE_CRISIS: 'bpHypertensiveCrisis',
}

export const getBloodPressureSeverityOrder = () => [
  BLOOD_PRESSURE_SEVERITY_TYPES.ALL_BLOOD_PRESSURES,
  BLOOD_PRESSURE_SEVERITY_TYPES.NORMAL,
  BLOOD_PRESSURE_SEVERITY_TYPES.PREHYPERTENSION,
  BLOOD_PRESSURE_SEVERITY_TYPES.HYPERTENSION_1,
  BLOOD_PRESSURE_SEVERITY_TYPES.HYPERTENSION_2,
  BLOOD_PRESSURE_SEVERITY_TYPES.HYPERTENSIVE_CRISIS,
]
export const classifyBloodPressureSeverity = (systolic, diastolic) => {
  if (systolic >= 180 || diastolic >= 120) {
    return BLOOD_PRESSURE_SEVERITY_TYPES.HYPERTENSIVE_CRISIS
  } else if (systolic >= 140 || diastolic >= 90) {
    return BLOOD_PRESSURE_SEVERITY_TYPES.HYPERTENSION_2
  } else if (systolic >= 130 || diastolic >= 80) {
    return BLOOD_PRESSURE_SEVERITY_TYPES.HYPERTENSION_1
  } else if (systolic >= 120 && diastolic < 80) {
    return BLOOD_PRESSURE_SEVERITY_TYPES.PREHYPERTENSION
  } else {
    return BLOOD_PRESSURE_SEVERITY_TYPES.NORMAL
  }
}

export const getBloodPressureCounts = (bloodPressureRecordings = []) => {
  const lookupTable = getBloodPressureSeverityOrder().slice(1).reduce((acc, severityType) => {
    acc[severityType] = 0
    return acc
  }, {})

  bloodPressureRecordings.forEach(rec => {
    lookupTable[rec.severity] += 1
  })

  return lookupTable
}

export const getBPSeverityClassName = bpSeverity => {
  let colorClassName = ''

  switch (bpSeverity) {
  case BLOOD_PRESSURE_SEVERITY_TYPES.NORMAL:
    colorClassName = BLOOD_PRESSURE_SEVERITY_CLASSNAMES.NORMAL
    break
  case BLOOD_PRESSURE_SEVERITY_TYPES.PREHYPERTENSION:
    colorClassName = BLOOD_PRESSURE_SEVERITY_CLASSNAMES.PREHYPERTENSION
    break
  case BLOOD_PRESSURE_SEVERITY_TYPES.HYPERTENSION_1:
    colorClassName = BLOOD_PRESSURE_SEVERITY_CLASSNAMES.HYPERTENSION_1
    break
  case BLOOD_PRESSURE_SEVERITY_TYPES.HYPERTENSION_2:
    colorClassName = BLOOD_PRESSURE_SEVERITY_CLASSNAMES.HYPERTENSION_2
    break
  case BLOOD_PRESSURE_SEVERITY_TYPES.HYPERTENSIVE_CRISIS:
    colorClassName = BLOOD_PRESSURE_SEVERITY_CLASSNAMES.HYPERTENSIVE_CRISIS
    break
  default:
    break
  }

  return colorClassName
}

export const getBloodPressureSeverityDisplayText = bloodPressureSeverityType => {
  let displayText = ''
  switch (bloodPressureSeverityType) {
  case BLOOD_PRESSURE_SEVERITY_TYPES.ALL_BLOOD_PRESSURES:
    displayText = 'All Blood Pressure Severities'
    break
  case BLOOD_PRESSURE_SEVERITY_TYPES.HYPERTENSIVE_CRISIS:
    displayText = 'Hypertensive Crisis'
    break
  case BLOOD_PRESSURE_SEVERITY_TYPES.HYPERTENSION_2:
    displayText = 'Hypertension 2'
    break
  case BLOOD_PRESSURE_SEVERITY_TYPES.HYPERTENSION_1:
    displayText = 'Hypertension 1'
    break
  case BLOOD_PRESSURE_SEVERITY_TYPES.PREHYPERTENSION:
    displayText = 'Elevated' // 'Prehypertension'
    break
  case BLOOD_PRESSURE_SEVERITY_TYPES.NORMAL:
    displayText = 'Normal'
    break
  default:
    break
  }
  return displayText
}

export const getTriggerDisplayText = triggerType => {
  let displayText = ''

  switch (triggerType) {
  case TRIGGER_TYPES.ALL_ABNORMAL_RECORDINGS:
    displayText = 'All non-normal recordings'
    break
  case TRIGGER_TYPES.ALL_RECORDINGS:
    displayText = 'All recordings'
    break
  case TRIGGER_TYPES.NO_RECORDINGS:
    displayText = 'No recordings'
    break

  case TRIGGER_TYPES.ALL_UNCLASSIFIED:
    displayText = 'All unclassified recordings'
    break
  case TRIGGER_TYPES.DAYS_SINCE_RECORDING_OVER_X:
    displayText = 'Days since recording over X value'
    break
  case TRIGGER_TYPES.HEART_RATE_OVER_X:
    displayText = 'Heart rate over X value'
    break
  case TRIGGER_TYPES.HEART_RATE_OVER_X_AND_AFIB:
    displayText = 'Heart rate over X value and possible afib'
    break
  case TRIGGER_TYPES.HEART_RATE_UNDER_X:
    displayText = 'Heart rate under X value'
    break
  case TRIGGER_TYPES.POSSIBLE_AFIB_DETECTION:
    displayText = 'Possible afib'
    break
  default:
    break
  }

  return displayText
}

export const TRIGGER_TYPES = {
  // CP 2019-1-18, do not change the key to 'ALL_NON_NORMAL_RECORDINGS' as 'ALL_ABNORMAL_RECORDINGS' is a key used by the backend
  ALL_ABNORMAL_RECORDINGS: 'ALL_ABNORMAL_RECORDINGS',
  ALL_RECORDINGS: 'ALL_RECORDINGS',
  ALL_UNCLASSIFIED: 'ALL_UNCLASSIFIED',
  DAYS_SINCE_RECORDING_OVER_X: 'DAYS_SINCE_RECORDING_OVER_X',
  HEART_RATE_OVER_X: 'HEART_RATE_OVER_X',
  HEART_RATE_OVER_X_AND_AFIB: 'HEART_RATE_OVER_X_AND_AFIB',
  HEART_RATE_UNDER_X: 'HEART_RATE_UNDER_X',
  NO_RECORDINGS: 'NO_RECORDINGS',
  POSSIBLE_AFIB_DETECTION: 'POSSIBLE_AFIB_DETECTION',
}
export const PRIMARY_TRIGGER_TYPES = {
  ALL_RECORDINGS: TRIGGER_TYPES.ALL_RECORDINGS,
  ALL_ABNORMAL_RECORDINGS: TRIGGER_TYPES.ALL_ABNORMAL_RECORDINGS,
  NO_RECORDINGS: TRIGGER_TYPES.NO_RECORDINGS,
}

export const TRIGGER_FREQUENCY_TYPES = {
  ALWAYS: 'always',
  NEVER: 'never',
}

export const CUSTOM_TRIGGER_OPTION = {
  id: 'custom',
  label: 'Custom',
}
export const CUSTOM_TRIGGER_DEFAULT = {
  possibleAFibResultWithHr: 60,
  heartRateUnder: 40,
  heartRateOver: 120,
}
export const ALL_RECORDINGS_TRIGGER_OPTION = {
  id: TRIGGER_TYPES.ALL_RECORDINGS,
  label: getTriggerDisplayText(TRIGGER_TYPES.ALL_RECORDINGS),
}

export const getPrimaryTriggerOption = (triggerType, triggerData) => ({
  label: getTriggerDisplayText(PRIMARY_TRIGGER_TYPES[triggerType]),
  ...triggerData,
})

export const getPrimaryTriggerOptions = triggers => {
  const options = primaryTriggerTypesOrder().reduce((acc, type) => {
    const triggerId = triggers.typeToIdLookup[type]
    const triggerData = triggers.byId[triggerId]
    acc.push(getPrimaryTriggerOption(type, triggerData))

    return acc
  }, [])

  options.push(CUSTOM_TRIGGER_OPTION)

  return options
}

export const getBloodPressureSeverityOptions = () =>
  getBloodPressureSeverityOrder().map(id => ({
    id,
    label: getBloodPressureSeverityDisplayText(id),
  }))

export const primaryTriggerTypesOrder = () => [
  PRIMARY_TRIGGER_TYPES.ALL_RECORDINGS,
  PRIMARY_TRIGGER_TYPES.ALL_ABNORMAL_RECORDINGS,
  PRIMARY_TRIGGER_TYPES.NO_RECORDINGS,
]

export const getPermissionOptions = () => Object.keys(PERMISSION_TYPES).reduce((acc, type) => {
  acc.push({
    id: PERMISSION_TYPES[type],
    label: getPermissionOptionDisplayText(PERMISSION_TYPES[type]),
  })
  return acc
}, [])
export const getPermissionOptionDisplayText = permissionType => {
  let displayText = null
  switch (permissionType) {
  case PERMISSION_TYPES.ALL_PERMISSIONS:
    displayText = 'All Permissions Types'
    break
  case PERMISSION_TYPES.HAS_PERMISSION:
    displayText = 'Yes'
    break
  case PERMISSION_TYPES.DOES_NOT_HAVE_PERMISSION:
    displayText = 'No'
    break
  default:
    break
  }
  return displayText
}
export const getPermissionOptionDisplayTextFromBoolean = flag => (flag
  ? getPermissionOptionDisplayText(PERMISSION_TYPES.HAS_PERMISSION)
  : getPermissionOptionDisplayText(PERMISSION_TYPES.DOES_NOT_HAVE_PERMISSION))
export const getPermissionBooleanFromPermissionType = type => {
  let boolean

  switch (type) {
  case PERMISSION_TYPES.HAS_PERMISSION:
    boolean = true
    break
  case PERMISSION_TYPES.DOES_NOT_HAVE_PERMISSION:
    boolean = false
    break
  default:
    break
  }

  return boolean
}
export const PERMISSION_TYPES = {
  ALL_PERMISSIONS: null,
  HAS_PERMISSION: 'HAS_PERMISSION',
  DOES_NOT_HAVE_PERMISSION: 'DOES_NOT_HAVE_PERMISSION',
}

export const getEmailFrequencyOptions = () => Object.keys(EMAIL_FREQUENCY_TYPES).reduce((acc, type) => {
  acc.push({
    id: EMAIL_FREQUENCY_TYPES[type],
    label: getEmailFrequencyOptionDisplayText(EMAIL_FREQUENCY_TYPES[type]),
  })
  return acc
}, [])
export const getEmailFrequencyOptionDisplayText = emailFrequencyType => {
  let displayText = null
  switch (emailFrequencyType) {
  case EMAIL_FREQUENCY_TYPES.WEEKLY:
    displayText = 'Weekly'
    break
  case EMAIL_FREQUENCY_TYPES.DAILY:
    displayText = 'Daily'
    break
  case EMAIL_FREQUENCY_TYPES.NEVER:
    displayText = 'Never'
    break
  default:
    break
  }
  return displayText
}
export const EMAIL_FREQUENCY_TYPES = {
  WEEKLY: 'weekly',
  DAILY: 'daily',
  NEVER: 'never',
}

export const getExportOptions = () => Object.keys(EXPORT_TYPES).reduce((acc, type) => {
  acc.push({
    id: EXPORT_TYPES[type],
    label: getExportOptionDisplayText(EXPORT_TYPES[type]),
  })
  return acc
}, [])
export const getExportOptionDisplayText = exportType => {
  let displayText = null
  switch (exportType) {
  case EXPORT_TYPES.ALL_EXPORT:
    displayText = 'All Export Types'
    break
  case EXPORT_TYPES.EXPORTED:
    displayText = 'Yes'
    break
  case EXPORT_TYPES.NOT_EXPORTED:
    displayText = 'No'
    break
  default:
    break
  }
  return displayText
}
export const EXPORT_TYPES = {
  ALL_EXPORT: null,
  EXPORTED: 'EXPORTED',
  NOT_EXPORTED: 'NOT_EXPORTED',
}

export const getEsignOptions = () => Object.keys(ESIGN_TYPES).reduce((acc, type) => {
  acc.push({
    id: ESIGN_TYPES[type],
    label: getEsignOptionDisplayText(ESIGN_TYPES[type]),
  })
  return acc
}, [])
export const getEsignOptionDisplayText = readType => {
  let displayText = null
  switch (readType) {
  case ESIGN_TYPES.ALL_ESIGN:
    displayText = 'All E-sign Types'
    break
  case ESIGN_TYPES.ESIGNED:
    displayText = 'Yes'
    break
  case ESIGN_TYPES.NOT_ESIGNED:
    displayText = 'No'
    break
  default:
    break
  }
  return displayText
}
export const ESIGN_TYPES = {
  ALL_ESIGN: null,
  ESIGNED: 'ESIGNED',
  NOT_ESIGNED: 'NOT_ESIGNED',
}


export const getSexDisplayText = sex => {
  let displayText = ''

  switch (sex) {
  case SEX_TYPES.FEMALE:
    displayText = 'Female'
    break
  case SEX_TYPES.MALE:
    displayText = 'Male'
    break
  case SEX_TYPES.NOT_KNOWN:
  default:
    displayText = 'Not Known'
    break
  }

  return displayText
}

export const SEX_TYPES = {
  FEMALE: 'female',
  MALE: 'male',
  NOT_KNOWN: 'not_known',
}

export const getSexOrder = () => [
  SEX_TYPES.FEMALE,
  SEX_TYPES.MALE,
  SEX_TYPES.NOT_KNOWN,
]

export const getSexOptions = () => getSexOrder().map(sex => ({
  id: sex,
  label: getSexDisplayText(sex),
}))

export const NO_STACK = 'NO_STACK'
export const CONFIRMED = 'CONFIRMED'
export const TRIAGE = 'TRIAGE'

export const TRIAGE_OPTION = {
  id: TRIAGE,
  firstName: 'Practice Inbox',
}

export const CONFIRMED_OPTION = {
  id: CONFIRMED,
  firstName: 'Confirmed',
}

export const ALL_PHYSICIANS_OPTION = {
  id: null,
  firstName: 'All Providers',
}

export const ALL_PATIENTS_OPTION = {
  id: null,
  firstName: 'All Patients',
}

export const ALL_PRESCRIPTIONS_OPTION = {
  id: null,
  name: 'All Prescriptions',
}

export const UNASSIGNED_ORDERING_PHYSICIAN_OPTION = {
  id: null,
  firstName: 'Unassigned',
}

export const isEkgRecordingInAStack = recording => recording && (recording.stackID !== NO_STACK && recording.stackID !== CONFIRMED)
export const isEkgRecordingConfirmed = recording => recording && recording.stackID === CONFIRMED
export const ekgRecordingHasInterpretations = recording => recording && recording.memberInterpretations && recording.memberInterpretations.length > 0
export const getStackId = (teamId, memberId, confirmed) => {
  let stackId

  if (teamId && (!memberId || memberId === null) && (!confirmed || confirmed === false)) {
    // triage/unassigned
    stackId = TRIAGE
  } else if (teamId && (!memberId || memberId === null) && confirmed === true) {
    // confirmed
    stackId = CONFIRMED
  } else if (teamId && memberId && (!confirmed || confirmed === false)) {
    // member
    stackId = memberId
  }

  return stackId
}

export const EKG_REVIEW_PAGE_TABS = {
  TRIAGE: 'TRIAGE',
  CONFIRMED: 'CONFIRMED',
  PHYSICIAN: 'PHYSICIAN',
}

export const EKG_TABLE_ROW_HEIGHT = 74
export const EKG_TABLE_COLUMN_HEADERS = {
  RECORDED_AT: 'recordedAt',
  CONFIRMED_AT: 'confirmedAt',
  PATIENT: 'patient',
  BPM: 'bpm',
  ORDERING_PHYSICIAN: 'orderingPhysician',
  ANALYSIS: 'analysis',
  ANALYSIS_BY: 'analysisBy',
  ORDERING_PRACTICE: 'orderingPractice',
  QT_CONFIRMED_AT: 'qtConfirmedAt',
  MRN: 'customParticipantID',
}

export const BLOOD_PRESSURE_TABLE_COLUMN_HEADERS = {
  RECORDED_AT: 'recorded_at',
  PATIENT: 'patient',
  SOURCE: 'source',
  CATEGORY: 'severity',
  BPM: 'pulse',
  SYSTOLIC: 'systolic',
  DIASTOLIC: 'diastolic',
}

export const PATIENT_DIRECTORY_TABLE_COLUMN_HEADERS = {
  PATIENT: 'patient',
  DOB: 'dob',
  SEX: 'sex',
  ORDERING_PHYSICIAN: 'orderingPhysician',
  CONNECTION_STATUS: 'connectionStatus',
  PRESCRIPTION: 'prescription',
  BILLING_CODE: 'billingCode',
}

export const ENROLLMENT_TABLE_ROW_HEIGHT = 40
export const ENROLLMENT_TABLE_COLUMN_HEADERS = {
  START_DATE: 'startDate',
  END_DATE: 'endDate',
  PRESCRIPTION: 'prescription',
  ICD10_CODE: 'icd10Code',
  BILLING_CODE: 'billingCode',
  ORDER_NUMBER: 'orderNumber',
}

export const REPORTS_TABLE_COLUMN_HEADERS = {
  END_DATE: 'endAt',
  PATIENT: 'patient',
  PHYSICIAN: 'physician',
  PRESCRIPTION: 'prescription',
  // ICD10_CODE: 'icd10Code',
  BILLING_CODE: 'billingCode',
  // ORDER_NUMBER: 'orderNumber',
  ESIGNED: 'esigned',
  EXPORTED: 'exported',
}

export const TEAM_INVITES_TABLE_COLUMN_HEADERS = {
  EMAIL: 'email',
}

export const TEAM_MEMBERS_TABLE_COLUMN_HEADERS = {
  EMAIL: 'email',
  NAME: 'name',
  ADMIN_PERMISSION: 'adminPermission',
  PHYSICIAN_PERMISSION: 'physicianPermission',
}

export const WEIGHT_TABLE_COLUMN_HEADERS = {
  RECORDED_AT: 'recordedAt',
  PATIENT: 'patient',
  SOURCE: 'source',
  CATEGORY: 'severity',
  BMI: 'bmi',
  WEIGHT: 'weight',
}

export const TABLE_BULK_ACTIONS = {
  MARK_AS_EXPORT: 'MARK_AS_EXPORT',
  MARK_AS_UNEXPORT: 'MARK_AS_UNEXPORT',
  MARK_AS_ESIGN: 'MARK_AS_ESIGN',
  // MARK_AS_UNESIGN: 'MARK_AS_UNESIGN',
  REMOVE_FROM_STACK: 'REMOVE_FROM_STACK',
  // CONFIRM_AND_ARCHIVED: 'CONFIRM_AND_ARCHIVED',
  // REASSIGN_STACK: 'REASSIGN_STACK',
  REVOKE_FROM_TEAM: 'REMOVE_FROM_TEAM',
  DOWNLOAD_LIST: 'DOWNLOAD_LIST',
}

export const ECG_STACK_CONTROLS_ACTION_TYPE = {
  CANCEL: 'CANCEL',
  OK: 'OK',
}

// javascript counter part to global sidebarWidth & sidebarCollapsedWidth
export const CONTENT_VERTICAL_PADDING = 20
export const CONTENT_WIDTH = CONTENT_WIDTH_OUTER - (CONTENT_VERTICAL_PADDING * 2)
export const DASHBOARD_SIDEBAR_WIDTH = SIDEBAR_WIDTH
export const DASHBOARD_SIDEBAR_COLLAPSED_WIDTH = 80
export const PATIENTS_DIRECTORY_HEADER_AND_TOOLBAR_HEIGHT = 188
export const PATIENTS_DIRECTORY_HEADER_AND_TOOLBAR_WITH_FILTERS_HEIGHT = PATIENTS_DIRECTORY_HEADER_AND_TOOLBAR_HEIGHT + 80
// export const TABLE_HEADER_HEIGHT = 32 + 44 + 1
export const TABLE_HEADER_HEIGHT = 49 + 44 + 1
export const HEADER_HEIGHT = 124
export const BOTTOM_PADDING = 14
export const RIGHT_SIDEBAR_WIDTH = 362
// need to test on IE
export const SCROLLBAR_WIDTH = 18
export const TABLE_ACTIONS_HEIGHT = 60
export const TABLE_TOOLBAR_ONE_LEVEL = 80
export const TABLE_TOOLBAR_TWO_LEVEL = 150
// used for recording graphing
export const SCROLLBAR_HEIGHT = 8


// old
export const tileBottomMargin = 8

export const smallRecordingTileHeight = 106
export const bigRecordingTileHeight = 154
export const smallRecordingTileElementHeight = smallRecordingTileHeight + tileBottomMargin
export const bigRecordingTileElementHeight = bigRecordingTileHeight + tileBottomMargin
