import React from "react";
import { Grid, Divider } from "@material-ui/core";

const MuiDivider = ({ children, ...props }) => (
  <Grid container alignItems="center" spacing={3} {...props}>
    <Grid item xs>
      <Divider />
    </Grid>
    <Grid item>{children}</Grid>
    <Grid item xs>
      <Divider />
    </Grid>
  </Grid>
);

export default MuiDivider;
