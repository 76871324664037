export const USER_LOGOUT = "USER_LOGOUT";
export const GET_AVAILABILITY_START = 'GET_AVAILABILITY_START';
export const GET_AVAILABILITY_SUCCESS = 'GET_AVAILABILITY_SUCCESS';
export const GET_AVAILABILITY_FAIL = 'GET_AVAILABILITY_FAIL';

export const ADD_AVAILABILITY_START = 'ADD_AVAILABILITY_START';
export const ADD_AVAILABILITY_SUCCESS = 'ADD_AVAILABILITY_SUCCESS';
export const ADD_AVAILABILITY_FAIL = 'ADD_AVAILABILITY_FAIL';

export const UPDATE_AVAILABILITY_START = 'UPDATE_AVAILABILITY_START';
export const UPDATE_AVAILABILITY_SUCCESS = 'UPDATE_AVAILABILITY_SUCCESS';
export const UPDATE_AVAILABILITY_FAIL = 'UPDATE_AVAILABILITY_FAIL';

export const DELETE_AVAILABILITY_START = 'DELETE_AVAILABILITY_START';
export const DELETE_AVAILABILITY_SUCCESS = 'DELETE_AVAILABILITY_SUCCESS';
export const DELETE_AVAILABILITY_FAIL = 'DELETE_AVAILABILITY_FAIL';

export const GET_USER_START ='GET_USER_START'
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS'
export const GET_USER_FAIL = 'GET_USER_FAIL';


export const GET_APPOINTMENTS_START = 'GET_APPOINTMENTS_START';
export const GET_APPOINTMENTS_SUCCESS = 'GET_APPOINTMENTS_SUCCESS';
export const GET_APPOINTMENTS_FAIL = 'GET_APPOINTMENTS_FAIL';

export const DELETE_APPOINTMENT_START = 'DELETE_APPOINTMENT_START';
export const DELETE_APPOINTMENT_SUCCESS = 'DELETE_APPOINTMENT_SUCCESS';
export const DELETE_APPOINTMENT_FAIL = 'DELETE_APPOINTMENT_FAIL';

export const GET_PATIENTDETAILS_START ="GET_PATIENTDETAILS_START";
export const GET_PATIENTDETAILS_SUCCESS = 'GET_PATIENTDETAILS_SUCCESS';
export const GET_PATIENTDETAILS_FAIL = 'GET_PATIENTDETAILS_FAIL';


export const GET_RECORDING_START ="GET_RECORDING_START";
export const GET_RECORDING_SUCCESS = 'GET_RECORDING_SUCCESS';
export const GET_RECORDING_FAIL = 'GET_RECORDING_FAIL';



export const GET_RECORDING_SAMPLE_START ="GET_RECORDING_SAMPLE_START";
export const GET_RECORDING_SAMPLE_SUCCESS = 'GET_RECORDING_SAMPLE_SUCCESS';
export const GET_RECORDING_SAMPLE_FAIL = 'GET_RECORDING_SAMPLE_FAIL';
export const GET_CLINICIANS_APPOINTMENTS_START = 'GET_CLINICIANS_APPOINTMENTS_START';
export const GET_CLINICIANS_APPOINTMENTS_SUCCESS = 'GET_CLINICIANS_APPOINTMENTS_SUCCESS';
export const GET_CLINICIANS_APPOINTMENTS_FAIL = 'GET_CLINICIANS_APPOINTMENTS_FAIL';

export const GET_CLINICIAN_AVAILABILITY_START = ' GET_CLINICIAN_AVAILABILITY_START';
export const GET_CLINICIAN_AVAILABILITY_SUCCESS = ' GET_CLINICIAN_AVAILABILITY_SUCCESS';
export const GET_CLINICIAN_AVAILABILITY_FAIL = ' GET_CLINICIAN_AVAILABILITY_FAIL';

export const RESET_PATIENT_DETAILS_SUCCESS = ' RESET_PATIENT_DETAILS_SUCCESS';
