import React, { Component } from "react";
import ArrowBackSharpIcon from '@material-ui/icons/ArrowBackSharp';
import ArrowForwardSharpIcon from '@material-ui/icons/ArrowForwardSharp';
import { withTranslation } from 'react-i18next';
import moment from "moment";

const navigate = {
  PREVIOUS: "PREV",
  NEXT: "NEXT",
  TODAY: "TODAY",
  DATE: "DATE",
  MONTH: "month",
  DAY: "day",
  WEEK: "week"
};
const start = moment();
const remainder = 15 - (start.minute() % 15);
const date = moment(start).add(remainder, "minutes").format("YYYY-MM-DDTHH:mm:ss.SSS");
class CustomToolbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
    isOpen: false,
    startDate: date,
    endDate: date,
    startTime: date,
    endTime: date
    }
    this.baseState=this.state
  }
componentWillUnmount(){
  this.setState({
    isOpen:false,
  })
}
  modalOpenHandler = () => {
    this.setState({
      isOpen: true,
    });
  };
  modalHandler = () => {
    this.setState({
      isOpen: false,
    });
  };
  navigate = (action) => {
    this.props.onNavigate(action);
  };

  render() {
    const { t } = this.props;
    return (
      <React.Fragment>
        <div className="row py-3 mt-3">
          <div className="col-9 d-flex flex-row align-items-center">
            <div className="cal_button">
              <span className="rbc-btn-group m-0">
                <button
                    type="button"
                    className={
                      this.props.view === "day"
                          ? "btn btn_grey py-2 px-4 selected"
                          : "btn btn_grey py-2 px-4 rounded-0"
                    }
                    onClick={this.props.onView.bind(null, navigate.DAY)}
                >
                  {t('Day')}
                </button>
              </span>
              <span className="rbc-btn-group m-0">
                <button
                    type="button"
                    className={
                      this.props.view === "week"
                          ? "btn btn_grey py-2 px-4 selected"
                          : "btn btn_grey py-2 px-4 rounded-0"
                    }
                    onClick={this.props.onView.bind(null,navigate.WEEK)}
                >
                  {t('Week')}
                </button>
            </span>
              <span className="rbc-btn-group m-0">
                <button
                    type="button"
                    className={
                      this.props.view === "month"
                          ? "btn btn_grey py-2 px-4 selected"
                          : "btn btn_grey py-2 px-4 rounded-0"
                    }
                    onClick={this.props.onView.bind(null, navigate.MONTH)}
                >
                  {t('Month')}
                </button>
            </span>

            </div>
          </div>
          <div className="col-3 d-flex flex-row align-items-center justify-content-end">
            <button
                className="btn btn_teal_arrow btn-back"
                onClick={this.navigate.bind(null, navigate.PREVIOUS)}
            >
              <ArrowBackSharpIcon className="prev-icon" />
            </button>
            <button
                className="btn btn_teal_arrow btn-next"
                onClick={this.navigate.bind(null, navigate.NEXT)}
            >
              <ArrowForwardSharpIcon className="next-icon" />
            </button>
            <h6 className="mx-2">{this.props.view==="day" || this.props.view==="month"?moment(this.props.start).format("D MMMM, YYYY"):
                moment(moment(this.props.start)._d).format("MMMM D -")+moment(moment(this.props.end)._d).format("MMMM D, YYYY")}</h6>

          </div>
        </div>

        {/* {this.state.isOpen && <AvailabilityForm
      startDate={this.state.startDate}
      endDate={this.state.endDate}
      startTime={this.state.startTime}
      endTime={this.state.endTime}
      show={this.state.isOpen}
      handleClose={this.modalHandler}
      onSubmit={(e) => this.props.submitHandler(e)}
      recurrsivepattern={"NotRecursive"}
      component="toolbar"
      title={"Create Availability"}/>
  } */}
      </React.Fragment>
    );
  }
}

export default withTranslation()(CustomToolbar);

