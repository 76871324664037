import * as React from 'react';
import styled from 'styled-components';
import { colors } from '../../../style/styleSettings';
import { Flex } from '../Flex';
import FlexItem from '../FlexItem';
import { Body } from '../Typography';
import Spacing from '../Spacing';

type Props = {
  headers: Array<string>,
  rowNum: number,
  editAction?: Function,
  viewFeedbackDetailsAction?: Function,
  viewAdminConsultationDetailsAction?: Function,
  deleteAction?: Function,
  toggleChecked?: boolean,
  onToggleChange?: Function,
  drillDownAction?: Function,
  sortable?: Boolean
};

const Wrapper = styled.li`
  list-style-type: none;
  padding: 15px;
  background-color: ${colors.WHITE};
  border-bottom: 1px solid ${colors.LIGHT_GREY};
`;

function ListHeader(props: Props) {
  const {
    headers,
    rowNum,
    editAction,
    viewFeedbackDetailsAction,
    viewAdminConsultationDetailsAction,
    deleteAction,
    onToggleChange,
    drillDownAction,
    sortable
  } = props;

  return (
    <ul style={{ paddingLeft: 0 }}>
      <Wrapper {...props}>
        <Flex direction="row" wrap="nowrap" align="center">
          {sortable && <Spacing width={'30px'} />}
          {headers &&
            headers.map((value, index) => (
              <FlexItem colspan={4} key={rowNum + '-' + index}>
                <Body bold light>
                  {value}
                </Body>
              </FlexItem>
            ))}
          {onToggleChange && <FlexItem />}
          {((editAction) ||
            (deleteAction) || (viewFeedbackDetailsAction) || (viewAdminConsultationDetailsAction)) && <FlexItem colspan={2} />}
          {drillDownAction && <FlexItem />}
        </Flex>
      </Wrapper>
    </ul>
  );
}

export default ListHeader;
