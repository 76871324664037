import styled, { css } from 'styled-components';
import { colors } from '../../../style/styleSettings';

type Props = {
  bold: boolean,
  large: boolean,
  light: boolean
};

const bold = (props: Props) =>
  props.bold &&
  css`
    font-weight: 700;
  `;

const large = (props: Props) =>
  props.large &&
  css`
    font-size: 16px;
  `;

const light = (props: Props) =>
  props.light &&
  css`
    color: ${colors.MID_GREY};
  `;

const Body = styled.span`
  font-size: 14px;
  line-height: 1.5;
  color: ${props => props.color};

  ${bold} ${large} ${light};
`;

export default Body;

Body.defaultProps = {
  color: '#000'
};
