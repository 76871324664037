/* eslint-disable */
import axios from 'axios';

export const GoAPI = axios.create({
    baseURL: process.env.REACT_APP_GOKARDIA_API_BASE
});

export const TeleKardiaAPI = axios.create({
    baseURL: process.env.REACT_APP_TELEKARDIA_API_BASE
});
