import React,{useEffect} from 'react'
import { Dialog,Radio,RadioGroup,FormControlLabel,Grid,Box} from "@material-ui/core";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import { withStyles,makeStyles } from "@material-ui/core/styles";
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import CloseIcon from "@material-ui/icons/Close";
import MuiTypography from "../Shared/Typography/MuiTypography";
import * as API from './../../Services/API/actions'
import moment from "moment";
import Alert from "../Shared/Alert/Alerts";
import {useSelector} from "react-redux";
import { useTranslation } from "react-i18next";
import { getTimezoneName } from '../../Utilities/Utilities';


const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2),
    flexGrow:1,
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    height: '36px',
    width: '467px',
    left: '16px',
    top: '0px',
    borderRadius: '10px',
    background: 'rgba(118, 118, 128, 0.12)',
    "& .MuiInputBase-root":{
      fontFamily:'Work Sans'
    },
  },
  grid: {
    // padding,
    textAlign: 'center',
    maxHeight:'300px',
    overflowY:"auto"
  },
  label:{
    paddingLeft:'36px',
    textAlign:"left"
  },
    dialogPaper: {
     height: '239px'
    },
  iconButton: {
    padding: 10,
  },
  customTextField: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    fontFamily:'Work Sans !important',
    // borderBottom: "1px solid #d8d8d8",
    boxShadow:"0px 2px 4px rgba(0, 0, 0, 0.08)",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    // transition: theme.transitions.create(["border-color", "box-shadow"]),
    "& input::placeholder": {
      fontSize: "16px",
      fontFamily:'Work Sans !important',
      fontWeight:600,
      color:"#142A39",
      textAlign: 'left'
  }},
  paper:{
    padding:"16px"
  }
}));
const styles = (theme) => ({
  root: {
    padding: "15px",
    alignContent: "center",
    flexGrow:1
  },
  closeButton: {
    position: "absolute",
    backgroundColor: "rgba(20, 42, 57, 0.15)",
    right: theme.spacing(3),
    top: theme.spacing(2),
    color: "black",
    padding: "10px",
    margin: "0px 0px 0px 10px",
    border: "none",
    cursor: "pointer",
    width: 40,
    height: 40,
    opacity: 0.9,
    borderRadius: "8px",
  },
  form:{
    display: 'flex',
    direction:'column',
    alignItems: 'center',
    marginLeft:'36px'
  }
});
  const GreenRadio = withStyles({
    root: {
      "&$checked": {
        color: "#2D9F86",
        // position: 'absolute',
        // width: '16px',
        // height: '16px',
        // left: 'calc(50% - 16px/2)',
        // top: 'calc(50% - 16px/2)',
        background: '#DADADA',
      },
    },
    checked: {},
  })((props) => <Radio color="default" {...props} />);
export default function ReAssignAppointment(props) {
  const { t } = useTranslation();

  const pattern = "YYYY-MM-DDTHH:mm:ss.SSS";
    const classes = useStyles();
    const _ = require("lodash");
    //const dispatch = useDispatch();
    const [searchInput, setSearchInput] = React.useState("");
    const [seriespattern,setseriesPattern] = React.useState("");
    const [availabledoctors,setavailabledoctors] = React.useState([]);
    const [loading,setloading] = React.useState(false);
    const [alert,setAlert] = React.useState(null);
    const teamId = useSelector((state) => state.user.team.id);
    const clinicianId = useSelector((state) => state.user.profile.id);
    useEffect(() => {
      setloading(true);
      let queryparams = {
        dateTime:moment(props.data.scheduledStartTime).format(pattern),
        offsetInMinutes:new Date().getTimezoneOffset(),
        timeZone:getTimezoneName(),
        appointmentId:props.data.appointmentId,
      }
      API.AvailabledoctorListv2(props.adminTeamId,clinicianId,queryparams).subscribe(res=>{
        setloading(false);
        if ("detailed_error" in res.data) {
          setAlert(<Alert
            date={new Date()}
            title={"detailed_error" in res.data?"Unable to process the request":"Technical Issue"
            }
            message={"detailed_error" in res.data?res.data["detailed_error"]:
          "Unable to process this request due to internal server error."}
          />)
        }
        else{
        setavailabledoctors(_.sortBy(res.data.result,"firstName").reverse());
        
        }
      },
      (error)=>{
        setloading(false);
        console.log(error);
      })
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const reassignsubmitHandler = ()=>{
      if(seriespattern){
      // eslint-disable-next-line
      let selectedDoctor = availabledoctors.filter(doctor=>{
        if(doctor.availabilityId===seriespattern)
          return doctor;
      })
      let body={
        availabilityBlockId:seriespattern,
        memberId: selectedDoctor[0].physician["memberId"],
        patientAppointmentId: props.data.appointmentId,
        teamId: selectedDoctor[0].physician["teamId"],
        timeZoneOffset: new Date().getTimezoneOffset(),
        timeZone:getTimezoneName(),
      }
      let params={
        version:props.data.version
      }
      setloading(true);
      API.AdminReAssignAppointment(teamId,clinicianId,props.data.appointmentId,body,params).subscribe(
        res=>{
          setloading(false);
          if ("detailed_error" in res.data) {
            setAlert(<Alert
              date={new Date()}
              title={"detailed_error" in res.data?"Unable to process the request":"Technical Issue"
              }
              message={"detailed_error" in res.data?res.data["detailed_error"]:
            "Unable to process this request due to internal server error."}
            />)
          }
          else{
            props.onSubmit({
              doctorFirstName: selectedDoctor[0].physician['firstName'],
                    doctorLastName: selectedDoctor[0].physician['lastName']
            });
          }
        },
      
      (error)=>{
        console.log(error);
        setloading(false);
      })
    }
    else{
      setAlert(<Alert
        date={new Date()}
        title={"Select Doctor"}
    message={"select doctor to reassign the consultation."}
  />)
    }
    }
    const updateSearchInputValue = (e) => {
        setSearchInput(e.target.value);
      };
      const DialogContent = withStyles((theme) => ({
        root: {
          padding: "16px",
        },
      }))(MuiDialogContent);
      
      const DialogActions = withStyles((theme) => ({
        root: {
          padding:"16px"
        
        },
      }))(MuiDialogActions);
      const DialogTitle = withStyles(styles)((props) => {
        const { children, classes, onClose, title, ...other } = props;
        return (
          <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <MuiTypography
              fontSize="24px"
              fontFamily="Work Sans"
              lineHeight="28px"
              letterSpacing="28px"
              fontWeight={500}
              color="#000000"
              // margin="10px 30px 30px 30px"
              textAlign= 'left'
            >
               {t("Reassign consultation")}
            </MuiTypography>
            {onClose ? (
              <IconButton
                aria-label="close"
                className={classes.closeButton}
                onClick={onClose}
              >
                <CloseIcon />
              </IconButton>
            ) : null}
          </MuiDialogTitle>
        );
      });
      const handleRadioChange =(e)=>{
        setseriesPattern(e.target.value);
      }
      const filtereddoctors = Object.keys(availabledoctors).length>0?
      availabledoctors.filter(doctor=>{ // eslint-disable-line
        if(doctor.physician["firstName"]){
            let name = doctor.physician["firstName"]+" "+doctor.physician["lastName"];
            return (name).toLowerCase().replace(/ /g, "").indexOf(searchInput.toLowerCase().trim().replace(/ /g, ""))!==-1;
        }
      }):[];

      const cancelAppointmentHandler=()=>{
        setloading(true);
        props.handleClose();
     let params={
       version:props.data.version
     }
          API.deleteAppointment(teamId,props.memberId,props.data.appointmentId,params).subscribe(res=>{
            setloading(false);
            if ("detailed_error" in res.data) {
              setAlert(<Alert
                date={new Date()}
                title={"detailed_error" in res.data?"Unable to process the request":"Technical Issue"
                }
                message={"detailed_error" in res.data?res.data["detailed_error"]:
              "Unable to process this request due to internal server error."}
              />)
            }
            else{
              props.onCancel();
            }
          },
          (error)=>{
            console.log(error);
            setloading(false);
          })
        }
      // })
      //}
    return (
      <Dialog
      classes={(loading && availabledoctors.length===0)?{ paper : classes.dialogPaper}:null}
      maxWidth={'sm'}
      fullWidth={true}
      PaperProps={{className:classes.paper}}
      onClose={props.handleClose}
      aria-labelledby="customized-dialog-title"
      open={props.show}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
    >
      <DialogTitle
          title=""
          id="customized-dialog-title"
          onClose={props.handleClose}
        />
        {alert &&
        <div className="telekardia">
        <div className="m-3">{alert}</div>
        </div>
        }
        
      <DialogContent>
          { Object.keys(availabledoctors).length > 0 ?
           (
            <>
            
            <Grid item container>
            <Box px={2}>
            <MuiTypography
              fontSize="14px"
              fontFamily="Work Sans"
              fonStyle="normal"
              lineHeight="16px"
              letterSpacing="0.75px"
              fontWeight={400}
              color="#000000"
              margin="0 30px 0 30px"
            >
              {t("Select the cardiologist to reassign the consulation to")} :
              
            </MuiTypography>
         
              
                <InputBase
                classes={{ root: classes.customTextField }}
                  startAdornment={
                    <InputAdornment>
                    <IconButton
                              disabled={true}
                              className={classes.iconButton}
                              aria-label="search"
                          >
                            <SearchIcon />
                          </IconButton>
                    </InputAdornment>
                  }
                  autoFocus
                  id="standard-search"
                  className={classes.root}
                  // disableUnderline={true}
                  placeholder="Search"
                  type="search" 
                  onChange={(e) => updateSearchInputValue(e)} value={searchInput}
                    />
                      <MuiTypography 
                      fontFamily= 'Work Sans'
                      margin="0px 30px 0 30px"
                      fontSize= '14px'
                      fonStyle= 'normal'
                      fonWeight= '400'
                      lineHeight= '16px'
                      letterSpacing= '0.002em'
                      textAlign= 'left'
                      textTransform="uppercase"
                      
                      >{t("Available doctors on")} {moment(props.date).format("Do MMM, YYYY")} {t("at")} {moment(props.data.scheduledStartTime).format("hh:mm A")}:</MuiTypography>
                </Box>
            </Grid>
                 <Box className={classes.grid} m={2}>  
                   
                          {Object.keys(availabledoctors).length > 0 &&(
                                filtereddoctors.map((items,index)=>(
                                  // <Grid item xs={6} key={index}>
                                  <Grid container item xs={12}>
                                    <RadioGroup key={index}
                                      aria-label="quiz"
                                      name="quiz"
                                      value={seriespattern}
                                      onChange={handleRadioChange}
                                      >
                                        <FormControlLabel
                                        className={classes.label}
                                          value={items.availabilityId}
                                          control={<GreenRadio />}
                                          // label={items.physician["firstName"] +" "+items.physician["lastName"]}
                                          label={
                                            <MuiTypography 
                                            fontFamily= 'Work Sans'
                                            fontSize= '14px'
                                            fonStyle= 'normal'
                                            fonWeight= '400'
                                            lineHeight= '24px'
                                            letterSpacing= '-0.2px'
                                            color ="#142A39"
                                            >
                                              {items.physician["firstName"] +" "+items.physician["lastName"]}
                                            </MuiTypography>
                                            }
                                        />
                                    </RadioGroup>
                                  </Grid>     
                                
                                ))
                              )                    
                      }
                      
                           
              </Box>
        </>
          ):loading?
          <div className="telekardia">
            <div className={loading ? "filtering" : null}>
              <div className={loading ? "spinner-border" : null}></div>
            </div>
            </div>:
            <Grid item container>
            <MuiTypography 
            fontFamily= 'Work Sans'
            fontSize= '14px'
            fonStyle= 'normal'
            fonWeight= '400'
            lineHeight= '24px'
            letterSpacing= '-0.20000000298023224px'
            textAlign= 'left'
            color="#000000">
              {t("No Cardiologist are available in this time slot. Do you want to cancel the consultation")}?
              
            </MuiTypography>
          </Grid>   
           }
      </DialogContent>
      {(availabledoctors.length!==0 || !loading) && 
        <DialogActions>
          <div className="telekardia">
          <button
            type="button"
            disabled={loading}
            className="btn px-4 text-uppercase h40 ml-3 swal_green"
            onClick={availabledoctors.length>0?reassignsubmitHandler:cancelAppointmentHandler}>
              {availabledoctors.length>0?t('CONFIRM'):t('YES, CANCEL')}
          </button>
          </div>
        </DialogActions>
      }
    </Dialog>
    
  )
}
