/* eslint-disable */
import React,{ useState,useEffect }  from "react";
import '../../Assets/styles/bootstrap.scss'
import {Popup } from 'semantic-ui-react'
import 'semantic-ui-css/semantic.min.css'
import '../../Assets/styles/PopOver.scss';
import moment from "moment";
import CachedSharpIcon from "@material-ui/icons/CachedSharp";
import RecursiveConfirmationPopup from "./RecursiveConfirmationPopup";
import AvailabilityForm from "./AvailabilityForm";
import DeleteConfirmationPopup from "./DeleteConfirmationPopup";
import { useTranslation } from "react-i18next";


const pattern = "YYYY-MM-DDTHH:mm:ss.SSS";
const PopOver =(props)=> {
  const { t } = useTranslation();
  const myRef = React.useRef(null);
  const [startDate,setstartDate] = useState(props.event.isSplit?props.event.splitStartDateTime:props.event.start)
  const [endDate,setendDate] = useState(props.event.isSplit?props.event.splitEndDateTime:props.event.end)
  const [ismodalOpen,seteditAvailabilityModal] = useState(false);
  const [recursiveModalOpen,setrecursiveModalOpen] = useState(false);
  const [recursiveConfirmationType,setrecursiveConfirmationType] = useState(false);
  const [recursiveConfirmation,setrecursiveConfirmation] = useState(false);
  const [recursiveSeries,setrecursiveSeries] = useState(0);
  const [deleteconfirmationpopup,setdeleteconfirmationpopup] = useState(false);
  const [availabledata,setavailabledata]= useState({});
  const [ongoing,setOngoing] = useState(false);

  useEffect(()=>{
        const data=[];
        data.push({
          "id":props.event.id,
          "teamId":props.teamId,
          "memberId":props.clinicianId,
          "startDateTime":props.event.start,
          "endDateTime":props.event.end,
          "isSplit":props.event.isSplit,
          "splitStartDateTime":props.event.splitStartDateTime,
          "splitEndDateTime":props.event.splitEndDateTime,
          "recurrenceStartTime":props.event.recurrenceStartTime,
          "recurrencePattern":props.event.RecurrencePattern,
          "instanceDateTime":props.event.instanceDateTime,
          "version":props.event.version,
          "isRecurring":props.event.isRecurring,
          "recurrenceStartDateTime":props.event.RecurrsiveStart,
          "recurrenceEndDateTime":props.event.RecurrsiveEnd,
        })
        setavailabledata(data);
  },[])
  const recursiveModalHandler=(prps)=>{
      document.body.click();
      setrecursiveModalOpen(true);
      if(prps==="edit")
        setrecursiveConfirmationType(true);
      else
      setrecursiveConfirmationType(false);
  }
  const recursiveModalCloseHandler=()=>{
    setrecursiveModalOpen(false);
  }
  const editModalHandler=()=>{
    document.body.click();
    seteditAvailabilityModal(true);
  }
  const editModalCloseHandler=()=>{
    seteditAvailabilityModal(false);
  }
  const recursiveSeriesConfirmHanlder=(value)=>{
    setrecursiveSeries(value);
    setrecursiveModalOpen(false);
    setrecursiveConfirmation(false);
    if(recursiveConfirmationType){
      seteditAvailabilityModal(true);
    }
    else{
      setdeleteconfirmationpopup(true);
    }
  }
  const deleteconfirmationpopupOpenHandler=(current_time,event_time)=>{
      document.body.click();
      if(current_time>event_time)
        setOngoing(true);
      else
        setOngoing(false);
      setdeleteconfirmationpopup(true);
  }
  const deleteconfirmationpopupCloseHandler=()=>{
      setdeleteconfirmationpopup(false);
  }
    let status = "badge br_rad px-2";
    if (props.event.status === 1 || props.event.status === 6) {
      status = "badge br_rad px-2 status_green_scheduled";
    } else if (props.event.status === 4) {
      status = "badge br_rad px-2 status_green_completed";
    } else if (props.event.status === 3) {
      status = "badge br_rad px-2 status_inprogress_dotted";
    } else if (props.event.status === 5 || props.event.status === 8 || props.event.statusName === "Declined") {
      status = "badge br_rad px-2 status_red_canceled";
    } else if (props.event.status === 10) {
      status = "badge br_rad px-2 status_orange_closed";
    }
    else if(props.event.status === 7) {
      status = "badge br_rad px-2 status_gray_reportgenerated";
    }
    else if(props.event.status === 2) {
      status = "badge br_rad px-2 status_gray_doctorreviewstarted";
    }
    
    const convertMStoHM = (ms) => {
      let h, m;
      h = Math.floor(ms / 1000 / 60 / 60);
      m = Math.floor((ms / 1000 / 60 / 60 - h) * 60);
  
      return h + "." + m + "     "; //" mins ";
    };
    const  convertMtoHM = (ms) => {
      ms=ms*60;
      let h, m;
      h = Math.floor(ms / 60);
      m = Math.floor(ms%60);
  
      return h + "." + m + "Hours"; //" mins ";
    };
    let current_time = moment().format(pattern);
    let event_time = moment(props.event.start).format(pattern);
    let event_endtime = moment(props.event.end).format(pattern);
    const popoverClickRootClose =(
          <div className="telekardia">
          {props.event.hasOwnProperty("custom") ? (
            <>
              <div className="row my-2">
                <div className="col-12 p-0">
                  <h5 className="name_caption">
                    {props.event.patient.lastName
                      ? props.event.patient.firstName +
                        " " +
                        props.event.patient.lastName
                      : " -,- "}
                  </h5>
                </div>
              </div>

              {/* <div className="row">
                <label className="col-12 col-form-label p-0">
                  <b>{t("Age")} : </b>{" "}
                  {props.event.patient.dob
                    ? new Date().getFullYear() -
                      new Date(props.event.patient.dob).getFullYear() +
                      " years"
                    : "-"}
                </label>
              </div>
              <div className="row">
                <label className="col-12 col-form-label p-0">
                  <b>{t("Gender")} : </b> {props.event.patient.gender}
                </label>
              </div> */}
              <div className="row">
                <label className="col-12 col-form-label p-0">
                  <b>{t("Method of Consultation")} : </b>{props.event.appointmentChannelType}
                </label>
              </div>
              {/* <div className="row">
                <label className="col-12 col-form-label p-0">
                  <b>{t("Reason for Consultation")} : </b> {props.event.visitReason}
                </label>
              </div> */}
              <div className="row">
                <label className="col-12 col-form-label p-0">
                  <b>{t("Status")} : </b>{" "}
                  <span className={status}>{props.event.statusName}</span>
                </label>
              </div>
            </>
          ) : (
            // availability popover content
            <>
              <div className="row my-2">
                    <div className="col-12 p-0">
                      <h5 className="name_caption">{t("Availability")}</h5>
                    </div>
                  </div>
                  <div className="row">
                    <label className="col-12 col-form-label p-0">
                      <b>{props.event.isSplit?"Start Date":"Start Time"}: </b>{" "}
                      {props.event.isSplit?moment(startDate).format("MM/DD/YYYY")+","+" "+moment(startDate).format("hh:mm A"):moment(startDate).format("LT")}
                    </label>
                  </div>
                  <div className="row">
                    <label className="col-12 col-form-label p-0">
                      <b>{props.event.isSplit?"End Date":"End Time"} : </b> {props.event.isSplit?moment(endDate).format("MM/DD/YYYY")+","+" "+moment(endDate).
                      format("hh:mm A"):moment(endDate).format("LT")}
                    </label>
                  </div>
                  <div className="row">
                    <label className="col-12 col-form-label p-0">
                      <b>{t("Duration")} : </b>{" "}
                      {convertMtoHM(
                        moment.duration(
                          moment(endDate).diff(
                            moment(startDate)
                          )
                        ).asHours())
                      }
                    </label>
                  </div>
                  {(current_time >= event_time && current_time <= event_endtime) ||
                    current_time < event_time ? (
                      <div className="row">
                        <div className="col-12 col-form-label mt-4 ml-auto text-right">
                          <button
                            type="button"
                            className="btn px-4 btn-outline-primary text-uppercase h40"
                            onClick={() =>  props.event.isRecurring
                              ? recursiveModalHandler("delete")
                              : deleteconfirmationpopupOpenHandler(current_time,event_time)
                          }
                            // disabled={
                            //     current_time > event_endtime
                            //     ? true
                            //     : false
                            // }
                          >
                            {t("Delete")}
                    </button>
                          <button
                            type="button"
                            className="btn px-4 btn-outline-secondary text-uppercase h40 ml-3"
                            onClick={() =>
                              props.event.isRecurring
                                ? recursiveModalHandler("edit")
                                : editModalHandler()
                            }
                          >
                            {t("Edit")}
                    </button>
                        </div>
                      </div>
                    ) : null}
            </>
          )}
      </div> 
    );
    let content = <div></div>;

    if (props.event.hasOwnProperty("custom")) {
      if (props.view === "day") {
        content = (
        <div className={"popover_title occupy"}>
          <div className="d-flex flex-row m-2 l_15">
            <div className="text-truncate max_width250">{props.event.patient.lastName ? (props.event.patient.firstName + " " + props.event.patient.lastName)
              : " -,- "}</div>
            <div className="pl-3 f600">{(moment(props.event.start).format('hh:mm A')) + "-" + (moment(props.event.end).format('hh:mm A'))}</div>
          </div>
        </div>
        )
      }
      else
        content = (
        <div className={"popover_title occupy"}><div className="appt_content line16"><div className="text-truncate">{props.event.patient.lastName ? (props.event.patient.firstName + " " + props.event.patient.lastName)
          : " -,- "}</div><div className="time_content">{(moment(props.event.start).format('hh:mm A'))}</div></div></div>

          )
    }
    else if (props.event.isRecurring) {
      content =
      <div className={"recurrance_icon popover_title " + "occupy " + "recurrsiveIcon"}>
        <CachedSharpIcon />
      </div>

    }
    else {
      if (props.view === 'month') { 

        content = (
          <div className={"popover_title " + "occupy"}>
            <div className="monthview_content d-flex align-content-stretch">
              <div className="d-flex flex-column col availability p-0 p-1 py-2">
                <div>Availability</div>
                <div className="mt-auto">
                  <h5 className="m-0 count">
                    {convertMStoHM(props.event.availableMinutes)}
                  </h5>
                  <div>{t("Hours")}</div>
                </div>
              </div>
              {props.event.plannedApps > 0 && (
                <div className="appointment d-flex flex-column p-1 py-2">
                  <div>Consultations</div>
                  <div className="mt-auto">
                    <h5 className="m-0 count">
                      {props.event.plannedApps}
                    </h5>
                    <div>{t("Booked")}</div>
                  </div>
                </div>
              )}
            </div>
          </div>
        )
      }
      else
        content = (
          <div className={"popover_title " + "occupy"}>
          </div>
        );
    }
    const createContextFromEvent=(e)=> {
      const left = e.clientX
      const top = e.clientY
      const right = left + 1
      const bottom = top + 1
    
      return {
        getBoundingClientRect: () => ({
          left,
          top,
          right,
          bottom,
    
          height: 0,
          width: 0,
        }),
      }
    }
    return (
      <>
       <Popup 
       className="telekardia"
        context={myRef}
        content={popoverClickRootClose} trigger={
          <div className={"custom_popover " + "occupy"} ref={myRef} onClick={(e)=>{
            e.preventDefault();
            myRef.current=createContextFromEvent(e);
          }}>
          <div className={"occupy" + " custom_align"}>{content}</div>
        </div>
        }
        flowing={false}
        disabled={props.view==="month"?true:false}
        on={["click"]}
        hoverable={true}
        // positionFixed={true}
        hideOnScroll={true}
        style={{
          //opacity: 0.98,
          background:"rgba(249, 249, 249, 0.78)",
          backdropFilter: "blur(54.3656px)",
          borderRadius:"13px",
          padding:'2em',
          maxWidth:'400px',
          minWidth:'350px',
        }}
        />
        {recursiveModalOpen &&
          <RecursiveConfirmationPopup
          show={recursiveModalOpen}
          onClose={recursiveModalCloseHandler}
          type={recursiveConfirmationType}
          recursiveSeriesConfirmHanlder={recursiveSeriesConfirmHanlder}
        />
        }
        {ismodalOpen &&
           <AvailabilityForm
           seriesConfirm={recursiveSeries}
          //  selectedData={props.selectedDoctorName}
           providerName={props.selectedDoctorName}
           selectedDoctor={availabledata[0]}
           show={ismodalOpen}
           handleClose={editModalCloseHandler}
           startDate={props.event.start}
           endDate={props.event.end}
           startTime={props.event.start}
           endTime={props.event.end}
           onSubmit={props.onSubmitEditAvailabilityhandler}
           isRecursive={props.event.isRecurring}
           component="popover"
           type="edit"
           title={"Edit availability"}
         />
        }
        {deleteconfirmationpopup &&
            <DeleteConfirmationPopup
                open={deleteconfirmationpopup}
                handleClose={deleteconfirmationpopupCloseHandler}
                data={availabledata[0]}
                series={recursiveSeries}
                ongoing={ongoing}
                selectedDoctorName={props.selectedDoctorName}
                onCancel={props.onSubmitDeleteAvailabilityhandler}
                component="adminCalendar"
              />
        }
      </>
    );
  }

export default PopOver;
