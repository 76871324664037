/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import '../../../Assets/styles/bootstrap.scss'
import swal from 'sweetalert';
import {Popup } from 'semantic-ui-react'
import 'semantic-ui-css/semantic.min.css'
import '../../../Assets/styles/PopOver.scss';
import * as actions from "../../../Store/actions";
import moment from "moment";
import AvailabilityForm from "../../Appointments/Calendar/AvailabilityForm";
import Details from "../../Appointments/Calendar/Details";
import Modal from "../../Appointments/Calendar/AppointmentDetailsModal";
import CachedSharpIcon from "@material-ui/icons/CachedSharp";
import * as Constants from "./../../../Utilities/Constants";
import ReAssignAppointment from '../../Dashboard/ReAssignAppointment'
import * as API from '../../../Services/API/actions';
import { withTranslation } from 'react-i18next';
import { getTimezoneName } from "../../../Utilities/Utilities";
import Authorize from "../../Authorize";


const pattern = "YYYY-MM-DDTHH:mm:ss.SSS";
class PopOver extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      alert: null,
      isModalOpen: false,
      isappointmentDetailModalOpen: false,
      isoverlayModalClosetime: 1000,
      startDate: this.props.event.isSplit
        ? this.props.event.splitStartDateTime
        : this.props.event.start,
      endDate: this.props.event.isSplit
        ? this.props.event.splitEndDateTime
        : this.props.event.end,
      startTime: this.props.event.isSplit
        ? this.props.event.splitStartDateTime
        : this.props.event.start,
      endTime: this.props.event.isSplit
        ? this.props.event.splitEndDateTime
        : this.props.event.end,
      seriesConfirm: null,
      reassignmodalOpen: false,
      showpopover: false,
      consultationNote: null,
      tabvalue: 0,
    };
    this.baseState = this.state;
  }
  componentWillUnmount() {
    this.setState({
      isModalOpen: false,
      isappointmentDetailModalOpen: false,
    });
  }
  deleteHandler = () => {
    document.body.click();
    let message =
      this.props.event.custom === "patient"
        ? "Are you sure you want to cancel the Consultation?"
        : "Are you sure you want to delete the Availability?";

    const getAlerts = () =>
      swal({
        closeOnClickOutside: false,
        title: "Recurring Availability",
        text: "This is one availability in a series. What do you want to delete?",
        className: "telekardia",
        buttons: {
          close: {
            text: "",
            visible: true,
            value: "close",
            className: "swal_black swal_close",
            closeModal: true,
          },
          Single: {
            text: "THIS EVENT",
            visible: true,
            value: "single",
            className: "swal_ok",
            closeModal: true,
          },
          Multi: {
            text: "THIS EVENT AND ALL FUTURE EVENTS",
            visible: true,
            value: "multi",
            className: "swal_ok",
            closeModal: true,
          },
        },
      }).then((value) => {
        if (value === "single" || value === "multi") {
          swal({
            closeOnClickOutside: false,
            text: message,
            className: "telekardia",
            buttons: {
              cancel: {
                text: "",
                visible: true,
                className: "swal_close swal_black",
                closeModal: true,
              },
              confirm: {
                text: "Submit",
                visible: true,
                value: "delete",
                className: "swal_ok",
                closeModal: true,
              },
            },
          }).then((confirm) => {
            if (confirm === "delete") this.deleteEvent(value);
          });
        }
      });
    const getAlert = () => {
      swal({
        closeOnClickOutside: false,
        title:
          this.props.event.custom === "patient"
            ? "Cancel Consultation"
            : "Delete Availability",
        text: message,
        className: "telekardia",
        buttons: {
          cancel: {
            text: "",
            visible: true,
            className: "swal_cancel swal_black swal_close",
            closeModal: true,
          },
          confirm: {
            text: "Yes, Cancel",
            visible: true,
            className: "swal_ok swal_green",
            closeModal: true,
          },
        },
      }).then((willDelete) => {
        if (willDelete) this.deleteEvent();
      });
    };
    this.setState({
      alert: this.props.event.isRecurring ? getAlerts() : getAlert(),
    });
  };
  deleteEvent = (prps) => {
    this.setState({
      alert: null,
    });
    let queryParams = {
      endDate: moment(this.props.eRange).format(pattern),
      startDate: moment(this.props.sRange).format(pattern),
      offsetInMinutes: new Date().getTimezoneOffset(),
      timeZone: getTimezoneName(),
      splitBasedOnDays: true,
    };
    let deleteparams = {
      version: this.props.event.version,
    };
    let appointmentqueryParams = {
      reason: "string",
    };
    let params = {
      timeZoneOffset: new Date().getTimezoneOffset(),
      timeZone: getTimezoneName(),
      startTime: moment(this.props.event.instanceDateTime).format("HH:mm"),
      instanceDate: moment(this.props.event.instanceDateTime).format(
        "YYYY-MM-DD"
      ),
      version: this.props.event.version,
    };
    let url = this.props.event.hasOwnProperty("custom")
      ? API.physicianCalendardeleteappointment(
          this.props.teamId,
          this.props.profileId,
          this.props.event.id,
          deleteparams
        )
      : prps === "single"
      ? API.deleteAvailabilityRecursive(
          this.props.teamId,
          this.props.profileId,
          this.props.event.id,
          params
        )
      : API.deleteAvailabilityNonRecursive(
          this.props.teamId,
          this.props.profileId,
          this.props.event.id,
          deleteparams
        );
    url.subscribe((res) => {
      if ("detailed_error" in res.data) {
        this.props.onSubmit({
          type: this.props.event.hasOwnProperty("custom")
            ? "appointment"
            : "delete",
          error: res.data["detailed_error"],
        });
      } else {
        this.props.onSubmit({
          type: this.props.event.hasOwnProperty("custom")
            ? "appointment"
            : "delete",
        });
      }
    }),
      (error) => {
        this.props.onSubmit({
          type: "delete",
          error:
            "detailed_error" in error.response
              ? error.response["detailed_error"]
              : "Unable to process this request",
        });
      };
  };

  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };
  modalOpenHandler = () => {
    document.body.click();
    this.setState({
      isModalOpen: true,
    });
  };
  modalCloseHandler = () => {
    // this.setState({ isModalOpen: false });
    this.setState(this.baseState);
  };
  appointmentDetailmodalOpenHandler = () => {
    document.body.click();

    this.setState({
      isappointmentDetailModalOpen: true,
    });
    //this.getPatientDetails()
  };
  appointmentDetailmodalCloseHandler = () => {
    this.setState({
      isappointmentDetailModalOpen: false,
    });
  };
  startDate_handleChange = (date) => {
    this.setState({
      startDate: date,
    });
  };
  endDate_handleChange = (date) => {
    this.setState({
      endDate: date,
    });
  };
  startTime_handleChange = (date) => {
    this.setState({
      startTime: date,
    });
  };
  endTime_handleChange = (date) => {
    this.setState({
      endTime: date,
    });
  };
  submitHandler = (prps) => {
    this.setState({ isModalOpen: false });
    let queryParams = {
      endDate: moment(this.props.eRange).format(pattern),
      startDate: moment(this.props.sRange).format(pattern),
      offsetInMinutes: new Date().getTimezoneOffset(),
      timeZone: getTimezoneName(),
      splitBasedOnDays: true,
    };

    this.props.onSubmit(prps);
    this.props.onFetchEvents(
      this.props.profileId,
      this.props.teamId,
      queryParams
    );
  };
  editConfirmationHandler = () => {
    const getAlert = () =>
      swal({
        closeOnClickOutside: false,
        title: "Recurring Availability",
        text: "This is one availability in a series. What do you want to edit?",
        className: "telekardia",
        buttons: {
          Close: {
            text: "",
            visible: true,
            value: "close",
            className: "swal_black swal_close",
            closeModal: true,
          },
          Single: {
            text: "THIS EVENT",
            visible: true,
            value: "single",
            className: "swal_ok",
            closeModal: true,
          },
          Multi: {
            text: "THIS EVENT AND ALL FUTURE EVENTS",
            visible: true,
            value: "multi",
            className: "swal_ok",
            closeModal: true,
          },
        },
      }).then((value) => {
        if (value === "multi") {
          this.setState({
            startDate: this.props.event.RecurrsiveStart,
            startTime: this.props.event.RecurrsiveStart,
            endDate: this.props.event.RecurrsiveEnd,
            endTime: this.props.event.RecurrsiveEnd,
            seriesConfirm: value,
          });
          this.modalOpenHandler();
        } else if (value === "single") {
          this.setState({
            startDate: this.props.event.isSplit
              ? this.state.startDate
              : this.props.event.start,
            startTime: this.props.event.isSplit
              ? this.state.startTime
              : this.props.event.start,
            endDate: this.props.event.isSplit
              ? this.state.endDate
              : this.props.event.end,
            endTime: this.props.event.isSplit
              ? this.state.endTime
              : this.props.event.end,
            seriesConfirm: value,
          });
          this.modalOpenHandler();
        }
      });
    this.setState({ alert: getAlert() });
  };
  convertMStoHM = (ms) => {
    let h, m;
    h = Math.floor(ms / 1000 / 60 / 60);
    m = Math.floor((ms / 1000 / 60 / 60 - h) * 60);

    return h + "." + m + "     "; //" mins ";
  };
  convertMtoHM = (ms) => {
    ms = ms * 60;
    let h, m;
    h = Math.floor(ms / 60);
    m = Math.floor(ms % 60);

    return h + "." + m + "Hours"; //" mins ";
  };
  reassignmodalOpenHandler = () => {
    this.setState({
      reassignmodalOpen: true,
    });
  };
  reassignmodalCloseHandler = () => {
    this.setState({
      reassignmodalOpen: false,
    });
  };
  getEvents = () => {
    onFetchEvents();
  };

  setShowfunction = () => {
    this.setState({
      showpopover: !this.state.showpopover,
    });
  };
  setConsultationNote = (note) => {
    this.setState({
      consultationNote: note.target.value,
    });
    API.setConsultationNotes(
      this.props.teamId,
      this.props.profileId,
      this.props.event.id,
      decodeURI(note.target.value)
    ).subscribe(
      (res) => {
        console.log("res");
      },
      (error) => {
        console.log("error");
      }
    );
  };
  changetabvalue = (event, newValue) => {
    this.setState({
      tabvalue: newValue,
    });
  };
  createContextFromEvent(e) {
    const left = e.clientX;
    const top = e.clientY;
    const right = left + 1;
    const bottom = top + 1;

    return {
      getBoundingClientRect: () => ({
        left,
        top,
        right,
        bottom,

        height: 0,
        width: 0,
      }),
    };
  }
  render() {
    const { t } = this.props;
    let current_time = moment().format(pattern);
    let event_time = moment(this.props.event.start).format(pattern);
    let event_endtime = moment(this.props.event.end).format(pattern);
    let status = "badge br_rad px-2";
    if (this.props.event.status === 1 || this.props.event.status === 6) {
      status = "badge br_rad px-2 status_green_scheduled";
    } else if (this.props.event.status === 4) {
      status = "badge br_rad px-2 status_green_completed";
    } else if (this.props.event.status === 3) {
      status = "badge br_rad px-2 status_inprogress_dotted";
    } else if (this.props.event.status === 5 || this.props.event.status === 8 || this.props.event.statusName === "Declined") {
      status = "badge br_rad px-2 status_red_canceled";
    } else if (this.props.event.status === 10) {
      status = "badge br_rad px-2 status_orange_closed";
    }
    else if(this.props.event.status === 7) {
      status = "badge br_rad px-2 status_gray_reportgenerated";
    }
    else if(this.props.event.status === 2) {
      status = "badge br_rad px-2 status_gray_doctorreviewstarted";
    }
    let popoverClickRootClose = (
      <div className="telekardia">
        {this.props.event.hasOwnProperty("custom") ? (
          <>
            <div className="row my-2">
              <div className="col-12 p-0">
                <h5 className="name_caption">
                  {this.props.event.patient.lastName
                    ? this.props.event.patient.firstName +
                      " " +
                      this.props.event.patient.lastName
                    : " -,- "}
                </h5>
              </div>
            </div>

            <div className="row">
              <label className="col-12 col-form-label p-0">
                <b>{t("Age")} : </b>{" "}
                {this.props.event.patient.dob
                  ? new Date().getFullYear() -
                    new Date(this.props.event.patient.dob).getFullYear() +
                    " years"
                  : "-"}
              </label>
            </div>
            <div className="row">
              <label className="col-12 col-form-label p-0">
                <b>{t("Gender")} : </b> {this.props.event.patient.gender}
              </label>
            </div>
            <div className="row">
              <label className="col-12 col-form-label p-0">
                <b>{t("Method of Consultation")} : </b>{" "}
                {this.props.event.appointmentChannelType}
              </label>
            </div>
            <div className="row">
              <label className="col-12 col-form-label p-0">
                <b>{t("Reason for Consultation")} : </b>{" "}
                {this.props.event.visitReason &&
                this.props.event.visitReason.length > 0
                  ? this.props.event.visitReason
                  : "-"}
              </label>
            </div>
            <div className="row">
              <label className="col-12 col-form-label p-0">
                <b>{t("Status")} : </b>{" "}
                <span className={status}>{this.props.event.statusName}</span>
              </label>
            </div>
            {(current_time >= event_time &&
              current_time <= event_endtime &&
              this.props.event.status !==
                Constants.AppointmentStatusTypes.Completed &&
              this.props.event.status !==
                Constants.AppointmentStatusTypes.closed) ||
            (current_time < event_time &&
              this.props.event.status !==
                Constants.AppointmentStatusTypes.Completed &&
              this.props.event.status !==
                Constants.AppointmentStatusTypes.closed) ? (
              <div className="row">
                <div className="col-12 col-form-label mt-4 text-center">
                  <button
                    type="button"
                    className="btn px-4 btn-outline-secondary text-uppercase h40 ml-3"
                    onClick={this.appointmentDetailmodalOpenHandler}
                  >
                    {t("View Details")}
                  </button>
                </div>
              </div>
            ) : null}
          </>
        ) : (
          // availability popover content
          <>
            <div className="row my-2">
              <div className="col-12 p-0">
                <h5 className="name_caption">{t("Availability")}</h5>
              </div>
            </div>
            <div className="row">
              <label className="col-12 col-form-label p-0">
                <b>
                  {this.props.event.isSplit ? "Start Date" : "Start Time"}:{" "}
                </b>{" "}
                {this.props.event.isSplit
                  ? moment(this.state.startDate).format("MM/DD/YYYY") +
                    "," +
                    " " +
                    moment(this.state.startTime).format("hh:mm A")
                  : moment(this.props.event.start).format("LT")}
              </label>
            </div>
            <div className="row">
              <label className="col-12 col-form-label p-0">
                <b>{this.props.event.isSplit ? "End Date" : "End Time"} : </b>{" "}
                {this.props.event.isSplit
                  ? moment(this.state.endDate).format("MM/DD/YYYY") +
                    "," +
                    " " +
                    moment(this.state.endTime).format("hh:mm A")
                  : moment(this.props.event.end).format("LT")}
              </label>
            </div>
            <div className="row">
              <label className="col-12 col-form-label p-0">
                <b>{t("Duration")} :</b>{" "}
                {this.convertMtoHM(
                  moment
                    .duration(
                      moment(this.state.endDate).diff(
                        moment(this.state.startDate)
                      )
                    )
                    .asHours()
                )}
              </label>
            </div>
            {(current_time >= event_time && current_time <= event_endtime) ||
            current_time < event_time ? (
              <Authorize>
                <div className="row">
                  <div className="col-12 col-form-label mt-4 ml-auto text-right">
                    <button
                      type="button"
                      className="btn px-4 btn-outline-primary text-uppercase h40"
                      onClick={() => this.deleteHandler()}
                      // disabled={
                      //   current_time >= event_time &&
                      //     current_time <= event_endtime
                      //     ? true
                      //     : false
                      // }
                    >
                      {t("Delete")}
                    </button>
                    <button
                      type="button"
                      className="btn px-4 btn-outline-secondary text-uppercase h40 ml-3"
                      onClick={() =>
                        this.props.event.isRecurring
                          ? this.editConfirmationHandler()
                          : this.modalOpenHandler()
                      }
                    >
                      {t("Edit")}
                    </button>
                  </div>
                </div>
              </Authorize>
            ) : null}
          </>
        )}
      </div>
    );
    let content = <div></div>;

    if (this.props.event.hasOwnProperty("custom")) {
      // appintment content display for Day view
      if (this.props.view === "day") {
        content = (
          <div className={"popover_title occupy"}>
            <div className="d-flex flex-row m-2 l_15">
              <div className="text-truncate max_width250">
                {this.props.event.patient.lastName
                  ? this.props.event.patient.firstName +
                    " " +
                    this.props.event.patient.lastName
                  : " -,- "}
              </div>
              <div className="pl-3 f600">
                {moment(this.props.event.start).format("hh:mm A") +
                  "-" +
                  moment(this.props.event.end).format("hh:mm A")}
              </div>
            </div>
          </div>
        );
      } else
        content = (
          <div className={"popover_title occupy"}>
            <div className="appt_content line16">
              <div className="text-truncate">
                {this.props.event.patient.lastName
                  ? this.props.event.patient.firstName +
                    " " +
                    this.props.event.patient.lastName
                  : " -,- "}
              </div>
              <div className="time_content">
                {moment(this.props.event.start).format("hh:mm A")}
              </div>
            </div>
          </div>
        );
    } else if (this.props.event.isRecurring) {
      content = (
        <div
          className={
            "recurrance_icon popover_title " + "occupy " + "recurrsiveIcon"
          }
        >
          <CachedSharpIcon />
        </div>
      );
    } else {
      //
      if (this.props.view === "month") {
        content = (
          <div className={"popover_title " + "occupy"}>
            <div className="monthview_content d-flex align-content-stretch">
              <div className="d-flex flex-column col availability p-0 p-1 py-2">
                <div>Availability</div>
                <div className="mt-auto">
                  <h5 className="m-0 count">
                    {this.convertMStoHM(this.props.event.availableMinutes)}
                  </h5>
                  <div>{t("Hours")}</div>
                </div>
              </div>
              {this.props.event.plannedApps > 0 && (
                <div className="appointment d-flex flex-column p-1 py-2">
                  <div>Consultations</div>
                  <div className="mt-auto">
                    <h5 className="m-0 count">
                      {this.props.event.plannedApps}
                    </h5>
                    <div>{t("Booked")}</div>
                  </div>
                </div>
              )}
            </div>
          </div>
        );
      } else content = <div className={"popover_title " + "occupy"}></div>;
    }
    let form = null;
    if (this.state.isModalOpen) {
      form = (
        <AvailabilityForm
          {...this.props}
          startDate={this.state.startDate}
          endDate={this.state.endDate}
          startTime={this.state.startTime}
          endTime={this.state.endTime}
          show={this.state.isModalOpen}
          disabled={this.props.event.isRecurring ? false : true}
          recurrsivepattern={this.props.event.RecurrencePattern}
          seriesConfirm={this.state.seriesConfirm}
          handleClose={this.modalCloseHandler}
          onSubmit={(e) => this.submitHandler(e)}
          title={
            this.state.seriesConfirm === "multi"
              ? "Edit Availability (Series)"
              : "Edit Availability"
          }
          component="popover"
        ></AvailabilityForm>
      );
    }
    let viewDetailsModal = null;
    if (
      this.props.event.eventType === "appointment" &&
      this.state.isappointmentDetailModalOpen
    ) {
      viewDetailsModal = (
        <Modal
          show={this.state.isappointmentDetailModalOpen}
          handleClose={this.appointmentDetailmodalCloseHandler}
          onSubmit={this.reassignmodalOpenHandler}
          component={"popover"}
          disable={new Date() > new Date(event_time) ? true : false}
        >
          {/* {this.props.loading?
        <div className={this.props.isLoading ? 'spinner-border' : null}></div> */}
          <Details
            {...this.props}
            ekgId={this.props.event.ekgId}
            appointmentId={this.props.event.id}
            component="popover"
            handleClose={this.appointmentDetailmodalCloseHandler}
            onSubmit={this.reassignmodalOpenHandler}
            setConsultationNote={this.setConsultationNote}
            consultationNote={this.state.consultationNote}
            tabvalue={this.state.tabvalue}
            changetabvalue={this.changetabvalue}
          />
        </Modal>
      );
    }
    return (
      <React.Fragment>
        <Popup
          context={this.myRef}
          content={popoverClickRootClose}
          trigger={
            <div
              className={"custom_popover " + "occupy"}
              ref={this.myRef}
              onClick={(e) => {
                e.preventDefault();
                this.myRef.current = this.createContextFromEvent(e);
              }}
            >
              <div className={"occupy" + " custom_align"}>{content}</div>
            </div>
          }
          disabled={this.props.view === "month" ? true : false}
          flowing={false}
          on={["click"]}
          hoverable={true}
          // positionFixed={true}
          hideOnScroll={true}
          style={{
            //opacity: 0.98,
            "&:before": { background: "rgba(249, 249, 249, 0.78)" },
            background: "rgba(249, 249, 249, 0.78)",
            backdropFilter: "blur(54.3656px)",
            borderRadius: "13px",
            padding: "2em",
            maxWidth: "400px",
            minWidth: "350px",
          }}
        />
        {form}
        {viewDetailsModal}
        {this.state.reassignmodalOpen === true && (
          <ReAssignAppointment
            show={this.state.reassignmodalOpen}
            handleClose={this.reassignmodalCloseHandler}
            onCancel={this.deleteEvent}
            component="popover"
            onSubmit={this.submitHandler}
            patientDetails={this.props.event}
            {...this.props}
          />
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    events: state.events.events,
    profileId: state.user.profile.id,
    teamId: state.user.team.id,
    isLoading:state.events.loading
  };
};


export default connect(mapStateToProps)(withTranslation()(PopOver));
