/* eslint-disable */
import * as actionTypes from '../actions/actionTypes'
import { updateObject } from '../utility';

const initialState = {
    profile: null,
    team: null,
    // permissions: null,
    // authorizationType: null,

};

const getUserSuccess = (state, action) => {
    // eslint-disable-next-line no-console
    if(action.payload ==="logOut")
        return updateObject(state,{
            profile:null,
            team:null
        })
    else
        return updateObject(state, {
            profile: action.payload.profile,
            team: action.payload.teams[0],
        })
}

const getUserFail = (state, action) => {
    //const newEvent = updateObject(action.eventData, { id: action.id });
    return updateObject(state, null);
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_USER_SUCCESS: return getUserSuccess(state, action);
        case actionTypes.GET_USER_FAIL: return getUserFail(state, action);
        default: return state;
    }
}

export default reducer