import React from 'react';
import Button from '../Button';
import Icon from '../Icon';
import { icons } from '../../../constants/icons';

type Props = {
  onClick: () => void
};
const DrillDownButton = function(props: Props) {
  return (
    <Button
      style={{ padding: '0px 0px' }}
      type="button"
      small
      secondary
      onClick={props.onClick}
      noBorder
    >
      <Icon strokeColor="#0072CE" name={icons.KEYBOARD_ARROW_RIGHT} size={30} />
    </Button>
  );
};

export default DrillDownButton;
