/*eslint-disable */
import React, { useState, useCallback, useEffect } from "react";
import { useAsync, IfPending, IfFulfilled, IfRejected } from "react-async";
import Container from "../Container";
import { Flex } from "../Flex";
import FlexItem from "../FlexItem";
import EcgLoader from "../EcgLoader";
import Spacing from "../Spacing";
import MaterialPagination from "../MaterialPagination";
import { move } from "./../../../Utilities/Utilities";
import get from "lodash.get";
import { Note } from "./styled";
import {
  createStyles,
  makeStyles,
  withStyles,
  Theme,
} from "@material-ui/core/styles";
import {
  Paper,
  Button,
  InputBase,
  Popover,
  IconButton,
  OutlinedInput,
  Grid,
  Select,
  MenuItem,
  FormControl,
  Divider,
  FormControlLabel,
  Checkbox,
  Chip,
  InputLabel,
  ListItemText,
  Collapse
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import ClearSharp from "@material-ui/icons/ClearSharp";
import ListTable from "./ListTable";
import { streamToPromise } from "./../../../Utilities/Utilities";
import apiservice from "./../../../api/services/apiservice.service";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTranslation } from "react-i18next";
import MuiTypography from "../Typography/MuiTypography";
import Datepicker from "../../Appointments/Calendar/material_datepicker_metrix";
import moment from "moment";
import startOfDay from "date-fns/startOfDay";
import endOfDay from "date-fns/endOfDay";
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import AlertTitle from '@material-ui/lab/AlertTitle';

type Props = {
  feature: String,
  /** The list of entity props to display in the list  */
  entityProps: Array<string>,
  /**
   * Function to load list of entities for the page
   *
   * @param {Object} filter An object with each property corresponding to a filter name, and the value the select one
   * @param {string} searchInput String to search, empty to return all results
   * @param {number} pageNumber When paging, this is the page number
   */
  loadAction: Promise<Array>,
  /** In container, wrap with withRouter to get history and privileges */
  history?: {
    push: Function, // eslint-disable-line
  },
  /** The table headers, component will looks up in translation files references*/
  headers?: Array<string>,
  /**
   * Function to add an entity
   *
   * @param {Object} history Router history object
   */
  addAction?: Function,
  /**
   * Function to edit an entity
   *
   * @param {Object} entity Entity to edit
   * @param {Object} history Router history object
   */
  editAction?: Function,
  /**
   * Function to drilldown on an entity
   *
   * @param {Object} entity Entity to edit
   * @param {Object} history Router history object
   */
  drillDownAction?: Function,
  /**
   * Function to delete an entity and refresh page
   *
   * @param {Object} entity Entity to remove
   * @param {string} searchInput String to search, empty to return all results
   * @param {number} pageNumber When paging, this is the page number
   */
  deleteAction?: Promise<Array>,
  /**
   * Function to save inline edited entity and refresh page
   *
   * @param {Object} entity Modified entity to save
   * @param {string} searchInput String to search, empty to return akl results
   * @param {number} pageNumber When paging, this is the page number
   */
  inlineEditAction?: Promise<Array>,
  /**
   * Function to delete an entity and refresh page
   *
   * @param {Array<Object>} sortedEntities entities with new sort order (sort sent is based on array order, provided function should compare to sort field and update if required)
   * @param {string} searchInput String to search, empty to return all results
   * @param {number} pageNumber When paging, this is the page number
   */
  sortAction?: Promise<Array>,
  /** Prop name containing the toggle field, must be a string */
  toggleField?: Boolean,
  /** Prop name containing the favorite field, must be a string */
  favoriteField?: Boolean,
  /** Page title */
  title?: string,
  sortordertype: String,
  sortPropsName: String, 
  /** Boolean to set whether we support search */
  searchEnabled?: Boolean,
  /** Filters using select, each filter object should have a "name" property (string) and an "options" property (Array). Each option object should have a "value" and a "label" property */
  filters?: Array<Object>,
  /** Custom renderer for field, property should be the same as the name of the field, and value
   * should be a function. The special "_edit" prop is for deciding whether to display edit button or not  */
  render?: { [string]: Function },
};

const BootstrapInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      "label + &": {
        marginTop: theme.spacing(3),
        fontSize: 16,
        color: "#000 !important",
        width: "100%",
      },
    },
    input: {
      borderRadius: 4,
      position: "relative",
      backgroundColor: theme.palette.background.paper,
      borderBottom: "1px solid #d8d8d8",
      fontSize: 16,
      padding: "10px 26px 10px 12px",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      // Use the system font instead of the default Roboto font.
      fontFamily: ["inherit"].join(","),
    },
  })
)(InputBase);

const GreenCheckbox = withStyles({
  root: {
    color: "#000000",
    "&$checked": {
      color: "#2D9F86",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);


const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    overflowX: "auto",
    marginTop: 0,
  },
  listroot: {
    // maxHeight: "calc(50vh)",
    width:"100%",
    overflow: 'auto'
  },
  listcontainer: {
    backgroundColor: "#FFFFFF",
  },
  all_apptContainer: {
    marginTop: "5px"
  },
  bg_white1: {
    backgroundColor: "#FFFFFF",
    padding: "18px"
  },
  searchbarroot: {
    padding: "2px 4px",
    display: '-webkit-box' && '-moz-box' && '-ms-flexbox' && '-webkit-flex' && 'flex',
    alignItems: "center",
    // background: "rgba(118,118,128,0.12)",
    borderRadius:"4px",
    border: "1px solid rgba(20, 42, 57, 0.16)",
    boxShadow:"none",
    height: "36px",
    width:"600px !important"
  },
  searchbarroottablet: {
    padding: "2px 4px",
    display: '-webkit-box' && '-moz-box' && '-ms-flexbox' && '-webkit-flex' && 'flex',
    alignItems: "center",
    // background: "rgba(118,118,128,0.12)",
    border: "1px solid rgba(20, 42, 57, 0.16)",
    borderRadius:"4px",
    boxShadow:"none",
    height: "36px",
    width:"281px !important"
  },
  searchbarroottabletipad: {
    padding: "2px 4px",
    display: '-webkit-box' && '-moz-box' && '-ms-flexbox' && '-webkit-flex' && 'flex',
    alignItems: "center",
    // background: "rgba(118,118,128,0.12)",
    border: "1px solid rgba(20, 42, 57, 0.16)",
    borderRadius:"4px",
    boxShadow:"none",
    height: "36px",
    width:"215px !important"
  },
  searchbarroottabletlandscape: {
    padding: "2px 4px",
    display: '-webkit-box' && '-moz-box' && '-ms-flexbox' && '-webkit-flex' && 'flex',
    alignItems: "center",
    border: "1px solid rgba(20, 42, 57, 0.16)",
    // background: "rgba(118,118,128,0.12)",
    borderRadius:"4px",
    boxShadow:"none",
    height: "36px",
    width:"415px !important"
  },
  searchbarrootmain: {
   marginTop: "19px"
  },
  titletablet:{
   width: "100%"
  },
  paperoot: {
    // display: 'flex',
    // flexWrap: 'wrap',
    "& > *": {
      // margin: theme.spacing(1),
      width: theme.spacing(100),
    },
  },
  // input: {
  //   marginLeft: theme.spacing(1),
  //   flex: 1,
  // },
  searchinputclass: {
    marginLeft: theme.spacing(1),
      flex: 1,
    // background: "#ffffff",
    fontFamily: "Work Sans !important",
    fontWeight: 400,
    color: "#142A39 !important",
    opacity: 1,
    textAlign: "left",
    "& input::placeholder": {
      fontFamily: "Work Sans !important",
      fontWeight: 400,
      color: "#142A39 !important",
      opacity: 1,
      textAlign: "left",
    }, 
    "&:hover": {
      color: "#2D9F86",
    },
    "&$focused": {
      color: "#2D9F86",
    },
    fontSize: 16,
    padding: "10px 26px 10px 12px",
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  container: {
    display: '-webkit-box' && '-moz-box' && '-ms-flexbox' && '-webkit-flex' && 'flex',
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  dense: {
    marginTop: theme.spacing(2),
  },
  menu: {
    width: 200,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 280,
  },
  formControllabelstyle: {  
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    fontFamily: "Work Sans !important",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "16px",
    letterSpacing: "0.4px",
    color: "rgba(20, 42, 57, 0.72)",
  },
  formControlsmall: {
    background: "#FFFFFF",
    minWidth: 120,
    maxWidth: 300,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  popovergrid: {
    padding: theme.spacing(3),
    background: "white",
  },
  largeinputtablet: {
    color: "#142A39 !important",
    fontFamily: "Work Sans !important",
    "& input::placeholder": {
      fontFamily: "Work Sans !important",
      fontSize: "12px",
      fontWeight: 400,
      color: "#142A39 !important",
      opacity: 1,
      textAlign: "left",
    },
    "&:focus": {
      borderColor: "#2D9F86",
    },
  },
  alertroot: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
    "& .MuiCollapse-wrapperInner":{
      background:'#FFF8D3'
    },
    "& .MuiAlertTitle-root":{
      maxWidth: 164,
      color: '#142A39',
      fontWeight:'bold',
      fontFamily:'Work Sans',
      fontSize: '14px',
      lineHeight: '24px',
      display: 'flex',
      alignItems: 'center',
      letterSpacing: '-0.2px',
      wordWrap: "break-word"
    }
  },
}));

const userError = (error, lastAction) => {
  switch (lastAction) {
    case "DELETE":
      return "Error DeletingData";
    case "ENABLE":
      return "Error SavingData";
    case "FAVORITE":
      return "Error SavingData";
    case "SORT":
      return "Error Sorting Data";
    case "SEARCH":
      return "Error Loading Data";
    case "FILTER":
      return "Error Loading Data";
    default:
      return "Error Loading Data";
  }
};

function useWidth() {
  const theme = useTheme();
  const keys = [...theme.breakpoints.keys].reverse();
  return (
    keys.reduce((output, key) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const matches = useMediaQuery(theme.breakpoints.up(key));
      return !output && matches ? key : output;
    }, null) || "xs"
  );
}

const useOutlinedInputStyles = makeStyles((theme) => ({
  root: {
    // "& $notchedOutline": {
    //   borderColor: "red"
    // },
    color: "#000000",
    backgroundColor: "#ffffff",
    "&:hover $notchedOutline": {
      color: "#2D9F86",
      borderColor: "#2D9F86",
    },
    "&$focused $notchedOutline": {
      color: "#000000",
      borderColor: "#2D9F86",
    },
  },
  input: {
    background: "#ffffff",
    fontFamily: "Work Sans !important",
    fontWeight: 400,
    color: "#142A39 !important",
    opacity: 1,
    textAlign: "left",
    '&:placeholder': {
      fontFamily: "Work Sans !important",
      fontWeight: 400,
      color: "#142A39 !important",
      opacity: 1,
      textAlign: "left",
    },
 
    "&:hover": {
      color: "#2D9F86",
    },
    "&$focused": {
      color: "#2D9F86",
    },
    fontSize: 16,
    padding: "10px 26px 10px 12px",
  },
  focused: {},
  notchedOutline: {},
}));


const ListPage = (props: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const mediawidth = useWidth();  
  const {
    history,
    headers,
    entityProps,
    feature,
    loadAction,
    addAction,
    deleteAction,
    editAction,
    drillDownAction,
    uploadappointmentsAction,
    inlineEditAction,
    sortAction,
    toggleField,
    favoriteField,
    title,
    render,
    searchEnabled,
    watch,
    sortordertype,
    sortPropsName
  } = props;
  const outlinedInputClasses = useOutlinedInputStyles();
  const [isSelectorOpen, setisSelectorOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const popoveropen = Boolean(anchorEl);
  const popoverid = popoveropen ? "simple-popover" : undefined;
  const [invaliddatealert, setinvaliddatealert] = useState(false);
  const [invaliddatealertmessage, setinvaliddatealertmessage] = useState("Invalid Date Time");
  const [startDate, setstartDate] = useState(new Date());
  const [endDate, setendtDate] = useState(new Date());

  const [startdateformatted, setstartDateformatted] = useState(
    ""
  );
  const [enddateformatted, setenddateformatted] = useState(
    ""
  );
  const [dateselectionmenuitemtext, setdateselectionmenuitemtext] = useState(
    "Select date range"
  );

  const [searchInput, setSearchInput] = useState("");
  const [lastAction, setLastAction] = useState("LOAD");
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setpageNumber] = useState(0);
  const [sortprops, setsortprops] = useState({});
  // const [clientids] = useState([
  //   { id: "123", name: "PT System" },
  //   { id: "1", name: "ALIVECORE" }
  // ]);
  const [filter, setFilter] = useState({});
  const [filterValues, setFilterValues] = useState({});

  const [status, setStatus] = React.useState([]);
  const [newvals, setnewvals] = useState(["100","1", "2", "3", "4", "5", "6", "7", "8", "10", "11"]);
  const [newvalsname, setnewvalsname] = useState(["All"]);
    // "1,2,3,4,5,6,7,8,9,10");
  const [statusList, setstatusList] = React.useState([]);
  // const isMounted = useRef(true);

  const loadLookupAction = useCallback(async () => {
    let allstatus = {
      id: 100,
      status: "All",
      displayName: "All",
      isDisplay: true,
    };
    const appointmentRequest = streamToPromise(
      apiservice.getapiservice({
        baseUrl: `api/v1/appointment-status`,
      })
    );
    const appointmentstatuslookup = await appointmentRequest;
    setstatusList([allstatus,...statusList, ...appointmentstatuslookup.data.result.filter((x) => x.isDisplay === true)]);
  }, []);

  useEffect(() => {
    loadLookupAction();
  }, []);

  useEffect(() => {
    if (props.resetdata === true) {
      onSearchClick();
    }
  }, [props.resetdata]); 

  // const isMobileView =
  //   useMedia(
  //     // Media queries
  //     ["(min-width: 961px)"],
  //     // Column counts (relates to above media queries by array index)
  //     ["large"],
  //     // Default column count
  //     "small"
  //   ) === "small";

  const modifyAction = async (params) => {
    const [action, newArgs] = params;

    switch (action) {
      case "DELETE":
        return deleteAction(newArgs);
      case "ENABLE":
        return inlineEditAction(newArgs);
      case "FAVORITE":
        return inlineEditAction(newArgs);
      case "SORT":
        return sortAction(newArgs);
      case "SEARCH":
        return loadAction(newArgs);
      case "FILTER":
        return loadAction(newArgs);
      default:
        throw new Error("unhandled action");
    }
  };

  // Fetch data
  const listState = useAsync({
    promiseFn: loadAction,
    deferFn: modifyAction,
    watch,
  });
  const { run, setData, isLoading, error, setError } = listState;

  /** Change rows per page */
  const onSizeChange = useCallback(
    async (event) => {
      const rowsPerPage = +event.target.value;
      setPageSize(rowsPerPage);
      setLastAction("SEARCH");
      return run("SEARCH", {
        filter,
        searchInput,
        sortprops: sortprops,
        pageNumber: 1,
        take: rowsPerPage,
      });
    },
    [filter, run, searchInput]
  );

  /** Search input functions */
  // const onSearchClick = useCallback(async () => {
  //   setLastAction("SEARCH");
  //   return run("SEARCH", { filter, searchInput });
  // }, [filter, run, searchInput]);

  const onSearchClick = useCallback(async () => {
    setLastAction("SEARCH");
    return run("SEARCH", {
      searchInput: searchInput,
      sortprops: sortprops,
      filter: filter,
      take: pageSize,
      pageNumber: pageNumber
    });
  }, [filter, run, searchInput]);

  const onClear = useCallback(async () => {
    setSearchInput("");
    // setPageSize(pageSize);
    setLastAction("SEARCH");
    return run("SEARCH", {
      searchInput: "",
      sortprops: sortprops,
      filter: filter,
      take: pageSize,
      pageNumber: pageNumber
    });
  }, [filter, run]);

  const updateSearchInputValue = (evt) => {
    return setSearchInput(evt.target.value);
  };

  const onChangePage = useCallback(
    async (newPage: number) => {
      setpageNumber(newPage);
      setLastAction("SEARCH");
      return run("SEARCH", {
        filter,
        sortprops: sortprops,
        searchInput,
        take: pageSize,
        pageNumber: newPage,
      });
    },
    [filter, run, searchInput, pageSize]
  );

  /** Action functions */
  // const _addAction = useCallback(async () => {
  //   setAddLoading(true);
  //   try {
  //     await addAction({
  //       history,
  //       reload: () => {
  //         if (isMounted.current) setAddLoading(false);
  //         return run("SEARCH");
  //       },
  //     });
  //   } catch (e) {
  //     setError(e);
  //   }
  // }, [addAction, history, run, setError]);

  const _statusChangeAction = useCallback(
    async (event) => {
      setStatus(event.target.value);
      setLastAction("FILTER");
      setFilterValues(event.target.value);
      let statusvalue = event.target.value;
      setFilter({ ...filter, status: statusvalue });
      return run("FILTER", {
        filter: { ...filter, status: statusvalue },
        sortprops: sortprops,
        searchInput: searchInput,
        take: pageSize,
      });
    },
    [filter, run, searchInput]
  );


  const _daterangeChangeAction = useCallback(async (tempdt1, tempdt2) => {
    setLastAction("FILTER");
    setFilter({
      ...filter,
      startDate: tempdt1,
      endDate: tempdt2,
    });
    return run("FILTER", {
      filter: {
        ...filter,
        startDate: tempdt1,
        endDate: tempdt2,
      },
      searchInput: searchInput,
      take: pageSize,
    });
  }, [filter, run, searchInput, startdateformatted, enddateformatted]);

  const _daterangeresetAction = useCallback(
    async (startdatefrmtd, enddatefrmtd) => {
      setLastAction("FILTER");
      setFilter({
        ...filter,
        startDate: startdatefrmtd,
        endDate: enddatefrmtd,
      });
      return run("FILTER", {
        filter: { ...filter, startDate: startdatefrmtd, endDate: enddatefrmtd },
        searchInput: searchInput,
        take: pageSize,
      });
    },
    [filter, run, searchInput]
  );

  const _drillDownAction = useCallback(
    (entity) =>
      drillDownAction
        ? async () => {
            setLastAction("DRILLDOWN");
            return drillDownAction({ entity, history });
          }
        : null,
    [drillDownAction, history]
  );

  const _uploadappointmentsAction = useCallback(
    (entity) =>
      uploadappointmentsAction
        ? async () => {
            setLastAction("EDIT");
            return uploadappointmentsAction({ entity, history });
          }
        : null,
    [uploadappointmentsAction, history]
  );

  const _editAction = useCallback(
    (entity) =>
      editAction
        ? async () => {
            setLastAction("EDIT");
            return editAction({ entity, history });
          }
        : null,
    [editAction, history]
  );

  // const reloadAction = useCallback(async () => {
  //   setSearchInput("");
  //   setLastAction("SEARCH");
  //   return run("SEARCH", {
  //     searchInput: "",
  //     filter: filter
  //   });
  // }, [filter, run]);

  const _deleteAction = useCallback(
    (entity, entities) =>
      deleteAction
        ? async () => {
            if (
              window.confirm("Are you sure you want to delete this record?") // NOSONAR
            ) {
              setLastAction("DELETE");
              return deleteAction({ entity, history });
            }
            return;
          }
        : null,
    [deleteAction, filter, run, searchInput, setData]
  );

  const _toggleAction = useCallback(
    (entity, entities) =>
      inlineEditAction
        ? async () => {
            setLastAction("ENABLE");
            // setData({
            //   data: entities.data.map(x => {
            //     if (
            //       (x.id && x.id === entity.id) ||
            //       (x.key && x.key === entity.key)
            //     ) {
            //       x[toggleField] = !entity[toggleField];
            //       return x;
            //     }
            //     return x;
            //   }),
            //   meta: entities.meta
            // });
            return run("ENABLE", {
              filter,
              entity,
              searchInput,
              pageNumber: entities.meta && entities.meta.currentPage,
            });
          }
        : null,
    [filter, inlineEditAction, run, searchInput, setData, toggleField]
  );

  const _favoriteAction = useCallback(
    (entity, entities) =>
      inlineEditAction
        ? async () => {
            setLastAction("FAVORITE");
            setData({
              data: entities.data.map((x) => {
                if (x.id === entity.id) {
                  x[favoriteField] = !entity[favoriteField];
                  return x;
                }
                return x;
              }),
              meta: entities.meta,
            });
            return run("FAVORITE", {
              filter,
              entity,
              searchInput,
              pageNumber: entities.meta && entities.meta.currentPage,
            });
          }
        : null,
    [favoriteField, filter, inlineEditAction, run, searchInput, setData]
  );

  const _sortAction = useCallback(
    (entities) =>
      sortAction
        ? async ({ oldIndex, newIndex }) => {
            setLastAction("SORT");
            const newEntities = move(entities.data, oldIndex, newIndex);
            setData({ data: newEntities, meta: entities.meta });
            return run("SORT", {
              filter,
              entities: newEntities,
              searchInput,
              pageNumber: entities.meta && entities.meta.currentPage,
            });
          }
        : null,
    [filter, run, searchInput, setData, sortAction]
  );


  const _sortHeaderAction = useCallback(
    async (prps) => { 
      setsortprops(prps);   
      setLastAction("FILTER");
      return run("FILTER", {
        filter,
        sortprops: prps,
        searchInput,
        take: pageSize,
        pageNumber: pageNumber
      });
    },
    [filter, run, searchInput]
  );

  const handlepopoveropenClick = (event) => {
    setisSelectorOpen(true);
    setAnchorEl(event.currentTarget);
  };

  const handlestoppropogationChange = (event) => {
    event.stopPropagation();
    // set your value
  };

  const handleStartDateChange = (date) => {
    const pattern = "YYYY-MM-DDT00:00:00.000";
    setstartDate(date);
    let tempdt = moment(startOfDay(date)).format(pattern);
    setstartDateformatted(tempdt);
  };

  const handleEndDateChange = (date) => {
    const eodpattern = "YYYY-MM-DDT23:59:00.000";
    setendtDate(date);
    let tempdt2 = moment(endOfDay(date)).format(eodpattern);
    setenddateformatted(tempdt2);
  };

  const handlepopoverokayclick = () => {
    const pattern = "YYYY-MM-DDT00:00:00.000";
    const eodpattern = "YYYY-MM-DDT23:59:00.000";
    if (startDate === null || endDate === null) {
      setinvaliddatealertmessage("Invalid Date Time");
      setinvaliddatealert(true);
    } else if  (startDate > endDate) {
      setinvaliddatealertmessage("Start date should not be greater than End date");
      setinvaliddatealert(true);
    }
    else if (startDate !== null && endDate !== null) {
      setinvaliddatealert(false);
      let tempdt1 = moment(startDate).format(pattern);
      let tempdt2 = moment(endDate).format(eodpattern);
      setstartDateformatted(tempdt1);
      setenddateformatted(tempdt2);
      setdateselectionmenuitemtext(
        `${moment(startDate).format("MM-DD-YYYY")} - ${moment(endDate).format(
          "MM-DD-YYYY"
        )}`
      );
      setisSelectorOpen(false);
      setAnchorEl(null);
      _daterangeChangeAction(tempdt1, tempdt2);      
    }
  };

  const handlepopovercancelclick = () => {
    setinvaliddatealert(false);
    setstartDate(new Date());
    setendtDate(new Date());
    setisSelectorOpen(false);
    setAnchorEl(null);
  };

  const handlepopoverresetclick = () => {
    setinvaliddatealert(false);
    setstartDate(new Date());
    setendtDate(new Date());
    setstartDateformatted("");
    setenddateformatted("");
    setdateselectionmenuitemtext("Select date range");
    setisSelectorOpen(false);
    setAnchorEl(null);
    _daterangeresetAction();
  };


  // const _sortHeaderAction = async (prps) => {
  //   setLastAction("SORT");
  //   console.log("sort props",prps);

  //   return run("SORT", {
  //     filter: prps,
  //     searchInput: "",
  //     take: pageSize,
  //   });
  // };

  
  const _statusChangeActionmultiselect = useCallback(
    async (data) => {
      setLastAction("FILTER");
      setFilterValues(data);
      let statusvalue = data;
      setFilter({ ...filter, status: statusvalue });
      return run("FILTER", {
        filter: {
          ...filter,        
          status: statusvalue,         
        },
        searchInput: searchInput,
      });
      // setstatusvaluedata(statusvalue);
    },
    [filter, run, searchInput]
  );


  const handleOptionToggle = (event) => {
    let newValue = [...newvals];
    if (event.target.checked) {
      if(event.target.value === "100"){
        newValue = ["100","1", "2", "3", "4", "5", "6", "7", "8", "10", "11"];
      }
      else{
      newValue.push(event.target.value);
      }
    } else {
      if(event.target.value === "100"){
        newValue = [];
      }
      else{
      newValue = newValue.filter((item) => (item !== event.target.value &&  item !== "100"));
      }
    }
    setnewvals(newValue);
    let newstatusarray = statusList.filter(x => newValue.includes(x.id.toString()));
    setnewvalsname(newstatusarray.map(x => x.displayName).join());
    let strigifynewvalue = newValue.join();
    _statusChangeActionmultiselect(strigifynewvalue);
  };

  return (
    <>
      <Container maxWidth="xl" className={classes.listcontainer}>
        <div className={classes.all_apptContainer}>
          {addAction || title}
          <>
            {searchEnabled && (
              <div className="">
                <Grid
                  alignItems="center"
                  spacing={0}
                  container
                  justify="space-between"
                  className={classes.bg_white1}
                  style={{ display: "-ms-flexbox" }}
                >
                  <Grid
                    item
                    className={
                      mediawidth === "xl" || mediawidth === "lg"
                        ? ""
                        : classes.titletablet
                    }
                  >
                    <div className="telekardia">
                      {/*<Body bold>All {feature}</Body>*/}
                      {/*<BottomLineTitle />*/}
                      <h5 className="caption">All Consultations</h5>
                    </div>
                  </Grid>
                  <Grid item className={classes.searchbarrootmain}>
                    <Paper
                      component="form"
                      className={
                        mediawidth === "xl"
                          ? classes.searchbarroot
                          : mediawidth === "lg"
                          ? classes.searchbarroottabletlandscape
                          : mediawidth === "md"
                          ? classes.searchbarroottablet
                          : classes.searchbarroottabletipad
                      }
                      style={{ display: "-ms-flexbox" }}
                    >
                      <InputBase
                        className={
                          mediawidth === "xl" || mediawidth === "lg"
                            ? classes.searchinputclass
                            : classes.largeinputtablet
                        }
                        placeholder="Search Patient Name"
                        inputProps={{ "aria-label": `Search ${feature}` }}
                        value={searchInput}
                        onKeyPress={(event) => {
                          if (event.key === "Enter") {
                            event.preventDefault();
                            onSearchClick(); // here was the mistake
                          }
                        }}
                        onChange={(e) => updateSearchInputValue(e)}
                      />
                      <IconButton
                        className={classes.iconButton}
                        onClick={onSearchClick}
                        aria-label="search"
                      >
                        <SearchIcon />
                      </IconButton>

                      {/* <Divider className={classes.divider} orientation="vertical" />
                      <IconButton
                          className={classes.iconButton}
                          onClick={onClear}
                          aria-label="clear"
                      >
                        <ClearSharp />
                      </IconButton> */}
                    </Paper>
                  </Grid>
                  <Grid item>
                    <InputLabel className={classes.formControllabelstyle}>
                      Status
                    </InputLabel>
                    <FormControl
                      variant="outlined"
                      className={
                        mediawidth === "xl" || mediawidth === "lg"
                          ? classes.formControl
                          : classes.formControlsmall
                      }
                    >
                      <Select
                        className="not-rounded"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        // value={status}
                        value={newvals}
                        renderValue={(selected) =>
                          selected.length === statusList.length ||
                          statusList.length === 0 ? (
                            <MuiTypography
                              fontFamily="Work Sans"
                              fontSize="16px"
                              fonStyle="normal"
                              fonWeight="400"
                              lineHeight="20px"
                              color="#142A39"
                            >
                              All
                            </MuiTypography>
                          ) : (
                            <div>{newvalsname.slice(0, 20)}...</div>
                          )
                        }
                        multiple
                        MenuProps={{
                          getContentAnchorEl: null,
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "center",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "center",
                          },
                        }}
                        // input={<BootstrapInput />}
                        input={
                          <OutlinedInput
                            labelWidth={0}
                            name="status"
                            id="outlined-status-simple"
                            classes={outlinedInputClasses}
                          />
                        }
                        // onChange={_statusChangeAction}
                      >
                        {statusList &&
                          statusList.length > 0 &&
                          statusList.map((item, index) => (
                            <MenuItem value={item.id.toString()} key={index}>
                              <FormControlLabel
                                // className={classes.formControlLabel}
                                control={
                                  <GreenCheckbox
                                    checked={
                                      newvals.indexOf(item.id.toString()) > -1
                                    }
                                    color="primary"
                                    inputProps={{
                                      "aria-label": `Checkbox ${item.id}`,
                                    }}
                                    onClick={(event) =>
                                      handleOptionToggle(event)
                                    }
                                    // value={}
                                    value={
                                      item.id.toString()
                                        ? item.id.toString()
                                        : " "
                                    }
                                  />
                                }
                                label={
                                  <MuiTypography
                                    fontFamily="Work Sans"
                                    fontSize="16px"
                                    fonStyle="normal"
                                    fonWeight="400"
                                    lineHeight="20px"
                                    color="#142A39"
                                  >
                                    {item.displayName}
                                  </MuiTypography>
                                }
                              />
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item>
                    <InputLabel className={classes.formControllabelstyle}>
                      Date range
                    </InputLabel>
                    <FormControl
                      variant="outlined"
                      className={
                        mediawidth === "xl" || mediawidth === "lg"
                          ? classes.formControl
                          : classes.formControlsmall
                      }
                    >
                      <Select
                        labelId="demo-simple-select-label-3"
                        id="demo-simple-select-3"
                        value={"0"}
                        MenuProps={{
                          getContentAnchorEl: null,
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "center",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "center",
                          },
                        }}
                        renderValue={(value) => (
                          <MuiTypography
                            fontFamily="Work Sans"
                            fontSize="16px"
                            fonStyle="normal"
                            fonWeight="400"
                            lineHeight="20px"
                            color="#142A39"
                          >
                            {dateselectionmenuitemtext}
                          </MuiTypography>
                        )}
                        input={
                          <OutlinedInput
                            labelWidth={0}
                            name="status"
                            id="outlined-status-simple"
                            classes={outlinedInputClasses}
                          />
                        }
                        open={isSelectorOpen}
                        onChange={handlestoppropogationChange}
                        onOpen={handlepopoveropenClick}
                      >
                        <MenuItem value={"1"}>
                          {" "}
                          <Popover
                            id={popoverid}
                            open={popoveropen}
                            anchorEl={anchorEl}
                            // onClose={handlepopoverClose}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "left",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "left",
                            }}
                          >
                            <Grid
                              container
                              justify="flex-end"
                              direction="column"
                              className={classes.popovergrid}
                            >
                              <div className={classes.alertroot}>
                                <Collapse in={invaliddatealert}>
                                  <Alert
                                    color="inherit"
                                    icon={false}
                                    action={
                                      <IconButton
                                        aria-label="close"
                                        color="inherit"
                                        size="small"
                                        onClick={() => {
                                          setinvaliddatealert(false);
                                        }}
                                      >
                                        <CloseIcon fontSize="default" />
                                      </IconButton>
                                    }
                                  >
                                    <AlertTitle>
                                      {invaliddatealertmessage}
                                    </AlertTitle>
                                  </Alert>
                                </Collapse>
                              </div>
                              <Grid item>
                                <MuiTypography
                                  fontSize="12px"
                                  fontFamily="Work Sans"
                                  lineHeight="16px"
                                  letterSpacing="0.4px"
                                  color="rgba(20, 42, 57, 0.72)"
                                >
                                  {t("Start Date")}
                                </MuiTypography>
                                <Datepicker
                                  label="Start Date"
                                  date={startDate}
                                  changeHandle={handleStartDateChange}
                                />
                              </Grid>
                              <Grid item>
                                <MuiTypography
                                  fontSize="12px"
                                  fontFamily="Work Sans"
                                  lineHeight="16px"
                                  letterSpacing="0.4px"
                                  color="rgba(20, 42, 57, 0.72)"
                                >
                                  {t("End Date")}
                                </MuiTypography>
                                <Datepicker
                                  label="End Date"
                                  date={endDate}
                                  changeHandle={handleEndDateChange}
                                />
                                <Divider />
                              </Grid>

                              <Grid
                                item
                                container
                                justify="space-between"
                                alignItems="center"
                                direction="row"
                              >
                                <Grid item>
                                  <Button onClick={handlepopovercancelclick}>
                                    <MuiTypography
                                      fontFamily="Work Sans"
                                      fontSize="14px"
                                      fonStyle="normal"
                                      fontWeight={700}
                                      lineHeight="16.42px"
                                      letterSpacing="0.75 px"
                                      color="#142A39"
                                    >
                                      {t("CANCEL")}
                                    </MuiTypography>
                                  </Button>
                                </Grid>
                                <Grid item>
                                  <Button onClick={handlepopoverresetclick}>
                                    <MuiTypography
                                      fontFamily="Work Sans"
                                      fontSize="14px"
                                      fonStyle="normal"
                                      fontWeight={700}
                                      lineHeight="16.42px"
                                      letterSpacing="0.75 px"
                                      color="#2D9F86"
                                    >
                                      {t("RESET")}
                                    </MuiTypography>
                                  </Button>
                                </Grid>
                                <Grid item>
                                  <div className="telekardia">
                                    <button
                                      type="button"
                                      className="btn m-1 btn_teal py-2 px-4"
                                      onClick={handlepopoverokayclick}
                                    >
                                      {t("OK")}
                                    </button>
                                  </div>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Popover>
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </div>
            )}
          </>
          <Grid container spacing={0}>
            <Paper className={classes.listroot}>
              {isLoading && ["DELETE", "SORT", "ENABLE"].includes(lastAction) && (
                <Note>
                  <EcgLoader />
                </Note>
              )}
              <IfPending state={listState} initial={true}>
                <Container>
                  <Spacing padding="80px">
                    <EcgLoader />
                  </Spacing>
                </Container>
              </IfPending>

              {isLoading && ["SEARCH", "FILTER"].includes(lastAction) && (
                <Container>
                  <Spacing padding="80px">
                    <EcgLoader />
                  </Spacing>
                </Container>
              )}

              {/*Display when there was an error with data*/}
              <IfRejected state={listState}>
                {(
                  error // NOSONAR
                ) => (
                  <Flex justify="center">
                    <Flex
                      direction="column"
                      justify="center"
                      textAlign="center"
                    >
                      <FlexItem>
                        <Spacing padding="50px">
                          {userError(error, lastAction)}
                        </Spacing>
                      </FlexItem>
                    </Flex>
                  </Flex>
                )}
              </IfRejected>

              {/*Display when data was retrieved, persist for optimistic updates*/}
              <IfFulfilled state={listState} persist={true}>
                {(entities) =>
                  (entities.data.length === 0 && (
                    <Flex justify="center">
                      <Spacing padding="20px">{"No Data"}</Spacing>
                    </Flex>
                  )) ||
                  ((!isLoading || !["SEARCH", "FILTER"].includes(lastAction)) &&
                    !error && (
                      <>
                        <div className="telekardia">
                          <ListTable
                            feature="Clinics"
                            {...props}
                            onSortEnd={_sortAction(entities)}
                            headings={headers}
                            sortordertype={sortordertype}
                            sortPropsName={sortPropsName}
                            sortHeaderAction={_sortHeaderAction}
                            items={entities.data.map((entity, index) => ({
                              entityValues: entityProps.map((prop) =>
                                render && render[prop]
                                  ? render[prop](get(entity, prop))
                                  : get(entity, prop)
                              ),
                              toggleChecked:
                                toggleField && inlineEditAction
                                  ? entity[toggleField] ||
                                    typeof entity[toggleField] === "undefined"
                                  : null,
                              favoriteChecked:
                                favoriteField && inlineEditAction
                                  ? !!entity[favoriteField]
                                  : null,

                              drillDownAction: _drillDownAction(entity),
                              uploadappointmentsAction:
                                _uploadappointmentsAction(entity),
                              editAction: _editAction(entity),
                              deleteAction: _deleteAction(entity, entities),
                              onToggleChange: _toggleAction(entity, entities),
                              onFavoriteChange: _favoriteAction(
                                entity,
                                entities
                              ),
                              isLoading,
                            }))}
                          />
                        </div>
                        <Flex justify="flex-end">
                          <MaterialPagination
                            pageSize={pageSize}
                            current={
                              entities &&
                              entities.meta &&
                              entities.meta.currentPage
                            }
                            total={
                              entities && entities.meta && entities.meta.count
                            }
                            onChange={onChangePage}
                            onSizeChange={onSizeChange}
                          />
                        </Flex>
                      </>
                    ))
                }
              </IfFulfilled>
            </Paper>
            {/* </Grid> */}
          </Grid>
        </div>
      </Container>
    </>
  );
};

export default ListPage;
