import React, { useState, useEffect, useLayoutEffect } from "react";
import ChatBubbleIcon from "@material-ui/icons/ChatBubble";
import SendIcon from "@material-ui/icons/Send";
import useTimer from "../../../hooks/useTimer";
import { formatTime } from "../../../Utilities/Utilities";
import EndConsultationPopUp from "./EndConsultationPopUp";
import * as API from "./../../../Services/API/actions";
import { useSelector } from "react-redux";
import NexmoClient from "nexmo-client";
import moment from "moment";
// import {Divider } from 'semantic-ui-react'
import { useTranslation } from "react-i18next";
import OfflinePopup from "./OfflinePopup";
import MuiDivider from "../../Shared/MuiDivider/MuiDivider";
import { Grid } from "@material-ui/core";
import PatientDeclinePopUp from "./PatientDeclinePopUp";
import SockJsClient from "react-stomp";
import { getToken } from "../../../Auth/AuthUtilities";
import Spinner from "../../Shared/Spinner/Spinner";
import useDoctorchatTimer from "../../../hooks/useDoctorchatTimer";
import usePatientchatTimer from "../../../hooks/usePatientchatTimer";
import PatientTechicalIssueChat from "./PatientTechicalIssueChat";
import apiservice from "../../../api/services/apiservice.service";
import { streamToPromise } from "../../../Utilities/Utilities";
import PatientDidntjoinCallPopup from "./PatientDidntjoinCallPopup";
import useJoiningTimer from "../../../hooks/useJoiningTimer";

const useNetwork = (onChange) => {
  const [status, setStatus] = useState(navigator.onLine);
  const handleChange = () => {
    if (typeof onChange === "function") {
      onChange(navigator.onLine);
    }
    setStatus(navigator.onLine);
  };
  useEffect(() => {
    window.addEventListener("online", handleChange);
    window.addEventListener("offline", handleChange);
    return () => {
      window.removeEventListener("online", handleChange);
      window.removeEventListener("offline", handleChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return status;
};

function Chat(props) {
  const { t } = useTranslation();
  const [offlinealertopen, setofflinealertopen] = React.useState(false);
  const [, setnetworkstatus] = useState("online");
  const [patientdeclinemodal, setpatientdeclinemodal] = useState(false);
  const clinicianId = props.clinicianId;
  const appointmentId = props.appointmentId;
  const customHeaders = {
    Authorization: `Bearer ${getToken()}`,
  };

  const {
    doctorchattimer,
    handledoctorchattimerStart,
    handledoctorchattimerReset,
  } = useDoctorchatTimer(0);

  const {
    patientchattimer,
    handlepatientchattimerStart,
    handlepatientchattimerReset,
  } = usePatientchatTimer(0);

  const handleNetworkChange = (online) => {
    online ? setofflinealertopen(false) : setofflinealertopen(true);
    online ? setnetworkstatus("online") : setnetworkstatus("We just offline");
  };
  const onLine = useNetwork(handleNetworkChange);
  //Generates the click handler, which returns a promise that resovles to the provided url.
  // eslint-disable-next-line
  const generateAsyncUrlGetter =
    (url, timeout = 2000) =>
    () => {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(url);
        }, timeout);
      });
    };
  const closeofflinepopup = () => {
    setofflinealertopen(false);
  };
  const [endcallconfirmationpopup, setendcallconfirmationpopup] =
    useState(false);
  const { timer, handleStart, handleReset } = useTimer(0);
  const [chatstatus, setchatstatus] = useState("disconnected");
  const [message, setmessage] = useState("");
  const [loadedMessages, setloadedMessages] = useState([]);
  const [, settoken] = useState(null);
  const [, setconversationId] = useState(null);
  const [disable, setdisable] = useState(true);
  const teamId = useSelector((state) => state.user.team.id);
  const profileId = useSelector((state) => state.user.profile.id);
  const providerfirstname = useSelector(
    (state) => state.user.profile.firstName
  );
  const providerlastname = useSelector((state) => state.user.profile.lastName);
  const [conversation, setconversation] = useState(null);
  const [loader, setloader] = useState(false);
  const [typing, settyping] = useState(false);
  const messageFeed = React.useRef(null);
  const textArea = React.useRef(null);
  const [, setismemberJoined] = useState(false);
  const [finalcounttimer, setfinalcounttimer] = useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [patientdidntjointimeout, setpatientdidntjointimeout] = useState(false);

  const { joiningtimer, handlejoiningStart, handlejoiningReset } =
    useJoiningTimer(0);

  useEffect(() => {
    if (joiningtimer === 120) {
      setpatientdidntjointimeout(true);
      notifyUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [joiningtimer]);

  useEffect(() => {
    if (chatstatus !== "chat end") {
      setfinalcounttimer(timer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timer]);

  const hidepatientdeclinemodal = () => {
    setpatientdeclinemodal(false);
    handleReset();
    setismemberJoined(false);
    setloader(true);
    setchatstatus("chat end");
    props.disableHandler(false);
  };

  const showstompmessage = (msg) => {
    setpatientdeclinemodal(true);
  };

  useLayoutEffect(() => {
    props.disableHandler(true);
    API.chatToken(teamId, profileId, props.appointmentId).subscribe(
      async (res) => {
        if ("detailed_error" in res.data) {
          console.log(res.data["detailed_error"]);
        } else {
          await run(res.data["result"].token, res.data["result"].convId);
          settoken(res.data["result"].token);
          setconversationId(res.data["result"].convId);
        }
      },
      (error) => {
        console.log(error);
      }
    );
    return () => {
      streamToPromise(
        apiservice.postapiservice({
          baseUrl: `api/v1/teams/${teamId}/clinicians/${clinicianId}/appointments/${appointmentId}/chat/archive`,
        })
      );
    };
  }, []);
  useEffect(() => {
    if (chatstatus !== "disconnected" && doctorchattimer === 30) {
      conversation
        .sendCustomEvent({
          type: "doctor_keep_alive",
          body: { your: "doctor_keep_alive" },
        })
        .then((custom_event) => {
          handledoctorchattimerReset();
          handledoctorchattimerStart();
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doctorchattimer]);

  const [patientjoiningtimeout, setpatientjoiningtimeout] = useState(false);

  useEffect(() => {
    if (chatstatus !== "disconnected" && patientchattimer === 60) {
      setpatientjoiningtimeout(true);
      notifyUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientchattimer]);

  useEffect(() => {
    if (chatstatus !== "disconnected") {
      const delayDebounceFn = setTimeout(() => {
        conversation
          .sendCustomEvent({
            type: "doctor_stop_typing",
            body: { your: "doctor_stop_typing" },
          })
          .then((custom_event) => {
            handledoctorchattimerReset();
            handledoctorchattimerStart();
          });
      }, 8000);

      return () => clearTimeout(delayDebounceFn);
    }
  }, [message]);

  useEffect(() => {
    if (loadedMessages.length > 0 && conversation) {
      setupConversationEvents(conversation);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadedMessages, conversation]);
  useEffect(() => {
    const { current } = messageFeed;
    if (current && loader) {
      current.scrollTop = current.scrollHeight;
      setloader(false);
    }
  }, [loader]);
  async function run(token, convId) {
    new NexmoClient({ debug: true })
      .login(token)
      .then((app) => {
        return app.getConversation(convId);
      })
      .then((conversation) => {
        setupConversationEvents(conversation);
        setconversation(conversation);
        showConversationHistory(conversation);
      })
      .then(() => {
        handlejoiningStart();
        streamToPromise(
          apiservice.postapiservice({
            baseUrl: `api/v1/teams/${teamId}/clinicians/${clinicianId}/appointments/${appointmentId}/notify`,
          })
        );
      })
      .catch((err) => console.log(err));
  }
  const getNextEvents = async (events) => {
    return await events.getNext();
  };

  const memberJoined = (event, conv) => {
    const rawDate = new Date(
      Date.parse(event.timestamp.joined || event.timestamp)
    );
    const formattedDate = moment(rawDate).format("HH:mm:ss A");
    const displayDate = moment(rawDate).format("LT");
    let text = "Joined";
    if (event.from === conv.me.id) {
      return {
        text: text,
        sender: "doctor",
        dateTime: formattedDate,
        displayDate: displayDate,
      };
    } else {
      return {
        text: text,
        sender: "patient",
        dateTime: formattedDate,
        displayDate: displayDate,
      };
    }
  };
  const memberLeft = (event, conv) => {
    const rawDate = new Date(Date.parse(event.timestamp));
    const formattedDate = moment(rawDate).format("HH:mm:ss A");
    const displayDate = moment(rawDate).format("LT");
    let text = "Left";
    if (event.from === conv.me.id) {
      return {
        text: text,
        sender: "doctor",
        dateTime: formattedDate,
        displayDate: displayDate,
      };
    } else {
      return {
        text: text,
        sender: "patient",
        dateTime: formattedDate,
        displayDate: displayDate,
      };
    }
  };

  const setupConversationEvents = (conversation) => {
    conversation.on("member:joined", (event) => {
      if (loadedMessages.length > 0) {
        let messages = loadedMessages;
        const message = memberJoined(event, conversation);
        messages.push(message);
        setloadedMessages(messages);
        setismemberJoined(true);
        setloader(true);
        handleReset();
        handleStart();
        setchatstatus("member joined");
        handlejoiningReset();
        handlepatientchattimerReset();
        handlepatientchattimerStart();
      }
    });
    conversation.on("member:left", (event) => {
      if (loadedMessages.length > 0) {
        let messages = loadedMessages;
        const message = memberLeft(event, conversation);
        messages.push(message);
        setloadedMessages(messages);
        setismemberJoined(false);
        setloader(true);
        setchatstatus("chat end");
        handlejoiningReset();
        handlepatientchattimerReset();
      }
    });
    conversation.on("patient_left", (from, event) => {
      if (loadedMessages.length > 0) {
        
        var patient_leftmessages = loadedMessages;
        const message = memberLeft(event, conversation);
        let doctorleftmessage = {
          text: "Left",
          sender: "doctor",
          dateTime: message.formattedDate,
          displayDate: message.displayDate,
        };
        if (
          loadedMessages.filter(
            (x) => x.text === "Left" && x.sender === "patient"
          ).length === 0
        ) {
          patient_leftmessages.push(doctorleftmessage);
          patient_leftmessages.push(message);
          
        }
        else{
          patient_leftmessages.push(message);
          patient_leftmessages.push(doctorleftmessage);
        }

        handlejoiningReset();
        setloadedMessages(patient_leftmessages);
        setismemberJoined(false);
        setloader(true);
        setchatstatus("chat end");
        props.disableHandler(false);
        handlepatientchattimerReset();
      }
    });
    conversation.on("doctor_left", (from, event) => {
      
      if (loadedMessages.length > 0) {
        let messages = loadedMessages;
        // const message = memberLeft(event, conversation);
        // messages.push(message);
        // let doctorleftmessage =  {
        //   text: "Left",
        //   sender: "patient",
        //   dateTime: message.formattedDate,
        //   displayDate: message.displayDate,
        // };
        // messages.push(doctorleftmessage);
        setloadedMessages(messages);
        setismemberJoined(false);
        setloader(true);
        setchatstatus("chat end");
        props.disableHandler(false);
      }
    });
    conversation.on("text:seen", (data, event) => {
      if (loadedMessages.length > 0) {
        let messages = loadedMessages;
        let lstmessage = messages[messages.length - 1];
        lstmessage.status = "seen";
        messages[messages.length - 1] = lstmessage;
        setloadedMessages(messages);
        setloader(true);
      }
    });
    conversation.on("text:delivered", (data, event) => {
      if (loadedMessages.length > 0) {
        let messages = loadedMessages;
        let lstmessage = messages[messages.length - 1];
        lstmessage.status = "delivered";
        messages[messages.length - 1] = lstmessage;
        setloadedMessages(messages);
        setloader(true);
        if (
          (messageFeed.current.scrollTop =
            messageFeed.current.scrollHeight && conversation.me.id !== event.id)
        )
          event.seen();
      }
    });
    conversation.on("text", (sender, event) => {
      if (loadedMessages.length > 0) {
        const formattedMessage = formatMessage(sender, event, conversation);
        let messages = loadedMessages;
        messages.push(formattedMessage);
        setloadedMessages(messages);
        setloader(true);
        if (event.from !== conversation.me.id) {
          handlejoiningReset();
          handlepatientchattimerReset();
          handlepatientchattimerStart();
        }
      }
    });
    conversation.on("text:typing:on", (data) => {
      if (data.user.id !== data.conversation.me.user.id) {
        // if (chatstatus !== "member joined") {
        //   handleReset();
        //   handleStart();
        //   setchatstatus("member joined");
        // }
        handlejoiningReset();
        settyping(true);
        handlepatientchattimerReset();
        handlepatientchattimerStart();
      }
    });
    conversation.on("text:typing:off", (data) => {
      if (data.user.id !== data.conversation.me.user.id) {
        handlejoiningReset();
        settyping(false);
        handlepatientchattimerReset();
        handlepatientchattimerStart();
      }
    });
    conversation.on("patient_keep_alive", (from, event) => {
      // if (chatstatus !== "member joined") {
      //   handleReset();
      //   handleStart();
      //   setchatstatus("member joined");
      // }
      handlejoiningReset();
      handlepatientchattimerReset();
      handlepatientchattimerStart();
    });
    conversation.on("patient_joined", (from, event) => {
      handleReset();
      handleStart();
      setchatstatus("member joined");
    });
  };
  const getAllEvents = (allEvents, conversation) => {
    let eventsHistory = [];
    allEvents.items.forEach((value, key) => {
      //here filtering
      if (conversation.members.get(value.from)) {
        // const date = new Date(Date.parse(value.timestamp));
        switch (value.type) {
          case "member:joined":
            // if(value.from!==conversation.me.id)
            // {
            const message = memberJoined(value, conversation);
            eventsHistory.push(message);
            // }
            break;
          case "member:left":
            if (value.from !== conversation.me.id) {
              const message1 = memberLeft(value, conversation);
              eventsHistory.push(message1);
            }
            break;
          case "text":
            const message2 = formatMessage(
              conversation.members.get(value.from),
              value,
              conversation
            );
            // if(value.from!==conversation.me.id && Object.keys(value.state.delivered_to).length===0){
            // value.seen();
            // }
            eventsHistory.push(message2);
            break;
          // case "doctor_joined":
          //   const message3 = memberJoined(value, conversation);
          //   eventsHistory.push(message3);
          //   break;
          // case "patient_joined":
          //   const message4 = memberJoined(value, conversation);
          //   eventsHistory.push(message4);
          //   break;
          default:
            console.log("unknown behaviour");
            break;
        }
      }
    });
    return eventsHistory;
  };
  const showConversationHistory = (conversation) => {
    conversation
      .getEvents({ page_size: 10, order: "desc" })
      .then(async (events_page) => {
        let allEvents = [...getAllEvents(events_page, conversation)];
        while (events_page.hasNext()) {
          await getNextEvents(events_page).then((moreEvents) => {
            events_page = moreEvents;
            let loadMore = getAllEvents(moreEvents, conversation);
            allEvents = [...allEvents, ...loadMore];
          });
        }
        setloadedMessages(allEvents.reverse());
        conversation
          .sendCustomEvent({ type: "doctor_joined", body: { your: "data" } })
          .then((custom_event) => {
            handledoctorchattimerReset();
            handledoctorchattimerStart();
            // handlepatientchattimerReset();
            // handlepatientchattimerStart();
          });
        // handleReset();
        // handleStart();
        setchatstatus("chat connected");
        setdisable(false);
      })
      .catch((err) => console.log(err));
  };

  const formatMessage = (sender, message, conversation) => {
    const rawDate = new Date(Date.parse(message.timestamp));
    const formattedDate = moment(rawDate).format("HH:mm:ss A");
    const displayDate = moment(rawDate).format("LT");
    let text = "";
    let status = "sent";
    text = message.body.text;
    if (message.from !== conversation.me.id) {
      return {
        text: text,
        sender: "patient",
        dateTime: formattedDate,
        displayDate: displayDate,
        status: status,
      };
    } else {
      if (
        message.state.seen_by &&
        Object.keys(message.state.seen_by).length > 0
      ) {
        status = "seen";
      } else if (
        message.state.delivered_to &&
        Object.keys(message.state.delivered_to).length > 0
      ) {
        status = "delivered";
      }
      return {
        text: text,
        sender: "doctor",
        dateTime: formattedDate,
        displayDate: displayDate,
        status: status,
      };
    }
  };
  const continuesessionconfirmationclick = () => {
    setendcallconfirmationpopup(false);
  };
  const endsessionconfirmationclick = () => {
    // conversation.leave();
    // conversation.sendCustomEvent({ type: 'doctor_left', body: { your: 'data' }}).then((custom_event) => {
    //   console.log(custom_event);
    // });
    setfinalcounttimer(timer);
    conversation
      .sendCustomEvent({ type: "doctor_left", body: { your: "doctor_left" } })
      .then((custom_event) => {
        handledoctorchattimerReset();
        handlepatientchattimerReset();
      });
    streamToPromise(
      apiservice.postapiservice({
        baseUrl: `api/v1/teams/${teamId}/clinicians/${clinicianId}/appointments/${appointmentId}/notify/endcall`,
      })
    );
    handleReset();
    setendcallconfirmationpopup(false);
    props.disableHandler(false);
    // settoggle(!toggle);
    setchatstatus("chat end");
  };

  const toggleHandler = (event) => {
    // if(toggle){
    if (chatstatus === "chat end" || chatstatus === "disconnected") {
      // handleStart();
      props.disableHandler(true);
      streamToPromise(
        apiservice.postapiservice({
          baseUrl: `api/v1/teams/${teamId}/clinicians/${clinicianId}/appointments/${appointmentId}/notify`,
        })
      );
      // handleReset();
      // handleStart();
      // settoggle(!toggle);
      setchatstatus("chat connected");
      setloader(true);
      conversation
        .sendCustomEvent({ type: "doctor_joined", body: { your: "data" } })
        .then((custom_event) => {
          handledoctorchattimerReset();
          handledoctorchattimerStart();
        });
    } else {
      setAnchorEl(event.currentTarget);
      setendcallconfirmationpopup(true);
    }
  };

  const closePatientLeftCallPopup = () => {
    setfinalcounttimer(timer);
    conversation
      .sendCustomEvent({ type: "doctor_left", body: { your: "doctor_left" } })
      .then((custom_event) => {
        handledoctorchattimerReset();
        handlepatientchattimerReset();
      });
    handleReset();
    setendcallconfirmationpopup(false);
    props.disableHandler(false);
    setchatstatus("chat end");
    setpatientjoiningtimeout(false);
  };
const notifyUser=()=>{
  API.endCall(teamId, profileId,appointmentId).subscribe(
    (res) => {
      
    },
    (error) => {
      console.log(error);
    }
  );
}
  const closepatientmissedconsultaion = () => {
    setfinalcounttimer(0);
    conversation
      .sendCustomEvent({ type: "doctor_left", body: { your: "doctor_left" } })
      .then((custom_event) => {
        handledoctorchattimerReset();
        handlepatientchattimerReset();
      });
    handleReset();
    props.disableHandler(false);
    setchatstatus("chat end");
    setpatientdidntjointimeout(false);
  };

  const messageHandler = (e) => {
    conversation
      .sendCustomEvent({
        type: "doctor_start_typing",
        body: { your: "doctor_start_typing" },
      })
      .then((custom_event) => {
        handledoctorchattimerReset();
        handledoctorchattimerStart();
      });
    e.preventDefault();
    setmessage(e.target.value);
  };
  const enterPressed = (e) => {
    let code = e.keyCode || e.which;
    if (code === 13) {
      sentmessageHandler();
    }
  };
  const sentmessageHandler = () => {
    setmessage("");
    conversation
      .sendText(message)
      .then(() => {
        handledoctorchattimerReset();
        handledoctorchattimerStart();
      })
      .catch((err) => console.log(err));
  };
  // 
  // console.log(loadedMessages);
  return (
    <div className="row no-gutters">
      <div className="col-12 bg_white px-0 pt-0 mb-md-3 mb-xl-3">
        <div className="d-flex flex-row align-items-center chat_head pb-2 px-3 panel_head">
          {chatstatus === "chat connected" ? (
            //  !toggle &&
            <div className="d-flex flex-column f16 font_white align-content-center text-truncate">
              <div className="d-flex flex-row weight_600">
                Connecting {props.patientName.slice(0, 11)}...
              </div>
            </div>
            // <div className="d-flex flex-column f16 font_white align-content-center text-truncate">
            //   <div className="d-flex flex-row weight_600">
            //     Chat in progress...
            //     {/* <span className="pl-2" style={{maxWidth:"150px"}}>{props.patientName}</span>                                            */}
            //   </div>
            //   <div className="d-flex flex-row">
            //     <span className="font-weight-normal width110">
            //       {formatTime(timer)}
            //     </span>
            //   </div>
            // </div>
          ) : chatstatus === "member joined" ? (
            <div className="d-flex flex-column f16 font_white align-content-center text-truncate">
              <div className="d-flex flex-row weight_600">
                Chat in progress...
                {/* <span className="pl-2" style={{maxWidth:"150px"}}>{props.patientName}</span>                                            */}
              </div>
              <div className="d-flex flex-row">
                <span className="font-weight-normal width110">
                  {formatTime(timer)}
                </span>
              </div>
            </div>
          ) : (
            chatstatus === "chat end" && (
              <div className="d-flex flex-column f16 font_white align-content-center text-truncate">
                <div className="d-flex flex-row weight_600">Chat ended</div>
                <div className="d-flex flex-row">
                  <span className="font-weight-normal">
                    Total duration : {formatTime(finalcounttimer)}
                  </span>
                </div>
              </div>
            )
          )}
          {
            // chatstatus === "disconnected" ? (
            //   <button
            //     type="button"
            //     className="btn text-uppercase h40 ml-auto btn_green min-width163 position-relative"
            //     onClick={toggleHandler}
            //     disabled={disable}
            //   >
            //     <ChatBubbleIcon className="left_15 f30" /> {t("START CHAT")}
            //   </button>
            // ) :
            chatstatus === "chat connected" ||
            chatstatus === "member joined" ||
            chatstatus === "disconnected" ? (
              <button
                type="button"
                className="btn text-uppercase h40 ml-auto bg_red_darker min-width163 position-relative"
                onClick={toggleHandler}
                disabled={disable}
              >
                <ChatBubbleIcon className="left_15 f30" />{" "}
                <span className="d-inline-block ml-5">{t("END CHAT")}</span>
              </button>
            ) : (
              <button
                type="button"
                className="btn text-uppercase h40 ml-auto btn_white_fill min-width163 position-relative"
                onClick={toggleHandler}
              >
                <ChatBubbleIcon />
                <span className="d-inline-block ml-5">{t("Reconnect")}</span>
              </button>
            )
          }
        </div>
        <div className="d-flex flex-row align-items-center chat_head pb-2 px-3 chat_panel_head_white">
          <div className="d-flex flex-column f16 font_grey align-content-center text-truncate">
            <div className="d-flex flex-row weight_600">
              {props.patientName}
            </div>
          </div>
        </div>

        <div className="mesgs py-3 align-items-center" ref={messageFeed}>
          {chatstatus === "disconnected" ? (
            //  toggle?
            <div className="d-flex flex-column align-items-center justify-content-center">
              {/* <svg
                width="128"
                height="128"
                viewBox="0 0 128 128"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M89.6 32H38.4C34.88 32 32 34.88 32 38.4V96L44.8 83.2H89.6C93.12 83.2 96 80.32 96 76.8V38.4C96 34.88 93.12 32 89.6 32ZM63.8091 76.6798L61.128 74.2391C56.1051 69.6844 51.9825 65.9425 49.208 62.4001H52.8C53.3273 62.4001 53.8208 62.1403 54.1192 61.7055L55.4611 59.7501L57.8773 63.3003C58.1936 63.7651 58.7307 64.0298 59.292 63.9975C59.8533 63.9651 60.3565 63.6406 60.6173 63.1425L64.5787 55.5799L67.7874 67.6124C67.9677 68.2887 68.5668 68.7693 69.2661 68.7987C69.9655 68.8281 70.6028 68.3995 70.8392 67.7407L73.6589 59.8858H80.1627C77.5551 64.2001 72.7137 68.5957 66.4902 74.2391L63.8091 76.6798ZM81.6733 56.6858H72.5333C71.8581 56.6858 71.2556 57.1097 71.0274 57.7452L69.5589 61.8362L66.6126 50.7878C66.4427 50.1506 65.8993 49.6829 65.2438 49.6099C64.5884 49.5369 63.9553 49.8735 63.6493 50.4576L59.0211 59.2934L56.7894 56.0142C56.4921 55.5773 55.9982 55.3154 55.4698 55.3144C54.9413 55.3134 54.4465 55.5733 54.1474 56.009L51.9575 59.2001H47.0598C45.9299 57.1444 45.3188 55.0932 45.3188 52.9197C45.3188 47.2062 49.7934 42.75 55.4885 42.75C58.7058 42.75 61.7937 44.2477 63.8091 46.596C65.8246 44.2477 68.9125 42.75 72.1298 42.75C77.8248 42.75 82.2995 47.2062 82.2995 52.9197C82.2995 54.2093 82.0843 55.4558 81.6733 56.6858Z"
                  fill="#142A39"
                />
              </svg> */}
              {/* <p className="w-100 text-center">{`Start your conversation with ${props.patientName}`}</p> */}
              <Spinner></Spinner>
            </div>
          ) : (
            <div className="msg_history px-2">
              {loadedMessages.length > 0 &&
                loadedMessages.map((item, index) =>
                  item.text === "Joined" ? (
                    <MuiDivider key={index} spacing={1}>
                      <Grid
                        container
                        direction="column"
                        justify="space-between"
                        alignItems="center"
                      >
                        <Grid item>
                          <span className="weight_600 f12 font_darkgray">
                            {item.sender === "doctor"
                              ? `Dr. ${providerfirstname} ${providerlastname}`
                              : props.patientName}
                          </span>
                          <span className="weight_400 f12 font_darkgray">
                            {" "}
                            joined
                          </span>
                        </Grid>
                        <Grid item>{item.displayDate}</Grid>
                      </Grid>
                    </MuiDivider>
                  ) : item.text === "Left" ? (
                    <div>
                      <span className="weight_600 f12 font_darkgray">
                        {item.sender === "doctor"
                          ? `Dr. ${providerfirstname} ${providerlastname}`
                          : props.patientName}
                      </span>{" "}
                      <span className="weight_400 f12 font_darkgray">
                        {" "}
                        left chat {item.displayDate}
                      </span>
                    </div>
                  ) : item.sender === "patient" ? (
                    <div className="incoming_msg" key={index}>
                      <div className="received_msg">
                        <span className="incoming_time_date">
                          {item.displayDate}
                        </span>
                        <p>{item.text}</p>
                      </div>
                    </div>
                  ) : (
                    item.text !== "Joined" &&
                    item.text !== "Left" && (
                      <div className="outgoing_msg" key={index}>
                        <div className="sent_msg">
                          <span className="time_date">{item.displayDate}</span>
                          <p>{item.text}</p>
                          {/* <span className="float-right">
                                                  {
                                                    item.status==="sent" && <DoneIcon/>
                                                  }
                                                  {
                                                    item.status==="delivered" && <DoneAllIcon/>
                                                  }
                                                  {
                                                    item.status==="seen" && <DoneAllIcon style={{color:'deepskyblue'}}/>
                                                  }
                                                </span> */}
                        </div>
                      </div>
                    )
                  )
                )}
            </div>
          )}
        </div>
        {typing && typing === true && (
          <div className="mesgstyping">
            <div className="ml-3">
              {typing ? `${props.patientfirstname} is typing...` : ""}
            </div>
          </div>
        )}
        {chatstatus !== "disconnected" && (
          //  !toggle &&
          <div className="type_msg">
            <div
              className="d-flex flex-row input_msg_write px-3"
              ref={textArea}
            >
              <input
                id="input"
                type="text"
                className="write_msg"
                placeholder="Enter your message"
                onChange={(e) => messageHandler(e)}
                value={message}
                onKeyPress={enterPressed}
                disabled={chatstatus === "chat end"}
              />
              <button
                className="msg_send_btn"
                type="button"
                onClick={sentmessageHandler}
                disabled={chatstatus === "chat end"}
              >
                <i className="fa fa-paper-plane-o" aria-hidden="true"></i>
                <SendIcon className="f40" />
              </button>
            </div>
          </div>
        )}
      </div>
      {endcallconfirmationpopup && (
        <EndConsultationPopUp
          anchorEl={anchorEl}
          open={endcallconfirmationpopup}
          continueSession={continuesessionconfirmationclick}
          endSession={endsessionconfirmationclick}
        />
      )}
      {
        <OfflinePopup
          open={offlinealertopen}
          closeofflinepopup={closeofflinepopup}
        />
      }
      {
        <PatientDeclinePopUp
          open={patientdeclinemodal}
          closeofflinepopup={hidepatientdeclinemodal}
        />
      }
      <SockJsClient
        headers={customHeaders}
        url={`${process.env.REACT_APP_TELEKARDIA_API_BASE}teleconnect-ws`}
        topics={[`/clinician/${clinicianId}/web/notification`]}
        //  onConnect={onConnect}
        onMessage={(msg: any) => showstompmessage(msg)}
      />
      <PatientTechicalIssueChat
        open={patientjoiningtimeout}
        closePatientLeftCallPopup={closePatientLeftCallPopup}
      />
      <PatientDidntjoinCallPopup
        open={patientdidntjointimeout}
        closepatientmissedconsultaion={closepatientmissedconsultaion}
      />
    </div>
  );
}

export default Chat;
