import "date-fns";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { createFilterOptions } from "@material-ui/lab/Autocomplete";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";

// const defaultMaterialTheme = createMuiTheme({
//     palette: {
//         primary: {
//             main: "#2D9F86"
//         }
//     },
//     typography: {
//         fontFamily: 'Work Sans',
//       },
//   });
const useStyles = makeStyles((theme) => ({
  listbox: {
    height: "192px",
  },
  paper: {
    boxShadow:
      "0px 1px 4px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.02), 0px 1px 12px rgba(0, 0, 0, 0.12)",
    margin: 0,
    borderRadius: "4px",
    overflow: "auto",
    background: "#FFFFFF",
  },
  option: {
    fontFamily: "Work Sans",
    fontWeight: 400,
    fontSize: "16px",
    alignItems: "flex-start",
    color: "#000000",
    padding: " 8px 20px",
    '&[aria-selected="true"]': {
      backgroundColor: "#F1F3F4",
    },
  },
  popupIndicatorOpen: {
    color: "#2D9F86",
  },
}));
const CssTextField = withStyles({
  root: {
    // '& label.Mui-focused': {
    //   color: '#2D9F86',
    // },
    // '& .MuiInput-underline:after': {
    //   borderBottomColor: '#2D9F86',
    // },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#142A39",
      },
      "&:hover fieldset": {
        borderColor: "#2D9F86",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#2D9F86",
      },
    },
  },
})(TextField);
const MaterialUIPickers = (props) => {
  const time = moment(props.time).format("HH:mm A");
  const [selectedTime, setSelectedTime] = React.useState(time);
  const classes = useStyles();
  const handleTimeChange = (date, value) => {
    if (value) {
      setSelectedTime(value.time);
      props.changeHandle(moment(value.time, "HH:mm A")._d);
    }
  };
  const timelineLabels = (desiredStartTime, interval, period) => {
    const periodsInADay = moment.duration(1, "day").as(period);

    const timeLabels = [];
    const startTimeMoment = moment(desiredStartTime, "HH:mm");
    for (let i = 0; i < periodsInADay; i += interval) {
      startTimeMoment.add(i === 0 ? 0 : interval, period);
      timeLabels.push({ id: i, time: startTimeMoment.format("HH:mm A") });
    }

    return timeLabels;
  };
  const timeIntervals = timelineLabels("00:00", 15, "minutes");
  const filterOptions = createFilterOptions({
    matchFrom: "start",
    stringify: (option) =>
      moment(moment(option.time, "h:mma")._d).format("h:mma"),
  });

  return (
    <Autocomplete
      id="combo-box-demo"
      disableClearable
      noOptionsText="Invalid time"
      options={timeIntervals}
      filterOptions={filterOptions}
      onChange={(event, value) => handleTimeChange(event, value)}
      getOptionLabel={(option) =>
        moment(moment(option.time, "h:mma")._d).format("h:mma")
      }
      classes={{
        paper: classes.paper,
        option: classes.option,
        popupIndicatorOpen: classes.popupIndicatorOpen,
        listbox: classes.listbox,
      }}
      value={timeIntervals.find((option) => option.time === selectedTime)}
      popupIcon={<AccessTimeIcon />}
      renderInput={(params) => (
        <CssTextField
          {...params}
          label={false}
          error={props.valid === true ? true : false}
          variant="outlined"
          fullWidth={true}
        />
      )}
    />
  );
};

export default MaterialUIPickers;
