import React, {useState, useEffect} from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import * as API from "./../../Services/API/actions";
import { useSelector } from "react-redux";
    


export default function ControlledTabs(props) {
    const clinicianId = useSelector((state) => state.user.profile.id);
    const teamId = useSelector((state) => state.user.team.id);
    const [key, setKey] = useState();
    const [consultationSummaryNotes, setconsultationSummaryNotes] = useState("");
    const [treatmentPlan, settreatmentPlan] = useState("");

    
  useEffect(() => {
    if (!props.disable) {
    API.getconsultationSummaryNotes(
        teamId,
        clinicianId,
        props.appointmentId,
      ).subscribe(
        (res) => {
          res.data.result !== null && setconsultationSummaryNotes(decodeURI(res.data.result));
          res.data.result !== null
          ? props.updateconsultationSummary({
              target: {
                value: decodeURI(res.data.result),
              },
            })
          : props.updateconsultationSummary({
              target: {
                value: "",
              },
            });
        },
        (error) => console.log(error)
      );

    API.gettreatmentPlan(
        teamId,
        clinicianId,
        props.appointmentId,
      ).subscribe(
        (res) => {
          res.data.result !== null && settreatmentPlan(decodeURI(res.data.result));
          res.data.result !== null
          ? props.updatetreatmentPlan({
              target: {
                value: decodeURI(res.data.result),
              },
            })
          : props.updatetreatmentPlan({
              target: {
                value: "",
              },
            });
        },
        (error) => console.log(error)
      );
    }
  }, []);

  const changeconsultationSummarynotes =(note) =>{
    props.updateconsultationSummary(note)
    setconsultationSummaryNotes(note.target.value);
  };

  const changetreatmentPlan =(treatment) =>{
    props.updatetreatmentPlan(treatment)
    settreatmentPlan(treatment.target.value);
  };

    return (
      <Tabs
        id="controlled-tab-example"
        className="customtabs_sm"
        activeKey={key}
        onSelect={(k) => setKey(k)}
      >
        <Tab
          eventKey="Addnotes"
          title="Consultation Summary"
          disabled={props.disable}
        >
          <textarea
            className="form-control bg_grey height_240 mt-0 border-0"
            disabled={props.disable}
            placeholder={
              props.disable
                ? "The consultation summary will not be sent to the patient due to the technical issue."
                : "Write your consultation summary here. This will be sent to the patient."
            }
            value={consultationSummaryNotes}
            onChange={(e) => changeconsultationSummarynotes(e)}
          />
        </Tab>
        <Tab
          eventKey="Treamentplan"
          title="Patient Recommendations"
          disabled={props.disable}
        >
          <textarea
            className="form-control bg_grey height_240 mt-0 border-0"
            disabled={props.disable}
            placeholder="Write your recommendation for the patient here.  This will be sent to the patient.  "
            value={treatmentPlan}
            onChange={(e) => changetreatmentPlan(e)}
          />
        </Tab>
      </Tabs>
    );
}

