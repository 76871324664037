import React, { Component } from "react";
import { connect } from "react-redux";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import * as actions from "../../../Store/actions/index";
import "../../../Assets/styles/popup.scss";
import "../../../Assets/styles/react-big-calendar.scss";
import "../../../Assets/styles/react-date-picker.scss";
import "../../../Assets/styles/bootstrap.scss";
import PopOver from "../../Shared/PopOver/PopOver";
import Alert from "../../Shared/Alert/Alerts";
import Customtoolbar from "./toolbar";
import "../../../Assets/styles/style.scss";
import "../../../Assets/styles/mediaquery.scss";
import "../../../Assets/styles/custom.css";
import Successalert from '../../Shared/Alert/AvailabilityAlert';
import * as zone from '../../../Utilities/Utilities'
import {Box,
} from "@material-ui/core";
import { withTranslation } from 'react-i18next';
import 'moment/locale/es';
import 'moment/locale/fr';
moment.locale(navigator.language);
const localizer = momentLocalizer(moment); // or globalizeLocalizer
const pattern = "YYYY-MM-DDTHH:mm:ss.SSS";
const start = moment();
const remainder = 15 - (start.minute() % 15);
const date = moment(start).add(remainder, "minutes").format("YYYY-MM-DDTHH:mm:ss.SSS");
class MyCalendar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      view: "day",
      onNavigate: date,
      isOpen: false,
      isFetching: true,
      startDate: date,
      endDate: date,
      startTime: date,
      endTime: date,
      startDateRange: moment().startOf("day")._d,
      endDateRange: moment().endOf("day")._d,
      alert: null,
    };
    this.baseState = this.state;
  }

  componentDidUpdate(prevProps, prevState) {
    let queryParams = {
      endDate: moment(this.state.endDateRange).format(pattern),
      startDate: moment(this.state.startDateRange).format(pattern),
      offsetInMinutes: new Date().getTimezoneOffset(),
      timeZone:zone.getTimezoneName(),
      splitBasedOnDays:true,
    };
    if (
      prevState.onNavigate !== this.state.onNavigate ||
      prevState.view !== this.state.view
    ) {
      this.props.onFetchEvents(
        this.props.profileId,
        this.props.teamId,
        queryParams
      );
    }
  }
  componentDidMount() {
    let queryParams = {
      endDate: moment(this.state.endDateRange).format(pattern),
      startDate: moment(this.state.startDateRange).format(pattern),
      offsetInMinutes: new Date().getTimezoneOffset(),
      timeZone:zone.getTimezoneName(),
      splitBasedOnDays:true,
    };
    this.props.onFetchEvents(
      this.props.profileId,
      this.props.teamId,
      queryParams
    );
  }
  componentWillUnmount() {
    this.setState({
      isOpen: false,
    });
  }

  modalHandler = () => {
    this.setState({
      isOpen: false,
    });
  };
  eventStyleGetter = (event, view) => {
    let background ="#885F85";
    let cursor = view==="month"?"default":"pointer"
    if (event.hasOwnProperty("custom")) {
      return {
        className: "patient_" + view,
        style:{background:background,cursor:cursor}
      }
    }
    else {
      return {
        className: "doctors_" + view,
        style:{
          cursor:cursor
        }
      }
    }

  }

  availabilityFormCloseModal = (prps) => {
    this.setState({isOpen:false,alert:null})
    let queryParams = {
      endDate: moment(this.state.endDateRange).format(pattern),
      startDate: moment(this.state.startDateRange).format(pattern),
      offsetInMinutes: new Date().getTimezoneOffset(),
      timeZone:zone.getTimezoneName(),
      splitBasedOnDays:true,
    };
    if(prps.type==="add"){
      this.setState({
        alert:
        <Successalert
    component="clinicianCalendar"
    title={prps.isRecurring?
      `Recursive Availability from ${prps.startDate}
       to ${prps.endDate} from ${prps.startTime} to ${prps.endTime} on  ${prps.days} added successfully`
      :`Availability on ${prps.startDate}, 
    ${prps.startTime} to ${prps.endTime} added successfully`}
  />
      })
      this.props.onFetchEvents(
        this.props.profileId,
        this.props.teamId,
        queryParams
      );
  }
  else if(prps.type==="edit"){
    this.setState({
      alert:<Successalert
      component="clinicianCalendar"
      title={`Availability has been changed successfully`}
    />
    })
  }
  else if(prps.type==="delete"){
      if(prps.error){
        this.setState({
          alert:(
            <Alert
            date={new Date()}
            title="Unable to process the request"
            message={prps.error}/>
          )
        })
      }
      else{
        this.setState({
          alert:<Successalert
          component="clinicianCalendar"
          title={`Availability has been deleted successfully`}
        />
        })
        this.props.onFetchEvents(
          this.props.profileId,
          this.props.teamId,
          queryParams
        );
      }
  }
else if(prps.type==="appointment"){
  if(prps.error){
    this.setState({
      alert:(
        <Alert
        date={new Date()}
        title="Unable to process the request"
        message={prps.error}/>
      )
    })
  }
  else{
    this.setState({
      alert:<Successalert
      component="clinicianCalendar"
      title={`Consultation has been deleted successfully`}
    />
    })
    this.props.onFetchEvents(
      this.props.profileId,
      this.props.teamId,
      queryParams
    );
  }
}
else if(prps.type==="reassign"){
    this.setState({
      alert:<Successalert
      component="clinicianCalendar"
      title={`The consultation has been successfully assigned to ${prps.doctorFirstName} ${prps.doctorLastName}`}
    />
    })
    this.props.onFetchEvents(
      this.props.profileId,
      this.props.teamId,
      queryParams
    );
}
else{
  this.props.onFetchEvents(
    this.props.profileId,
    this.props.teamId,
    queryParams
  );
}
  };
  DateHandler = (slots) => {
    if (slots.length === 7) {
      this.setState({
        startDateRange: slots[0],
        endDateRange: slots[6].setHours(23, 59, 59, 999),
      });
    } else if (slots.length === 1) {
      let now = new Date(slots[0]);
      this.setState({
        startDateRange: slots[0],
        endDateRange: now.setHours(23, 59, 59, 999),
      });
    } else {
      this.setState({
        startDateRange: slots.start,
        endDateRange: slots.end,
      });
    }
  };
  viewHandler = (view) => {
    this.setState({
      view: view,
    });
  };
  startDate_handleChange = (date) => {
    this.setState({
      startDate: date,
    });
  };
  endDate_handleChange = (date) => {
    this.setState({
      endDate: date,
    });
  };
  startTime_handleChange = (date) => {
    this.setState({
      startTime: date,
    });
  };
  endTime_handleChange = (date) => {
    this.setState({
      endTime: date,
    });
  };
  calculateDifferenceInHours = (a, b) => {
    let dif = a.getTime() - b.getTime();
    return dif;
  };

  CustomDateHeader = (props) => {
    if (this.state.view === "day") {
      return (
        <div>
          <div className="d-flex flex-row cal_header">
            <div className="availability_header text-center text-uppercase">
            {this.props.t("Availability")}
            </div>
            <div className="appointment_header text-left text-uppercase pl-3">
            {this.props.t("Consultations")}
            </div>
          </div>
        </div>
      );
    } else if (this.state.view === "week") {
      return (
        <div>
          <div className="d-flex flex-column calweek_header">
            <div className="text-left text-uppercase px-1">
              {moment(props.date).format("dddd, D ")}
            </div>
            <div className="d-flex flex-row border_top">
              <div className="availability_header text-center">
              {this.props.t("Availability")}
              </div>
              <div className="appointment_header text-center">{this.props.t("Consultations")}</div>
            </div>
          </div>
        </div>
      );
    } else {
      return <div>{moment(props.date).format("dddd")}</div>;
    }
  };

  CustomTimeHeader({ label, drilldownView, onDrillDown }) {
    return <div className="headtime_day text-center text-uppercase">Time</div>;
  }

  render() {
    let cEvents = this.props.events;
    let fEvents = this.props.events;
    if (this.props.events.length > 0 && this.state.view === "month") {
      fEvents = [];
      let dayElement = {};
      cEvents.forEach((element) => {
        if (!dayElement["start"]) {
          dayElement["start"] = element.start;
          dayElement["end"] = element.end;
          dayElement["availableMinutes"] = 0;
          dayElement["plannedApps"] = 0;
        }
        if (
          new Date(dayElement["start"]).toDateString() !==
          new Date(element["start"]).toDateString()
        ) {
          fEvents.push(dayElement);
          dayElement = {};
          dayElement["start"] = element.start;
          dayElement["end"] = element.end;
          dayElement["availableMinutes"] = 0;
          dayElement["plannedApps"] = 0;
        }

        if (element.custom === "patient") dayElement["plannedApps"]++;
        else
          dayElement["availableMinutes"] += this.calculateDifferenceInHours(
            element.end,
            element.start
          );
      });
      if (dayElement["availableMinutes"] > 0) fEvents.push(dayElement);
    }

    let calendar = (
      <div className={this.props.isLoading ? "filtering" : null}>
        <div className={this.props.isLoading ? "spinner-border" : null}></div>
        <Calendar
          popup
          selectable={this.state.view === "month" ? true : false}
          onRangeChange={(slots) => this.DateHandler(slots)}
          onView={(view) => this.viewHandler(view)}
          onNavigate={(slot) => this.setState({ onNavigate: slot })}
          localizer={localizer}
          step={30}
          formats={{ timeGutterFormat: "h A" }}
          resizable
          defaultView={"day"}
          views={{
            day: true,
            week: true,
            month: true,
          }}
          tooltipAccessor={null}
          style={{ height: "calc(100vh - 200px)" }}
          events={fEvents}
          components={{
            event: (prps) => (
              <PopOver
                {...prps}
                {...this.props}
                sRange={this.state.startDateRange}
                view={this.state.view}
                eRange={this.state.endDateRange}
                clinicianId={this.props.profileId}
                onSubmit={this.availabilityFormCloseModal}
              />
            ),
            toolbar: (prps) => (
              <Customtoolbar
                {...prps}
                {...this.props}
                start={this.state.startDateRange}
                end={this.state.endDateRange}
                submitHandler={this.availabilityFormCloseModal}
              />
            ),
            week: {
              header: this.CustomDateHeader,
            },
            day: { header: this.CustomDateHeader },
            month: { header: this.CustomDateHeader },
            timeGutterHeader: this.CustomTimeHeader,
          }}
          eventPropGetter={(event) =>
            this.eventStyleGetter(event, this.state.view)
          }
        />
      </div>
    );
    let error = this.props.error ? (
     <Alert
      date={new Date()}
      title="Technical Issue"
      message="Unable to process this request due to internal server error."/> ) : null;
    return (
      <div className="telekardia">
          <div className="container-fluid">
          <div className="row">
            <div className="col-12 overflow-auto">
              <div className="calendar-container full_calendar">
                {error}
                <Box pt={2}>
                {this.state.alert}
                </Box>
                
                {calendar}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  if ("events" in state.events && !Array.isArray(state.events.events))
    return {
      events: [],
      profileId: state.user.profile.id,
      teamId: state.user.team.id,
    };
  return {
    events: state.events.events,
    profileId: state.user.profile.id,
    teamId: state.user.team.id,
    error: state.events.error || state.appointments.error,
    isLoading: state.events.loading,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    onAddingEvent: (teamId, clinicianid, eventData, queryParams) =>
      dispatch(
        actions.addAvailability(teamId, clinicianid, eventData, queryParams)
      ),
    onFetchEvents: (clinicianid, teamId, queryParams) =>
      dispatch(actions.getAvailabilities(clinicianid, teamId, queryParams)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(MyCalendar));
