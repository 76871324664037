/* eslint-disable */

import moment from "moment";
import React, { useState, useEffect, useCallback } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  Box,
  Typography,
  Dialog,
  IconButton,
  Grid,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  Chip
} from "@material-ui/core";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import _get from "lodash.get";
import apiservice from "../../api/services/apiservice.service";
import { streamToPromise, getTimezoneName } from "../../Utilities/Utilities";
import { getAge } from "../../Utilities/Utilities";
import EcgLoader from "../Shared/EcgLoader";
import MuiTypography from "../Shared/Typography/MuiTypography";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {},
  gridPaddingLightgreen: {
    background: "rgba(224, 224, 224, 0.3)",
    minHeight: "172px",
    borderRadius: "4px",
    padding: theme.spacing(2),
  },
  gridPadding: {   
    padding: theme.spacing(2),
  },
  tablehead: {
    // fontSize: 20,
    fontWeight: 700,
    whiteSpace: "nowrap",
    // fontWeight: "bolder",
    backgroundColor: "#FFFFFF",
  },
  tablecell: {
    width: 200,
    whiteSpace: "nowrap",
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  smallButton: {
    position: "absolute",
    backgroundColor: "rgba(20, 42, 57, 0.15)",
    right: theme.spacing(3),
    top: theme.spacing(2),
    color: "black",
    padding: "10px",
    margin: "0px 0px 0px 10px",
    border: "none",
    cursor: "pointer",
    width: 40,
    height: 40,
    opacity: 0.9,
    borderRadius: "8px",
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.smallButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    // padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(even)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const ConsultationsDetailModal = (props) => {
  const { t } = useTranslation(); 
  const classes = useStyles();
  const timezoneValue = getTimezoneName();
  // let appointmentId = props.appointmentId;
  const { appointmentId, clinicianId, teamId } = props; 
  const [adminConsultationDetails, setAdminConsultationDetails] = useState({});
  const [ekgLoader, setekgLoader] = useState(false);

  const loadAction = useCallback(async (apptid) => {
    setekgLoader(true);
    let offsetInMinutes = props.offset;
    const adminConsultationsRequest = streamToPromise(
      apiservice.getapiservice({
        baseUrl: `api/v1/teams/${teamId}/admin/${clinicianId}/appointments/${apptid}?offsetInMinutes=${offsetInMinutes}&timeZone=${timezoneValue}`,
      })
    );

    const adminConsultations = await adminConsultationsRequest;
    setAdminConsultationDetails(adminConsultations.data.result);
    setekgLoader(false);
  }, []);

  useEffect(() => {
    if(props.show)
      loadAction(appointmentId);
  }, [props.show]);

  let cellClass = "";
  if (
    (_get(adminConsultationDetails, "appointmentStatusDisplayName") || "-") === "Completed"
  ) {
    cellClass += " status_green_completed";
  } else if (
    (_get(adminConsultationDetails, "appointmentStatusDisplayName") || "-")=== "Pending"
  ) {
    cellClass += " status_purple";
  } else if (
    (_get(adminConsultationDetails, "appointmentStatusDisplayName") || "-") === "Scheduled"
  ) {
    cellClass += " status_green_scheduled";
  } else if (
    (_get(adminConsultationDetails, "appointmentStatusDisplayName") || "-") === "Tech issues - patient" ||
    (_get(adminConsultationDetails, "appointmentStatusDisplayName") || "-") === "Tech issues - doctor" ||
    (_get(adminConsultationDetails, "appointmentStatusDisplayName") || "-") === "Doctor disconnected" ||
    (_get(adminConsultationDetails, "appointmentStatusDisplayName") || "-") === "Patient Missed" ||
    (_get(adminConsultationDetails, "appointmentStatusDisplayName") || "-") === "Doctor Missed"
  ) {
    cellClass += " status_orange_closed";
  } else if (
      (_get(adminConsultationDetails, "appointmentStatusDisplayName") || "-") === "Canceled" ||
      (_get(adminConsultationDetails, "appointmentStatusDisplayName") || "-") === "Missed" ||
      (_get(adminConsultationDetails, "appointmentStatusDisplayName") || "-") === "Declined" ||
      (_get(adminConsultationDetails, "appointmentStatusDisplayName") || "-") === "Patient Canceled" ||
      (_get(adminConsultationDetails, "appointmentStatusDisplayName") || "-") === "Doctor Canceled" ||
      (_get(adminConsultationDetails, "appointmentStatusDisplayName") || "-") === "System Canceled" ||
      (_get(adminConsultationDetails, "appointmentStatusDisplayName") || "-") === "Admin Canceled"
  ) {
    cellClass += " status_red_canceled";
  } else if (
    (_get(adminConsultationDetails, "appointmentStatusDisplayName") || "-") === "Report being generated" ||
    (_get(adminConsultationDetails, "appointmentStatusDisplayName") || "-") === "Report Generation"
   ) {
    cellClass += " status_gray_reportgenerated";
  } else if (
    (_get(adminConsultationDetails, "appointmentStatusDisplayName") || "-") === "In progress"
  ) {
    cellClass += " status_gray_inprogress";
  } else if (
    (_get(adminConsultationDetails, "appointmentStatusDisplayName") || "-") === "Doctor review started") {
    cellClass += " status_gray_doctorreviewstarted";
  } else {
    cellClass += " status_purple";
  }
  

  
  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth={"md"}
        onClose={props.handleClose}
        aria-labelledby="customized-dialog-title"
        open={props.show}
      >
        <DialogTitle
          id="customized-dialog-title-value"
          onClose={props.handleClose}
        >
          <MuiTypography
            fontSize="24px"
            fontFamily="Work Sans"
            lineHeight="28.15px"
            fontWeight={500}
            color="#000000"
          >
            {t("Consultation details")}
          </MuiTypography>
        </DialogTitle>
        <DialogContent>
          {ekgLoader === true ? (
            <EcgLoader />
          ) : (
            <>
              <Grid container className={classes.gridPaddingLightgreen}>
                <Grid
                  container
                  direction="row"
                  justify="flex-start"
                  spacing={10}
                >
                  <Grid item>
                    <Grid container direction="column" justify="space-between">
                      <Grid item>
                        <MuiTypography
                          fontSize="14px"
                          fontFamily="Work Sans"
                          lineHeight="24px"
                          letterSpacing="-0.2px"
                          fontWeight={700}
                          color="#000000"
                        >
                          {t("Date and time")}
                        </MuiTypography>
                      </Grid>
                      <Grid item>
                        <MuiTypography
                          fontSize="14px"
                          fontFamily="Work Sans"
                          lineHeight="24px"
                          letterSpacing="-0.2px"
                          fontWeight={400}
                          color="#000000"
                        >
                          {_get(adminConsultationDetails, "startdatetime") &&
                            moment(
                              _get(adminConsultationDetails, "startdatetime")
                            ).format("MM/DD/YYYY")}{" "}
                          {_get(adminConsultationDetails, "startdatetime") &&
                            moment(
                              _get(adminConsultationDetails, "startdatetime")
                            ).format("hh:mm A")}{" "}
                          to{" "}
                          {_get(adminConsultationDetails, "enddatetime") &&
                            moment(
                              _get(adminConsultationDetails, "enddatetime")
                            ).format("hh:mm A")}
                        </MuiTypography>
                      </Grid>
                      <Grid item>
                        <MuiTypography
                          fontSize="14px"
                          fontFamily="Work Sans"
                          lineHeight="24px"
                          letterSpacing="-0.2px"
                          fontWeight={700}
                          color="#000000"
                        >
                          {t("Type")}
                        </MuiTypography>
                      </Grid>
                      <Grid item>
                        <MuiTypography
                          fontSize="14px"
                          fontFamily="Work Sans"
                          lineHeight="24px"
                          letterSpacing="-0.2px"
                          fontWeight={400}
                          color="#000000"
                        >
                          {_get(adminConsultationDetails, "appointmentType") ||
                            "-"}
                        </MuiTypography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid container direction="column" justify="space-between">
                      <Grid item>
                        <MuiTypography
                          fontSize="14px"
                          fontFamily="Work Sans"
                          lineHeight="24px"
                          letterSpacing="-0.2px"
                          fontWeight={700}
                          color="#000000"
                        >
                          {t("Status")}
                        </MuiTypography>
                      </Grid>
                      <Grid item>
                        <div className="telekardia">
                          <Chip
                            size="small"
                            className={cellClass}
                            label={
                              _get(
                                adminConsultationDetails,
                                "appointmentStatusDisplayName"
                              ) || "-"
                            }
                          />
                        </div>
                      </Grid>
                      <Grid item>
                        <MuiTypography
                          fontSize="14px"
                          fontFamily="Work Sans"
                          lineHeight="24px"
                          letterSpacing="-0.2px"
                          fontWeight={700}
                          color="#000000"
                        >
                          {t("Method")}
                        </MuiTypography>
                      </Grid>
                      <Grid item>
                        <MuiTypography
                          fontSize="14px"
                          fontFamily="Work Sans"
                          lineHeight="24px"
                          letterSpacing="-0.2px"
                          fontWeight={400}
                          color="#000000"
                        >
                          {_get(
                            adminConsultationDetails,
                            "appointmentChannelType"
                          ) || "-"}
                        </MuiTypography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                {/* <Grid container direction="row" justify="flex-start">
                  <Grid item>
                    <Grid container direction="column" justify="space-between">
                      <Grid item>
                        <MuiTypography
                          fontSize="14px"
                          fontFamily="Work Sans"
                          lineHeight="24px"
                          letterSpacing="-0.2px"
                          fontWeight={700}
                          color="#000000"
                        >
                          {t("Reason for consultation")}
                        </MuiTypography>
                      </Grid>
                      <Grid item>
                        <MuiTypography
                          fontSize="14px"
                          fontFamily="Work Sans"
                          lineHeight="24px"
                          letterSpacing="-0.2px"
                          fontWeight={400}
                          color="#000000"
                        >
                          {_get(adminConsultationDetails, "reasonForVisit") ||
                            "-"}
                        </MuiTypography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid> */}
              </Grid>
              <Box mt={2} />
              <Grid container className={classes.gridPadding}>
                <Grid
                  container
                  direction="row"
                  justify="flex-start"
                  spacing={10}
                >
                  <Grid item>
                    <Grid container direction="column" justify="space-between">
                      <Grid item>
                        <MuiTypography
                          fontSize="10px"
                          fontFamily="Work Sans"
                          lineHeight="16px"
                          letterSpacing="2.5px"
                          fontWeight={500}
                          textTransform="uppercase"
                          color="#067F6F"
                        >
                          {t("Cardiologist details")}
                        </MuiTypography>
                      </Grid>
                      <Grid item>
                        <MuiTypography
                          fontSize="14px"
                          fontFamily="Work Sans"
                          lineHeight="24px"
                          letterSpacing="-0.2px"
                          fontWeight={700}
                          color="#000000"
                        >
                          Dr{" "}
                          {_get(
                            adminConsultationDetails,
                            "physician.firstName"
                          ) || "-"}{" "}
                          {_get(
                            adminConsultationDetails,
                            "physician.lastName"
                          ) || "-"}
                        </MuiTypography>
                      </Grid>
                      <Grid item>
                        <MuiTypography
                          fontSize="14px"
                          fontFamily="Work Sans"
                          lineHeight="24px"
                          letterSpacing="-0.2px"
                          fontWeight={400}
                          color="#000000"
                        >
                          {_get(adminConsultationDetails, "physician.email") ||
                            "-"}
                        </MuiTypography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid container direction="column" justify="space-between">
                      <Grid item>
                        <MuiTypography
                          fontSize="10px"
                          fontFamily="Work Sans"
                          lineHeight="16px"
                          letterSpacing="2.5px"
                          fontWeight={500}
                          textTransform="uppercase"
                          color="#067F6F"
                        >
                          {t("PATIENT DETAILS")}
                        </MuiTypography>
                      </Grid>
                      <Grid item>
                        <MuiTypography
                          fontSize="14px"
                          fontFamily="Work Sans"
                          lineHeight="24px"
                          letterSpacing="-0.2px"
                          fontWeight={700}
                          color="#000000"
                        >
                          {_get(
                            adminConsultationDetails,
                            "patient.firstName"
                          ) || "-"}{" "}
                          {_get(adminConsultationDetails, "patient.lastName") ||
                            "-"}
                        </MuiTypography>
                      </Grid>
                      {/* <Grid item>
                        <MuiTypography
                          fontSize="14px"
                          fontFamily="Work Sans"
                          lineHeight="24px"
                          letterSpacing="-0.2px"
                          fontWeight={400}
                          color="#000000"
                        >
                          {(
                            _get(adminConsultationDetails, "patient.gender") ||
                            "-"
                          ).slice(0, 1)}
                          ,
                          {getAge(
                            new Date(
                              _get(adminConsultationDetails, "patient.dob")
                            )
                          )}{" "}
                          yrs
                        </MuiTypography>
                      </Grid> */}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Box mt={2} />
              <Grid container className={classes.gridPadding}>
                <Grid container direction="row" justify="space-between" xs={7}>
                  <Grid item>
                    <MuiTypography
                      fontSize="10px"
                      fontFamily="Work Sans"
                      lineHeight="16px"
                      letterSpacing="2.5px"
                      fontWeight={500}
                      textTransform="uppercase"
                      color="#067F6F"
                    >
                      {t("Audit")}
                    </MuiTypography>
                  </Grid>
                </Grid>
              </Grid>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      align="left"
                      className={classes.tablehead}
                      style={{ width: 173 }}
                    >
                      <MuiTypography
                        fontSize="14px"
                        fontFamily="Work Sans"
                        lineHeight="24px"
                        letterSpacing="-0.2px"
                        fontWeight={700}
                        color="#000000"
                      >
                        {t("Date and Time")}
                      </MuiTypography>
                    </TableCell>
                    <TableCell align="left" className={classes.tablehead}>
                      <MuiTypography
                        fontSize="14px"
                        fontFamily="Work Sans"
                        lineHeight="24px"
                        letterSpacing="-0.2px"
                        fontWeight={700}
                        color="#000000"
                      >
                        {t("Action")}
                      </MuiTypography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {adminConsultationDetails &&
                    adminConsultationDetails.auditDetails &&
                    adminConsultationDetails.auditDetails.length > 0 &&
                    adminConsultationDetails.auditDetails
                      .sort(
                        (a, b) =>
                          moment(b.auditDateTime) - moment(a.auditDateTime)
                      )
                      .map((row) => (
                        <StyledTableRow key={row.auditDateTime}>
                          <TableCell align="left" style={{ width: 173 }}>
                            <MuiTypography
                              fontSize="14px"
                              fontFamily="Work Sans"
                              lineHeight="24px"
                              letterSpacing="-0.2px"
                              fontWeight={400}
                              color="#000000"
                            >
                              {_get(row, "auditDateTime") &&
                                moment(_get(row, "auditDateTime")).format(
                                  "MM/DD/YYYY"
                                )}
                              ,{" "}
                              {_get(row, "auditDateTime") &&
                                moment(_get(row, "auditDateTime")).format(
                                  "LT"
                                )}{" "}
                            </MuiTypography>
                          </TableCell>
                          <TableCell align="left">
                            <MuiTypography
                              fontSize="14px"
                              fontFamily="Work Sans"
                              lineHeight="24px"
                              letterSpacing="-0.2px"
                              fontWeight={400}
                              color="#000000"
                            >
                              {row.auditReason}
                            </MuiTypography>

                            {row.auditReason
                              .toLowerCase()
                              .includes("closed") && (
                              <>
                                {/* <br /> */}
                                <MuiTypography
                                  fontSize="14px"
                                  fontFamily="Work Sans"
                                  lineHeight="24px"
                                  letterSpacing="-0.2px"
                                  fontWeight={400}
                                  color="#000000"
                                >
                                  {t("Reason")} : {row.auditStatus}
                                </MuiTypography>
                              </>
                            )}
                          </TableCell>
                        </StyledTableRow>
                      ))}
                </TableBody>
              </Table>
            </>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ConsultationsDetailModal;
