import 'date-fns';
import React from 'react';
import DateFnsUtils from "@date-io/date-fns";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker
} from '@material-ui/pickers';
import Grid from "@material-ui/core/Grid";
import startOfYear from "date-fns/startOfYear";
import endOfYear from "date-fns/endOfYear";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";


const defaultMaterialTheme = createMuiTheme({
    palette: {
        primary: {
            main: "#2D9F86"
        }
    },
    typography: {
        fontFamily: 'Work Sans',
      },
  });

export default function MaterialUIPickers(props) {
    const [selectedDate, setSelectedDate] = React.useState(props.date);
    
    const handleDateChange = (date) => {
        setSelectedDate(date);
        props.changeHandle({ daterange:{ selectedStartDate: startOfYear((date)), selectedEndDate: endOfYear((date))}});
    };
    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container justify="flex-end">
            <ThemeProvider theme={defaultMaterialTheme}>
                <KeyboardDatePicker
                    autoOk
                    disableToolbar
                    views={["year"]}
                    variant="inline"
                    inputVariant="outlined"
                    // margin="normal"
                    id="date-picker-inline"
                    // label="Year"
                    // helperText="Start from year selection"
                    value={selectedDate}
                    onChange={handleDateChange}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                    inputProps={{
                        style: {                          
                          padding: props.padding,
                        },
                      }}
                      InputProps={{ readOnly: true }}
                />
            </ThemeProvider>    
            </Grid>
           
        </MuiPickersUtilsProvider>
    );
}
