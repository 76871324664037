import 'date-fns';
import React from 'react';
import DateFnsUtils from "@date-io/date-fns";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker
} from '@material-ui/pickers';
import Grid from "@material-ui/core/Grid";
import startOfMonth from "date-fns/startOfMonth";
import endOfMonth from "date-fns/endOfMonth";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import englishLocale from "date-fns/locale/en-US";
import spanishLocale from "date-fns/locale/es";
import frLocale from "date-fns/locale/fr";
import germanLocale from "date-fns/locale/de";
import italianLocale from "date-fns/locale/it";
import { useTranslation } from "react-i18next";


const defaultMaterialTheme = createMuiTheme({
    palette: {
        primary: {
            main: "#2D9F86"
        }
    },
    typography: {
        fontFamily: 'Work Sans',
      },
  });
  

export default function MaterialUIPickers(props) {
    const [selectedDate, setSelectedDate] = React.useState(props.date);
    
    const handleDateChange = (date) => {
        setSelectedDate(date);
        props.changeHandle({ daterange:{ selectedStartDate: startOfMonth((date)), selectedEndDate: endOfMonth((date))}});
    };

    const { i18n } = useTranslation();
    const localeMap = {
        en: englishLocale,
        es: spanishLocale,
        de: germanLocale,
        it : italianLocale,
        fr: frLocale
      };

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[i18n.language]}>
            <Grid container justify="flex-end">
            <ThemeProvider theme={defaultMaterialTheme}>
                <KeyboardDatePicker
                    autoOk
                    disableToolbar
                    openTo="month"
                    views={[ "month", "year"]}
                    variant="inline"
                    inputVariant="outlined"
                    // format="MM/dd/yyyy"
                    // margin="normal"
                    id="date-picker-inline"
                    // label="Year and Month"
                    // helperText="Start from month selection"
                    value={selectedDate}
                    onChange={handleDateChange}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                    inputProps={{
                        style: {                          
                          padding: props.padding,
                        },
                      }}
                      InputProps={{ readOnly: true }}
                />
                </ThemeProvider>
            </Grid>
           
        </MuiPickersUtilsProvider>
    );
}
