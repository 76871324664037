import React from 'react'
import classes from './MultilaneIndicator.scss'
import { xScale } from '../recording/graphUtilities'

class MultilaneIndicator extends React.PureComponent {
  render() {
    const {
      rows,
      rowSeconds,
      // eslint-disable-next-line no-unused-vars
      samples,
      height,
      stripWidth,
      is6l = false,
    } = this.props

    const offsetLeft = 0.25
    const offsetLeft6l = 0.2

    const renderTick = () => {
      const content = []
      const xTrans = xScale(rowSeconds, stripWidth, 1)(offsetLeft)
      for (let i = 0; i < rows; i += 1) {
        content.push((
          <g transform={`translate(${0}, ${height * i})`} key={i}>
            <g transform={`translate(${xTrans} ,0)`} className={classes.tick}>
              <g>
                <text className={classes.secondsText} dy=".71em" y="9" x="0" style={{ textAnchor: 'start' }}>
                  {i * rowSeconds}s
                </text>
              </g>
            </g>
          </g>
        ))
      }
      return content
    }
    const renderLeads = () => {
      const leads = {
        0: 'I',
        1: 'II',
        2: 'III',
        3: 'aVR',
        4: 'aVL',
        5: 'aVF',
      }
      const content = []
      const xTrans = xScale(rowSeconds, stripWidth, 1)(offsetLeft6l)
      for (let i = 0; i < 6; i += 1) {
        content.push((
          <g transform={`translate(${0}, ${(height / 2) * i})`} key={i}>
            <g transform={`translate(${xTrans} ,0)`} className={classes.tick}>
              <g>
                <text className={classes.secondsText} dy=".71em" y="9" x="0" style={{ textAnchor: 'start' }}>
                  {leads[i]}
                </text>
              </g>
            </g>
          </g>
        ))
      }
      return content
    }

    return (
      <React.Fragment>
        {is6l ?
          <g className={classes.ticks} transform={`translate(0, ${height / 2})`}>
            {renderLeads()}
          </g>
          :
          <g className={classes.ticks} transform={`translate(0, ${height - 40})`}>
            {renderTick()}
          </g>
        }
      </React.Fragment>
    )
  }
}

export default MultilaneIndicator
