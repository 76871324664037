/* eslint-disable */
import { combineReducers } from 'redux';
import UserReducer from './user'
import AppointmentsReducer from './dashboard'
import CalendarReducer from './calendar'
import EkgReducer from './ekg'

const rootReducer = combineReducers({
    events: CalendarReducer,
    user: UserReducer,
    appointments: AppointmentsReducer,
    ekg: EkgReducer
});
export default (state, action) =>
  rootReducer(state, action);
